import React, { Component } from "react";

import { LineGraph, PieGraph } from "Components/Data";
import {
  graphqlAutoActivitySummaries,
  graphqlPieGraph,
  gql
} from "HOC/graphql";

const PageViewsBySectionChart = PieGraph.extendAs(
  "Behavior.SiteUsage.PageViewsBySectionChart",
  {
    size: "full",
    title: "Page Views by Section"
  },
  graphqlPieGraph(
    "withPageViewsBySection",
    gql`
      query withPageViewsBySection($filters: Filters) {
        siteUsage {
          pageViewsBySection(filters: $filters) {
            csvTag
            rows {
              subsection
              activitiesCount
            }
          }
        }
      }
    `,
    {
      wrangle: {
        path: "siteUsage.pageViewsBySection",
        labelField: "subsection",
        valueField: "activitiesCount"
      }
    }
  )
);

export const LicenseViewsGraph = LineGraph.extendAs(
  "Behavior.SiteUsage.LicenseViewsGraph",
  {
    size: "half",
    title: "License Views"
  },
  graphqlAutoActivitySummaries("withLicenseViews", {
    path: "licenses.views",
    activitiesLabel: "License Views"
  })
);

export const OrderViewsGraph = LineGraph.extendAs(
  "Behavior.SiteUsage.OrderViewsGraph",
  {
    size: "half",
    title: "Order Views"
  },
  graphqlAutoActivitySummaries("withOrderViews", {
    path: "orders.views",
    activitiesLabel: "Order Views"
  })
);

export default class BehaviorSiteUsage extends Component {
  static displayName = "Behavior.SiteUsage";

  render() {
    return (
      <React.Fragment>
        <PageViewsBySectionChart />
        <LicenseViewsGraph />
        <OrderViewsGraph />
      </React.Fragment>
    );
  }
}
