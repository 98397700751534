import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Transition } from "react-transition-group";

import { IconButton } from "Components/Utility";

import styles from "./Drawer.module.scss";

export default class Drawer extends Component {
  static displayName = "Layout.Drawer";

  static propTypes = {
    children: PropTypes.node.isRequired,
    handleCloseClick: PropTypes.func.isRequired,
    position: PropTypes.oneOf(["left", "right"]),
    title: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.drawer = React.createRef();
  }

  componentDidMount() {
    document.body.classList.add("has-scroll-lock");
    this.drawer.current.addEventListener("click", this.handleLinkClick);
  }

  componentWillUnmount() {
    document.body.classList.remove("has-scroll-lock");
    this.drawer.current.removeEventListener("click", this.handleLinkClick);
  }

  get duration() {
    return 400;
  }

  get leftPos() {
    return this.props.position === "left" ? "-100%" : "100%";
  }

  get translatedPos() {
    return this.props.position === "left" ? "100%" : "-100%";
  }

  get defaultStyle() {
    return {
      left: this.leftPos,
      transition: `transform ${this.duration}ms ease-out`
    };
  }

  get transitionStyle() {
    return {
      entering: { transform: "translateX(0)" },
      entered: { transform: `translateX(${this.translatedPos})` }
    };
  }

  handleLinkClick = event => {
    const isLink =
      (event.target.tagName === "A" || event.target.parentNode.tagName === "A");

    if (!isLink) return;

    this.props.handleCloseClick();
  };

  renderHeader() {
    return (
      <header className={styles.header}>
        <div className="m-nav-heading">{this.props.title}</div>
      </header>
    );
  }

  renderCloseButton() {
    const { position, handleCloseClick } = this.props;

    return (
      <div className={classNames(styles.closeButtonWrapper, styles[position])}>
        <IconButton
          handleClick={handleCloseClick}
          icon="Close32"
          srText="Close drawer."
        />
      </div>
    );
  }

  render() {
    const { children, title } = this.props;
    const className = classNames(styles.container);

    return (
      <Transition in={true} timeout={100} appear={true}>
        {state => (
          <div
            ref={this.drawer}
            className={className}
            children={children}
            style={{
              ...this.defaultStyle,
              ...this.transitionStyle[state]
            }}
          >
            {title && this.renderHeader()}
            <div className={styles.inner}>{children}</div>
            {this.renderCloseButton()}
          </div>
        )}
      </Transition>
    );
  }
}
