import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Binoculars extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 22;
  }

  get defaultWidth() {
    return 22;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 22 22";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <polygon
            fill={this.fill}
            points="16.25 10.25 15.5 10.25 14.75 6.5 13.813 6.5 14 5 11.75 5 11.938 6.5 10.063 6.5 10.25 5 8 5 8.188 6.5 7.25 6.5 6.5 10.25 5.75 10.25 5 16.25 5 17 9.5 17 9.5 10.25 10.25 10.25 10.25 9.5 11.75 9.5 11.75 10.25 12.5 10.25 12.5 17 17 17 17 16.25"
          />
          <polygon
            fill={this.fill}
            points="16.25 10.25 15.5 10.25 14.75 6.5 13.813 6.5 14 5 11.75 5 11.938 6.5 10.063 6.5 10.25 5 8 5 8.188 6.5 7.25 6.5 6.5 10.25 5.75 10.25 5 16.25 5 17 9.5 17 9.5 10.25 10.25 10.25 10.25 9.5 11.75 9.5 11.75 10.25 12.5 10.25 12.5 17 17 17 17 16.25"
          />
        </g>
      </svg>
    );
  }
}
