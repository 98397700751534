import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactJson from "react-json-view";
import classNames from "classnames";
import { flow, get, isFunction, method, property, toString } from "lodash";

import Header from "./Header";
import Footer from "./Footer";

import styles from "./Card.module.scss";

const splitErrorStack = flow(
  property("state.error.stack"),
  toString,
  method("split", /\n\s*/)
);

const cardProps = {
  size: PropTypes.oneOf(["half", "full"]),
  title: PropTypes.node,
  description: PropTypes.string,
  headerPosAbsolute: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  csvTag: PropTypes.string,
  moreInfo: PropTypes.string,
  onDownloadClick: PropTypes.func,
  currentRouteTitle: PropTypes.string,
  routeLinkName: PropTypes.string,
  routeLinkText: PropTypes.string
};

export const cardPropsShape = PropTypes.shape(cardProps);

export default class Card extends Component {
  static displayName = "Layout.Card";

  static propTypes = cardProps;

  static defaultProps = {
    size: "half"
  };

  state = {
    hasError: false
  };

  static getDerivedStateFromError(error) {
    return { hasError: Boolean(error), error };
  }

  componentDidCatch(error, info) {
    console.warn("Caught error in Layout.Card");
    console.dir({ error, info });
  }

  get containerClasses() {
    const sizeClass = styles[this.props.size];

    return classNames(styles.container, sizeClass);
  }

  get routeHeaderProps() {
    const { currentRouteTitle, routeLinkName, routeLinkText } = this.props;

    return { currentRouteTitle, routeLinkName, routeLinkText };
  }

  get footerProps() {
    if (this.hasError) {
      return {
        info: <ReactJson src={splitErrorStack(this)} />
      };
    } else {
      const { csvTag, hasNoData, isLoading, moreInfo: info, onDownloadClick } = this.props;

      return { csvTag, hasNoData, isLoading, info, onDownloadClick };
    }
  }

  get hasError() {
    return this.state.hasError && this.state.error;
  }

  get headerClasses() {
    return classNames(styles.header, {
      [styles.headerAbs]: this.useAbsoluteHeaderPositioning
    });
  }

  get showRouteHeader() {
    return this.props.routeLinkName;
  }

  get showFooter() {
    const { onDownloadClick, moreInfo } = this.props;

    return isFunction(onDownloadClick) || (moreInfo && moreInfo.length > 0);
  }

  get useAbsoluteHeaderPositioning() {
    if (this.hasError) {
      return false;
    }

    return get(this, "props.headerPosAbsolute", false);
  }

  renderChildren() {
    if (this.hasError) {
      return (
        <React.Fragment>
          <p>An error occurred in this visualization</p>
          <p>{this.state.error.toString()}</p>
        </React.Fragment>
      );
    }

    const { children } = this.props;

    return isFunction(children) ? children() : children;
  }

  render() {
    const { description, title } = this.props;

    return (
      <section className={this.containerClasses}>
        {this.showRouteHeader && <Header {...this.routeHeaderProps} />}
        <div className={styles.inner}>
          <header className={this.headerClasses}>
            {title && <h2 className={styles.title}>{title}</h2>}
            {description && <p className={styles.description}>{description}</p>}
          </header>
          <div className={styles.body}>{this.renderChildren()}</div>
        </div>
        <Footer {...this.footerProps} />
      </section>
    );
  }
}
