import React, { Component } from "react";

import { NamedRoute } from "Components/Routing";

import ArticleDetail from "./ArticleDetail";
import Articles from "./Articles";
import Cases from "./Cases";
import Root from "./Root";
import Satisfaction from "./Satisfaction";

export default class PerformanceRoutes extends Component {
  static displayName = "Performance.Routes";

  render() {
    return (
      <React.Fragment>
        <NamedRoute authenticated name="performance.cases" component={Cases} />
        <NamedRoute
          authenticated
          name="performance.articles.byArticleId"
          component={ArticleDetail}
        />
        <NamedRoute
          authenticated
          name="performance.articles"
          component={Articles}
        />
        <NamedRoute
          authenticated
          name="performance.satisfaction"
          component={Satisfaction}
        />
        <NamedRoute authenticated name="performance" component={Root} />
      </React.Fragment>
    );
  }
}
