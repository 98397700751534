import { LineGraph } from "Components/Data";

import { flow, get, unzip } from "lodash";
import { at, map } from "lodash/fp";

import { graphql, gql } from "HOC/graphql";

const extractValues = at(["label", "submissionsCount", "usersCount", "visitorsCount"]);

const reorientValues = flow(
  map(extractValues),
  unzip
);

export default LineGraph.extendAs(
  "Performance.Cases.OpenCasesByPeriodGraph",
  {
    size: "full",
    title: "Support Cases Opened by Period",
  },
  graphql(
    gql`
    query withOpenCasesByPeriod($filters: Filters) {
      supportCases {
        byPeriod(filters: $filters) {
          csvTag

          rows {
            id
            label
            submissionsCount
            usersCount
            visitorsCount
          }
        }
      }
    }
    `,
    {
      name: "withOpenCasesByPeriod",
      options: ({ localFilters: filters } = {}) => ({
        fetchPolicy: "cache-and-network",
        variables: { filters }
      }),
      props: function wrangleOpenCasesByPeriodProps(props) {
        const data = get(props, "withOpenCasesByPeriod", null);

        const isLoading = get(data, "loading", true);

        const connection = get(data, "supportCases.byPeriod");

        const csvTag = get(connection, "csvTag");

        const rows = get(connection, "rows", []);

        const [labels, submissionCounts, uniqueUserCounts, visitorCounts] = reorientValues(rows);

        const series = [
          {
            name: "Open Cases",
            type: "column",
            data: submissionCounts
          }, {
            name: "Unique Users",
            type: "column",
            data: uniqueUserCounts
          }, {
            name: "Visitors",
            type: "line",
            data: visitorCounts
          }
        ];

        const chartProps = {
          series,
          options: {
            chart: {
              id: "withOpenCasesByPeriod",
              stacked: false
            },
            dataLabels: {
              enabled: false
            },
            labels,
            xaxis: {
              categories: labels
            },
            yaxis: [
              {
                axisTicks: {
                  show: true
                },
                title: {
                  text: "Open Cases"
                }
              },
              {
                seriesName: "Unique Users",
                axisTicks: {
                  show: true
                },
                opposite: true,
                title: {
                  text: "Unique Users"
                }
              },
              {
                seriesName: "Visitors",
                axisTicks: {
                  show: true
                },
                opposite: true,
                title: {
                  text: "Visitors"
                }
              }
            ]
          }
        };

        return { chartProps, csvTag, isLoading };
      }
    }
  )
);
