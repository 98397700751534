import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Context as AuthContext } from "Auth";
import hideIfUnauthenticated from "HOC/hideIfUnauthenticated";

import { IconComposer } from "Components/Utility";

import styles from "./Userbox.module.scss";

export class Userbox extends Component {
  static displayName = "Navigation.Userbox";

  static propTypes = {
    size: PropTypes.oneOf(["default", "tall"])
  };

  static defaultProps = {
    size: "default"
  };

  static contextType = AuthContext;

  render() {
    const { authState } = this.context;

    return (
      <div className={classNames(styles.container, styles[this.props.size])}>
        <div className={styles.user}>${authState.username}</div>
        <div className={styles.logOut}><a href={authState.signOutURL}>Log Out</a></div>
        <IconComposer
          icon="User32"
          size="default"
          fill="#00BEFF"
          iconClass={styles.icon}
        />
      </div>
    );
  }
}

export default hideIfUnauthenticated(Userbox);
