import React, { Component } from "react";

import { LineGraph, PieGraph } from "Components/Data";
import { graphqlActivitySummaries, graphqlAutoActivitySummaries, graphqlPieGraph, gql } from "HOC/graphql";

import VisitorsByCountryMap from "./VisitorsByCountryMap";

export const VisitorsGraph = LineGraph.extendAs(
  "Audience.Root.VisitorsGraph",
  {
    size: "full",
    title: "Visitors"
  },
  graphqlAutoActivitySummaries("withAudienceVisitors", {
    path: "audience.visitors",
    activitiesLabel: "Page Views",
    usersLabel: "Unique Visitors",
    usersYAxis: { opposite: true }
  })
);

export const TotalSessionsGraph = LineGraph.extendAs(
  "Audience.Root.TotalSessionsGraph",
  {
    size: "full",
    title: "Total Sessions"
  },
  graphqlActivitySummaries(
    "withAudienceTotalSessions",
    gql`
    query withAudienceTotalSessions($filters: Filters) {
      audience {
        totalSessions(filters: $filters) {
          csvTag

          rows {
            id
            day
            label
            sessionsCount
          }
        }
      }
    }
    `,
    {
      wrangle: {
        path: "audience.totalSessions",
        seriesDefinitions: [
          { field: "sessionsCount", label: "Sessions Count" }
        ],
        xAxisField: "label"
      }
    }
  )
);

export const LanguagesPieGraph = PieGraph.extendAs(
  "Audience.Root.LanguagesPieGraph",
  {
    size: "half",
    title: "Languages"
  },
  graphqlPieGraph(
    "withLanguages",
    gql`
      query withLanguages($filters: Filters) {
        audience {
          languages(filters: $filters) {
            csvTag
            rows {
              language
              activitiesCount
            }
          }
        }
      }
    `,
    {
      wrangle: {
        path: "audience.languages",
        labelField: "language",
        valueField: "activitiesCount"
      }
    }
  )
);
export default class AudienceRoot extends Component {
  static displayName = "Audience.Root";

  render() {
    return (
      <React.Fragment>
        <VisitorsGraph />
        <VisitorsByCountryMap />
        <LanguagesPieGraph />
      </React.Fragment>
    );
  }
}
