import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { IconComposer } from "Components/Utility";

import styles from "./SiemensFooter.module.scss";

export default class SiemensFooter extends PureComponent {
  static displayName = "Layout.SiemensFooter";

  static propTypes = {
    visibility: PropTypes.oneOf(["mobile", "desktop"])
  };

  render() {
    const { visibility } = this.props;
    const className = classNames(styles.wrapper, {
      [styles.wrapperMobile]: visibility === "mobile",
      [styles.wrapperDesktop]: visibility === "desktop"
    });
    const svgProps = { "aria-hidden": false };

    return (
      <div className={className}>
        <IconComposer icon="LogoSiemensUnique" svgProps={svgProps} />
      </div>
    );
  }
}
