import { flow, identity, method, spread } from "lodash";
import { filter, map } from "lodash/fp";

import moment, { tryMoment } from "./moment";

const buildClamper = function(methodName) {
  if (typeof moment[methodName] !== "function") {
    throw new Error(`Unknown moment function: ${methodName}`);
  }

  const callMomentMethod = spread(moment[methodName].bind(moment));

  return flow(
    map(tryMoment),
    filter(identity),
    callMomentMethod,
    method("toDate")
  );
}

export const earliestDate = buildClamper("min");
export const latestDate = buildClamper("max");
