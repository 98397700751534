import {
  cond,
  conforms,
  identity,
  isFunction,
  isPlainObject,
  overEvery,
  overSome,
  stubTrue
} from "lodash";
import PropTypes from "prop-types";

export const ROUTE_NAME_PATTERN = /^\w+(?:\.\w+)*$/;

export const isValidRouteName = value =>
  Boolean(ROUTE_NAME_PATTERN.test(value));
export const isValidNamedRoutePropObject = overEvery(
  isPlainObject,
  conforms({
    component: isFunction
  })
);

export const isValidNamedRouteProps = overSome(
  isFunction,
  isValidNamedRoutePropObject
);

const allKeysAreValidRoutes = object =>
  Object.keys(object).every(isValidRouteName);
const allValsAreValidProps = object =>
  Object.values(object).every(isValidNamedRouteProps);

export const isValidNamedRouteMapping = overEvery(
  isPlainObject,
  allKeysAreValidRoutes,
  allValsAreValidProps
);

export const NamedRouteSwitchShape = PropTypes.objectOf(
  (propValue, key, componentName, location, propFullName) => {
    if (!isValidRouteName(key)) {
      return new Error(
        `Invalid route name ${propFullName} provided to ${componentName}. Validation failed.`
      );
    }

    const value = propValue[key];

    if (isPlainObject(value)) {
      if (!isValidNamedRoutePropObject(value)) {
        return new Error(
          `Invalid named route prop object provided under ${propFullName} to ${componentName}. Validation failed.`
        );
      }
    } else if (!isFunction(value)) {
      return new Error(
        `Invalid named route prop value provided under ${propFullName} to ${componentName}. Expected a class or function. Validation failed.`
      );
    }
  }
);

export const toNamedRouteSwitchProps = cond([
  [isValidNamedRoutePropObject, identity],
  [isFunction, component => ({ component })],
  [
    stubTrue,
    () => {
      throw new Error("Invalid named route switch prop");
    }
  ]
]);
