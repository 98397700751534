import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Audience extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 32;
  }

  get defaultWidth() {
    return 32;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 32 32";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M11.5,12.5 C11.5,10.0145096 13.5152054,8 16,8 C18.4861424,8 20.5,10.0138576 20.5,12.5 C20.5,14.9861424 18.4861424,17 16,17 C13.5152054,17 11.5,14.9854904 11.5,12.5 Z M12.5,12.5 C12.5,14.4331284 14.0674129,16 16,16 C17.9338576,16 19.5,14.4338576 19.5,12.5 C19.5,10.5661424 17.9338576,9 16,9 C14.0674129,9 12.5,10.5668716 12.5,12.5 Z M23.999997,23.5016051 C23.9991105,23.7777461 23.7745354,24.0008839 23.4983944,23.9999974 C23.2222535,23.9991109 22.9991157,23.7745358 23.0000021,23.4983949 L23.004,22.2533421 C23.0056364,21.0096482 21.9975073,20 20.7539996,20 L11.2859999,20 C10.0231423,20 9,21.0231424 9,22.286 L9,23.5 C9,23.7761424 8.77614237,24 8.5,24 C8.22385763,24 8,23.7761424 8,23.5 L8,22.286 C8,20.4708576 9.47085759,19 11.2859999,19 L20.7539996,19 C22.5503485,19 24.0063629,20.4582083 24.003997,22.2556051 L23.999997,23.5016051 Z"
          />
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M11.5,12.5 C11.5,10.0145096 13.5152054,8 16,8 C18.4861424,8 20.5,10.0138576 20.5,12.5 C20.5,14.9861424 18.4861424,17 16,17 C13.5152054,17 11.5,14.9854904 11.5,12.5 Z M12.5,12.5 C12.5,14.4331284 14.0674129,16 16,16 C17.9338576,16 19.5,14.4338576 19.5,12.5 C19.5,10.5661424 17.9338576,9 16,9 C14.0674129,9 12.5,10.5668716 12.5,12.5 Z M23.999997,23.5016051 C23.9991105,23.7777461 23.7745354,24.0008839 23.4983944,23.9999974 C23.2222535,23.9991109 22.9991157,23.7745358 23.0000021,23.4983949 L23.004,22.2533421 C23.0056364,21.0096482 21.9975073,20 20.7539996,20 L11.2859999,20 C10.0231423,20 9,21.0231424 9,22.286 L9,23.5 C9,23.7761424 8.77614237,24 8.5,24 C8.22385763,24 8,23.7761424 8,23.5 L8,22.286 C8,20.4708576 9.47085759,19 11.2859999,19 L20.7539996,19 C22.5503485,19 24.0063629,20.4582083 24.003997,22.2556051 L23.999997,23.5016051 Z"
          />
        </g>
      </svg>
    );
  }
}
