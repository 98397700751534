import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import { compose, lifecycle, withHandlers, withStateHandlers } from "recompose";
import { debounce, get, toString } from "lodash";
import gql from "graphql-tag";

import { FilterCard } from "Components/Layout";
import Selector from "Components/Utility/SelectFilter/Selector";
import { ArticleKeys } from "LocalFilters";
import { setArticleKeyFilter, setArticleNeedleFilter } from "Queries";
import { IconComposer } from "Components/Utility";

import styles from "./ArticleFilter.module.scss";

const ARTICLE_KEYS = ArticleKeys.options;

export class ArticleFilter extends Component {
  static displayName = "Filters.ArticleFilter";

  static propTypes = {
    articleKey: PropTypes.string,
    articleNeedle: PropTypes.string,
    handleArticleNeedleClear: PropTypes.func.isRequired,
    handleArticleNeedleChange: PropTypes.func.isRequired,
    needleInput: PropTypes.string.isRequired,
    updateArticleKey: PropTypes.func.isRequired,
    updateArticleNeedle: PropTypes.func.isRequired,
  };

  static defaultProps = {
    needleInput: ""
  };

  get showClear() {
    return this.props.needleInput.length > 0;
  }

  renderClearButton() {
    const { handleArticleNeedleClear } = this.props;

    return (
      <button
        className={styles.clearButton}
        onClick={handleArticleNeedleClear}
      >
        <span className={styles.clearText}>Clear</span>
        <IconComposer
          icon="Close22"
          iconClass={styles.clearIcon}
          size="default"
        />
      </button>
    );
  }

  renderSearch() {
    const { handleArticleNeedleChange, needleInput } = this.props;

    return (
      <div className={styles.searchContainer}>
        <input
          type="text"
          onChange={handleArticleNeedleChange}
          value={needleInput}
          placeholder="Search term…"
          className={styles.searchInput}
        />
        {this.showClear && this.renderClearButton()}
      </div>
    );
  }

  render() {
    const { articleKey, updateArticleKey } = this.props;
    const currentOption = ARTICLE_KEYS.find(o => o.value === articleKey);

    return (
      <FilterCard size="full">
        <Selector
          isClearable={false}
          isSearchable={false}
          onChange={updateArticleKey}
          options={ARTICLE_KEYS}
          value={currentOption}
          categoryLabel={"Article Key"}
          isLoading={false}
          themes={["with-search"]}
          placeholder={articleKey}
        />
        {this.renderSearch()}
      </FilterCard>
    );
  }
}

const withArticleFilters = graphql(
  gql`
    query withArticleFilters {
      localFilters @client {
        articleKey
        articleNeedle
      }
    }
  `,
  {
    name: "withArticleFilters",
    options: props => ({
      fetchPolicy: "cache-only"
    }),
    props: props => ({
      articleKey: get(props, "withArticleFilters.localFilters.articleKey", ArticleKeys.first()),
      articleNeedle: get(props, "withArticleFilters.localFilters.articleNeedle", ""),
    })
  }
);

const withKeyMutation = graphql(setArticleKeyFilter, {
  name: "mutateArticleKey",
  options: props => ({
    fetchPolicy: "no-cache"
  })
});

const withNeedleMutation = graphql(setArticleNeedleFilter, {
  name: "mutateArticleNeedle",
  options: props => ({
    fetchPolicy: "no-cache"
  })
});

const withUpdaters = withHandlers({
  clearArticleNeedle: ({ mutateArticleNeedle }) => () => {
    mutateArticleNeedle({ variables: { articleNeedle: "" } });
  },

  updateArticleKey: ({ mutateArticleKey }) => articleKey => {
    mutateArticleKey({ variables: { articleKey } });
  },

  updateArticleNeedle: ({ mutateArticleNeedle }) => debounce(articleNeedle => {
    mutateArticleNeedle({ variables: { articleNeedle } });
  }, 1500, { leading: false, trailing: true }),
});

const withInputState = withStateHandlers(
  ({ articleNeedle: needleInput }) => ({ needleInput }),
  {
    handleArticleNeedleChange: (_, { updateArticleNeedle }) => (event) => {
      const needleInput = get(event, "target.value");

      // debounced so we don't fire a request on every keypress
      updateArticleNeedle(needleInput);

      return { needleInput };
    },

    handleArticleNeedleClear: (_, { clearArticleNeedle }) => () => {
      clearArticleNeedle();

      return { needleInput: "" };
    },

    updateNeedleInput: () => (value) => ({ needleInput: toString(value) })
  }
);

const withMonitorNeedle = lifecycle({
  componentDidUpdate({ articleNeedle: previousNeedle }) {
    const { articleNeedle, needleInput, updateNeedleInput } = this.props;

    if ((articleNeedle !== previousNeedle && !needleInput) || (!articleNeedle && needleInput)) {
      updateNeedleInput(articleNeedle);
    }
  }
});

export default compose(
  withArticleFilters,
  withKeyMutation,
  withNeedleMutation,
  withUpdaters,
  withInputState,
  withMonitorNeedle,
)(ArticleFilter);
