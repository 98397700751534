import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import { compose, withHandlers } from "recompose";
import { get } from "lodash";
import gql from "graphql-tag";

import { GlobalContext } from "Components/Application";
import { FilterCard } from "Components/Layout";
import SelectFilter from "Components/Utility/SelectFilter";
import { setLanguageFilter } from "Queries";

export class LanguageFilter extends Component {
  static displayName = "Filters.LanguageFilter";

  static propTypes = {
    language: PropTypes.string,
    updateLanguage: PropTypes.func.isRequired
  };

  static contextType = GlobalContext;

  render() {
    const { language, updateLanguage } = this.props;

    const { isLoading, languages } = this.context;

    return (
      <FilterCard size="full">
        <SelectFilter
          categoryLabel="Language"
          isLoading={isLoading}
          onChange={updateLanguage}
          options={languages}
          placeholder="All Languages"
          selectedValue={language}
          themes={["card"]}
        />
      </FilterCard>
    );
  }
}

const withLanguage = graphql(
  gql`
    query withLanguage {
      localFilters @client {
        language
      }
    }
  `,
  {
    name: "withLanguage",
    options: props => ({
      fetchPolicy: "cache-only"
    }),
    props: props => ({
      language: get(props, "withLanguage.localFilters.language", null)
    })
  }
);

const withMutation = graphql(setLanguageFilter, {
  name: "mutateLanguage",
  options: props => ({
    fetchPolicy: "no-cache"
  })
});

const withUpdateLanguage = withHandlers({
  updateLanguage: ({ mutateLanguage }) => language => {
    mutateLanguage({ variables: { language } });
  }
});

export default compose(
  withLanguage,
  withMutation,
  withUpdateLanguage
)(LanguageFilter);
