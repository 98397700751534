import React, { Component } from "react";
import { get } from "lodash";
import { branch, renderComponent } from "recompose";

import { Count, BarGraph, LineGraph, PieGraph, Table } from "Components/Data";
import { CountCell } from "Components/Table";
import { graphql, graphqlAutoActivitySummaries, graphqlPieGraph, graphqlSimpleBarGraph, gql } from "HOC/graphql";

const ContentViewsByTypeChart = PieGraph.extendAs(
  "Behavior.Content.ContentViewsByTypeChart",
  {
    size: "full",
    title: "Content Views by Type"
  },
  graphqlPieGraph(
    "withContentViewsByType",
    gql`
      query withContentViewsByType($filters: Filters) {
        content {
          viewsByType(filters: $filters) {
            csvTag
            rows {
              id
              resourceType
              activitiesCount
            }
          }
        }
      }
    `,
    {
      wrangle: {
        path: "content.viewsByType",
        labelField: "resourceType",
        valueField: "activitiesCount"
      }
    }
  )
);

const DocumentViewsByFormatChart = PieGraph.extendAs(
  "Behavior.Content.DocumentViewsByFormatChart",
  {
    size: "half",
    title: "Document Views by File Type"
  },
  graphqlPieGraph(
    "withDocumentViewsByFormat",
    gql`
      query withDocumentViewsByFormat($filters: Filters) {
        content {
          documentViewsByFormat(filters: $filters) {
            csvTag
            rows {
              id
              documentFormat
              activitiesCount
            }
          }
        }
      }
    `,
    {
      wrangle: {
        path: "content.documentViewsByFormat",
        labelField: "documentFormat",
        valueField: "activitiesCount"
      }
    }
  )
);

export const DownloadsOverTimeGraph = LineGraph.extendAs(
  "Behavior.Content.DownloadsOverTimeGraph",
  {
    size: "half",
    title: "Downloads Over Time",
  },
  graphqlAutoActivitySummaries(
    "withDownloadsOverTime",
    {
      path: "downloads.overall",
      activitiesLabel: "Downloads Over Time"
    }
  )
);

export const DownloadsByReleaseGraph = BarGraph.extendAs(
  "Behavior.Content.DownloadsByReleaseGraph",
  {
    size: "half",
    title: "Downloads by Release"
  },
  graphqlSimpleBarGraph(
    "withDownloadsByRelease",
    gql`
    query withDownloadsByRelease($filters: Filters, $scope: ScopeByProduct!) {
      downloads {
        byRelease(filters: $filters, scope: $scope) {
          csvTag

          rows {
            id
            releaseId
            releaseTitle
            activitiesCount
          }
        }
      }
    }
    `,
    {
      modifyVariables: (variables) => ({
        ...variables,
        scope: { productId: get(variables, "filters.productId") }
      }),
      wrangle: {
        path: "downloads.byRelease",
        seriesTitle: "Downloads in this Period",
        labelField: "releaseTitle",
        valueField: "activitiesCount"
      }
    }
  )
);

const hasProductIDFilter = (props) => Boolean(get(props, "localFilters.productId"));

export const DownloadsByProductGraph = BarGraph.extendAs(
  "Behavior.Content.DownloadsByProductGraph",
  {
    size: "half",
    title: "Downloads by Product"
  },
  branch(hasProductIDFilter, renderComponent(DownloadsByReleaseGraph)),
  graphqlSimpleBarGraph(
    "withDownloadsByProduct",
    gql`
    query withDownloadsByProduct($filters: Filters) {
      downloads {
        byProduct(filters: $filters) {
          csvTag

          rows {
            id
            productId
            productTitle
            activitiesCount
          }
        }
      }
    }
    `,
    {
      wrangle: {
        path: "downloads.byProduct",
        seriesTitle: "Downloads in this Period",
        labelField: "productTitle",
        valueField: "activitiesCount"
      }
    }
  )
);

export const PlacementViewsCount = Count.extendAs(
  "Behavior.Content.PlacementViewsCount",
  {
    size: "half",
    title: "Placement Views"
  },
  graphql(
    gql`
    query withPlacementViewsCount($filters: Filters) {
      content {
        placementViewsCount(filters: $filters) {
          id
          placementCount
          impressionCount
        }
      }
    }
    `,
    {
      name: "withPlacementViewsCount",
      options: ({ localFilters: filters } = {}) => ({
        fetchPolicy: "cache-and-network",
        variables: { filters }
      }),
      props: (props) => ({
        isLoading: get(props, "withPlacementViewsCount.loading", true),
        count: get(props, "withPlacementViewsCount.content.placementViewsCount.placementCount", 0),
        total: get(props, "withPlacementViewsCount.content.placementViewsCount.impressionCount", 0),
      })
    },
  )
);

export const ContentViewsByProductTable = Table.extendAs(
  "Behavior.Content.ContentViewsByProductTable",
  {
    size: "full",
    title: "Content Views By Product",
  },
  graphql(
    gql`
    query withContentViewsByProduct($filters: Filters) {
      content {
        viewsByProduct(filters: $filters) {
          csvTag

          rows {
            id
            productId
            productTitle
            activitiesCount
            placementCount
            downloadCount
          }
        }
      }
    }
    `,
    {
      name: "withContentViewsByProduct",
      options: ({ localFilters: filters } = {}) => ({
        fetchPolicy: "cache-and-network",
        variables: { filters }
      }),
      props: (props) => ({
        csvTag: get(props, "withContentViewsByProduct.content.viewsByProduct.csvTag"),
        isLoading: get(props, "withContentViewsByProduct.loading", true),
        tableProps: {
          columns: [
            {
              Header: "Product",
              accessor: "productTitle"
            },
            {
              Cell: CountCell,
              Header: "Content Views",
              accessor: "activitiesCount"
            },
            {
              Cell: CountCell,
              Header: "Placement Views",
              accessor: "placementCount"
            },
            {
              Cell: CountCell,
              Header: "Downloads",
              accessor: "downloadCount"
            }
          ],
          data: get(props, "withContentViewsByProduct.content.viewsByProduct.rows", []),
          defaultPageSize: 10
        }
      })
    }
  )
);

export default class BehaviorContent extends Component {
  static displayName = "Behavior.Content";

  render() {
    return (
      <React.Fragment>
        <ContentViewsByTypeChart />
        <DownloadsByProductGraph />
        <DownloadsOverTimeGraph />
        <DocumentViewsByFormatChart />
        <PlacementViewsCount />
        <ContentViewsByProductTable />
      </React.Fragment>
    );
  }
}
