export const CHART_SCALES = ["daily", "monthly", "weekly"];

export function chartScaleFromDuration(duration) {
  if (!duration) {
    return "weekly";
  }

  if (duration.asMonths() >= 8) {
    return "monthly";
  } else if (duration.asDays() <= 32) {
    return "daily";
  } else {
    return "weekly";
  }
}
export function isChartScale(scale) {
  return CHART_SCALES.includes(scale);
}
