import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Collapse } from "react-collapse";

import { NamedLink } from "Components/Routing";
import { IconComposer } from "Components/Utility";

import styles from "./NavItem.module.scss";

export default class NavItem extends Component {
  static displayName = "Navigation.NavItem";

  static propTypes = {
    active: PropTypes.bool,
    expanded: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.node,
    useCollapse: PropTypes.bool
  };

  static defaultProps = {
    active: false,
    useCollapse: true
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpened: false
    };
  }

  componentDidUpdate(prevProps) {
    const { children, expanded } = this.props;

    if (!children) return false;

    // if props.expanded becomes true, open submenu
    if (prevProps.expanded !== expanded && expanded) {
      this.setState({ isOpened: expanded });
    }
  }

  handleToggleClick() {
    this.setState({
      isOpened: !this.state.isOpened
    });
  }

  renderToggle() {
    if (!this.props.children) return false;

    return (
      <button
        className={styles.nestedListToggle}
        onClick={event => {
          this.handleToggleClick();
        }}
      >
        <span className="a-hidden">{"Toggle visibility of submenu"}</span>
        <IconComposer
          icon="Arrow1Down22"
          size="default"
          fill="#00BEFF"
          iconClass={classNames(styles.toggleIcon, {
            [styles.isRotated]: !this.state.isOpened
          })}
        />
      </button>
    );
  }

  renderSubmenu() {
    const { children, useCollapse } = this.props;

    if (!useCollapse) return <ul className={styles.nestedList}>{children}</ul>;

    return (
      <Collapse isOpened={this.state.isOpened}>
        <ul className={styles.nestedList}>{children}</ul>
      </Collapse>
    );
  }

  render() {
    const {
      active,
      name,
      label,
      icon,
      children,
      useCollapse,
      ...otherProps
    } = this.props;

    return (
      <li className={styles.item}>
        <div
          className={classNames({
            [styles.inner]: true,
            [styles.active]: active
          })}
        >
          <NamedLink {...otherProps} isNav name={name} className={styles.link}>
            <span className={styles.label}>{label}</span>
            {icon && (
              <IconComposer
                icon={icon}
                size="default"
                fill="#00BEFF"
                iconClass={styles.icon}
              />
            )}
          </NamedLink>
          {useCollapse && this.renderToggle()}
        </div>
        {children && this.renderSubmenu()}
      </li>
    );
  }
}
