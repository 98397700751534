import React, { Component } from "react";
import PropTypes from "prop-types";
import { defaultProps, setDisplayName, withPropsOnChange } from "recompose";
import { flow, isEmpty, overSome, property } from "lodash";

import { cardPropsShape } from "Components/Layout";
import { Chart, Legend } from "Components/Chart";
import { wrapGraphQL } from "HOC/graphql";

import DataCard from "./DataCard";
import { pieChartPropsShape } from "./shapes";

const hasNoData = overSome(
  flow(
    property("options.labels"),
    isEmpty
  ),
  flow(
    property("series"),
    isEmpty
  )
);

const checkData = withPropsOnChange(["chartProps"], ({ chartProps }) => ({
  hasNoData: hasNoData(chartProps)
}));

export default class PieGraph extends Component {
  static displayName = "Data.PieGraph";

  static propTypes = {
    cardProps: cardPropsShape,
    chartProps: pieChartPropsShape,
    hasNoData: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
  };

  static defaultProps = {
    hasNoData: false,
    isLoading: false
  };

  render() {
    const renderPie = () => (
      <React.Fragment>
        <Chart {...this.props.chartProps} type="pie" />
        <Legend {...this.props.chartProps} size={this.props.cardProps.size} />
      </React.Fragment>

    );

    return <DataCard {...this.props}>{renderPie}</DataCard>;
  }

  static extendAs(displayName, cardProps = {}, ...hocs) {
    return wrapGraphQL(
      setDisplayName(displayName),
      ...hocs,
      defaultProps({
        cardProps
      }),
      checkData
    )(PieGraph);
  }
}
