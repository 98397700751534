import { setContext } from "apollo-link-context";

import { set } from "lodash";

import authService from "../Auth";

const authContextLink = setContext(
  (flerp, { headers = {}, ...otherContext }) => {
    const contextFragment = { headers };

    if (authService.isAuthenticated()) {
      return set(
        contextFragment,
        "headers.Authorization",
        authService.bearerToken
      );
    }

    return contextFragment;
  }
);

export default authContextLink;
