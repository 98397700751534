import React, { PureComponent } from "react";

import { maybeFormatNumber } from "Utilities";

export default class CountCell extends PureComponent {
  static displayName = "Table.CountCell";

  render() {
    const { value } = this.props;

    return <span>{maybeFormatNumber(value)}</span>;
  }
}
