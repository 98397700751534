import React, { Component } from "react";
import PropTypes from "prop-types";
import { get, matchesProperty, property } from "lodash";
import { map, pick } from "lodash/fp";
import { branch, fromRenderProps, withPropsOnChange } from "recompose";

import { compose, graphql, gql } from "HOC/graphql";
import withAuth from "HOC/withAuth";

import LocalFiltersContext from "LocalFilters/Context";

import CalendarProvider from "./CalendarProvider";
import GlobalContext, { defaultGlobalContext, globalContextShape } from "./GlobalContext";

export const companyShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  activitiesCount: PropTypes.number.isRequired
});

export const productShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  activitiesCount: PropTypes.number.isRequired
});

export class GlobalBootstrap extends Component {
  static displayName = "Application.GlobalBootstrap";

  static propTypes = {
    children: PropTypes.node,
    globalContextValue: globalContextShape.isRequired
  };

  static defaultProps = {
    globalContextValue: defaultGlobalContext
  };

  render() {
    const { children, minimumDay, maximumDay, globalContextValue } = this.props;

    return (
      <GlobalContext.Provider value={globalContextValue}>
        <CalendarProvider minimumDay={minimumDay} maximumDay={maximumDay} children={children} />
      </GlobalContext.Provider>
    );
  }
}

const withGlobals = graphql(
  gql`
  query withGlobals {
    globals {
      id
      minimumDay
      maximumDay

      companies {
        name
        mode
      }

      languages {
        name
      }

      products {
        id
        title
      }
    }
  }
  `,
  {
    name: "withGlobals",
    props: (props) => ({
      companies:  get(props, "withGlobals.globals.companies", []),
      isLoading:  get(props, "withGlobals.loading", true),
      languages:  get(props, "withGlobals.globals.languages", []),
      maximumDay: get(props, "withGlobals.globals.maximumDay"),
      minimumDay: get(props, "withGlobals.globals.minimumDay"),
      products:   get(props, "withGlobals.globals.products", []),
    })
  }
);

const withIncludeEmployees = fromRenderProps(
  LocalFiltersContext.Consumer,
  pick("includeEmployees")
);

const mapCompanyOptions = map(({ name, mode }) => ({ label: name, value: name, mode }));

const isCustomer = matchesProperty("mode", "customer");

const mapAndFilterCompanies = (companies = [], includeEmployees = false) => {
  const options = mapCompanyOptions(companies);

  return includeEmployees ? options : options.filter(isCustomer);
};

const withCompanyOptions = withPropsOnChange(
  ["companies", "includeEmployees"],
  ({ companies, includeEmployees }) => ({
    companyOptions: mapAndFilterCompanies(companies, includeEmployees)
  })
);

const mapLanguageOptions = map(({ name: label }) => ({ label, value: label }));

const withLanguageOptions = withPropsOnChange(
  ["languages"],
  ({ languages }) => ({
    languageOptions: mapLanguageOptions(languages)
  })
);

const mapProductOptions = map(({ id: value, title: label }) => ({ label, value }));

const withProductOptions = withPropsOnChange(
  ["products"],
  ({ products }) => ({
    productOptions: mapProductOptions(products)
  })
);

const withGlobalContextValue = withPropsOnChange(
  ["companyOptions", "isLoading", "languageOptions", "minimumDay", "maximumDay", "productOptions"],
  ({ companyOptions: companies, isLoading, languageOptions: languages, minimumDay, maximumDay, productOptions: products }) => ({
    globalContextValue: { companies, isLoading, languages, minimumDay, maximumDay, products }
  })
);

const withGlobalFetch = compose(
  withGlobals,
  withIncludeEmployees,
  withCompanyOptions,
  withLanguageOptions,
  withProductOptions,
  withGlobalContextValue,
);

const withAuthorizedGlobalFetch = compose(
  withAuth,
  branch(
    property("isAuthenticated"),
    withGlobalFetch
  )
);

export default compose(
  withAuthorizedGlobalFetch
)(GlobalBootstrap);
