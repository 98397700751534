import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";

import Pagination from "./Pagination";

import styles from "./Table.module.scss";

export default class TableWrapper extends Component {
  static displayName = "Table.Wrapper";

  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    defaultPageSize: PropTypes.number.isRequired,
    showPageSizeOptions: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    defaultPageSize: 5,
    showPageSizeOptions: false
  };

  render() {
    const { columns, data, defaultPageSize, showPageSizeOptions } = this.props;

    return (
      <div className={styles.wrapper}>
        <ReactTable
          data={data}
          columns={columns}
          defaultPageSize={defaultPageSize}
          className="-highlight"
          PaginationComponent={Pagination}
          showPageSizeOptions={showPageSizeOptions}
        />
      </div>
    );
  }
}
