import {
  bindAll,
  castArray,
  conforms,
  flatten,
  fromPairs,
  get,
  has,
  identity,
  isEmpty,
  mapValues,
  noop,
  pick,
  toString,
  transform
} from "lodash";

import { BehaviorSubject } from "rxjs";

import apolloCache from "Apollo/cache";

import { getLocalFilters as getLocalFiltersQuery } from "Queries";

import { getInitialDateRange, toDateRange } from "Utilities/Temporal";

import { isPresent } from "Utilities";

import ArticleKeys from "./ArticleKeys";

const setsNewTimes = conforms({
  startTime: isPresent,
  untilTime: isPresent
});

const DATE_RANGE = Symbol("DATE_RANGE");
const DATA = Symbol("DATA");
const MERGE = Symbol("MERGE");
const MERGE_KEY = Symbol("MERGE_KEY");
const TYPE_NAME = "__typename";
const FILTERS$ = Symbol("FILTERS$");
const getLocalFilters = Symbol("getLocalFilters");
const refreshFilters = Symbol("refreshFilters");
const setDateRange = Symbol("setDateRange");

const defaultArticleKey = ArticleKeys.first().toString();

export default class LocalFilters {
  static displayName = "LocalFilters.State";

  constructor() {
    this[DATA] = new Map([
      [TYPE_NAME, "LocalFilters"],
      ["articleKey", defaultArticleKey],
      ["articleNeedle", ""],
      ["chartScale", "weekly"],
      ["company", null],
      ["includeEmployees", false],
      ["language", null],
      ["productId", null],
      ["startTime", null],
      ["untilTime", null]
    ]);

    this[setDateRange](getInitialDateRange());

    this[FILTERS$] = new BehaviorSubject(this[getLocalFilters]());

    bindAll(this, ["clearGlobalFilters", "resetDateRange"]);
  }

  get data() {
    return pick(this, "dateRangeChartScale", "localFilters");
  }

  get dateRange() {
    return this[DATE_RANGE];
  }

  get dateRangeChartScale() {
    return this[DATE_RANGE].chartScale;
  }

  get localFilters() {
    return this[FILTERS$].getValue();
  }

  get localFilters$() {
    return this[FILTERS$];
  }

  clearGlobalFilters() {
    this.merge({
      articleKey: defaultArticleKey,
      articleNeedle: "",
      company: null,
      includeEmployees: false,
      language: null,
      productId: null,
    });

    return this.data;
  }

  createMergeResolverFor(...filterNames) {
    return (_, variables, { cache }) => {
      const newFilters = pick(variables, ...filterNames);

      if (isEmpty(cache)) {
        throw new Error("Missing cache for resolver");
      }

      if (isPresent(newFilters)) {
        const data = this.merge(newFilters);

        cache.writeData({ data });
      }

      return this.localFilters;
    };
  }

  createMergeResolvers(mapping = {}) {
    return mapValues(mapping, filterNames => {
      return this.createMergeResolverFor(...castArray(filterNames));
    });
  }

  getCurrent(filterName) {
    if (filterName !== TYPE_NAME && this[DATA].has(filterName)) {
      return this[DATA].get(filterName);
    } else {
      throw new Error(`Invalid filter name: ${filterName}`);
    }
  }

  merge(newFilters = {}) {
    this[MERGE](newFilters);

    return this.data;
  }

  mutationFor(methodName) {
    return (_, variables, { cache }) => {
      if (isEmpty(cache)) {
        throw new Error("Missing cache for resolver");
      }

      this[methodName](variables);

      const { data } = this;

      cache.writeData({ data });

      return this.localFilters;
    }
  }

  mutationsFor(...methodNames) {
    return transform(flatten(methodNames), (acc, methodName) => {
      acc[methodName] = this.mutationFor(methodName);
    }, {});
  }

  resetDateRange() {
    this[setDateRange](getInitialDateRange());

    this[refreshFilters]();

    return this.data;
  }

  /**
   * @private
   * @return {void}
   */
  async restoreFromCache() {
    try {
      const result = await apolloCache.readQuery({
        query: getLocalFiltersQuery
      });

      const { localFilters } = result;

      this.merge(localFilters);
    } catch (err) {
      console.warn("Problem restoring localFilters from cache");
      console.error(err);
    }
  }

  subscribe(...args) {
    return this[FILTERS$].subscribe(...args);
  }

  toJSON() {
    return this.data;
  }

  /**
   * @return {void}
   */
  [MERGE](newFilters = {}) {
    if (isEmpty(newFilters)) {
      return;
    }

    this[MERGE_KEY]("company", newFilters);

    this[MERGE_KEY]("includeEmployees", newFilters, {
      cast: Boolean,
      onChange() {
        this[DATA].set("company", null)
      }
    });

    this[MERGE_KEY]("articleKey", newFilters, {
      cast: ArticleKeys.getFilter,
      onChange() {
        this[DATA].set("articleNeedle", "");
      }
    });

    this[MERGE_KEY]("articleNeedle", newFilters, {
      cast: toString
    });

    this[MERGE_KEY]("language", newFilters);
    this[MERGE_KEY]("productId", newFilters);

    if (setsNewTimes(newFilters)) {
      const dateRange = toDateRange(newFilters);

      if (dateRange.isValid()) {
        this[setDateRange](dateRange);
      } else {
        console.warn("[LocalFilters.merge] Got invalid time data");
        console.dir(dateRange);
        console.dir(newFilters);
      }
    }

    this[refreshFilters]();
  }

  [MERGE_KEY](key, newFilters = {}, { cast = identity, onChange = noop } = {}) {
    if (has(newFilters, key)) {
      const oldValue = this.getCurrent(key);
      const newValue = cast(get(newFilters, key));

      this[DATA].set(key, newValue);

      if (oldValue !== newValue && typeof onChange === "function") {
        onChange.call(this, { oldValue, newValue, key });
      }
    }
  }

  /**
   * @return {object}
   */
  [getLocalFilters]() {
    return fromPairs(Array.from(this[DATA]));
  }

  /**
   * @return {void}
   */
  [refreshFilters]() {
    this[FILTERS$].next(this[getLocalFilters]());
  }

  /**
   * @return {void}
   */
  [setDateRange](dateRange) {
    if (!dateRange.isValid()) {
      throw new Error("Invalid Date Range");
    }

    this[DATE_RANGE] = dateRange;

    for (const [key, value] of dateRange) {
      this[DATA].set(key, value);
    }
  }
}
