import { get } from "lodash";

import { CountryMap } from "Components/Data";
import { graphql, gql } from "HOC/graphql";

export default CountryMap.extendAs(
  "Audience.Root.VisitorsByCountryMap",
  {
    size: "half",
    title: "Location",
    description: "Visitors by Country"
  },
  graphql(
    gql`
    query withVisitorsByCountry($filters: Filters) {
      countries {
        visits(filters: $filters) {
          csvTag
          rows {
            id
            alpha2
            activitiesCount
            usersCount
          }
        }
      }
    }
    `,
    {
      name: "withVisitorsByCountry",
      options: ({ localFilters: filters } = {}) => ({
        fetchPolicy: "cache-and-network",
        variables: { filters }
      }),
      props: (props) => ({
        csvTag:    get(props, "withVisitorsByCountry.countries.visits.csvTag", null),
        countries: get(props, "withVisitorsByCountry.countries.visits.rows", []),
        isLoading: get(props, "withVisitorsByCountry.loading", true),
      })
    }
  )
)
