import React, { Component } from "react";
import { get } from "lodash";

import { LineGraph, Table } from "Components/Data";
import { ArticleFilter } from "Components/Filters";
import { TableCellLink } from "Components/Routing";
import { PercentCell } from "Components/Table";
import { graphqlAutoActivitySummaries, graphql, gql } from "HOC/graphql";

export const ArticleCellLink = TableCellLink.generateFor(
  "performance.articles.byArticleId",
  {
    alterParams: ({ id: articleId }) => ({ articleId }),
    label: ({ id, title }) => (title || id)
  }
);

export const CollectionViewsGraph = LineGraph.extendAs(
  "Performance.Articles.CollectionViewsGraph",
  {
    size: "full",
    title: "Views"
  },
  graphqlAutoActivitySummaries("withArticleCollectionViews", {
    path: "articles.collectionViews",
    activitiesLabel: "Views of Collections of Articles"
  })
);

export const ArticlesTable = Table.extendAs(
  "Performance.Articles.ArticlesTable",
  {
    size: "full",
    title: "Article List",
  },
  graphql(
    gql`
    query withArticles($filters: Filters) {
      articles {
        summaries(filters: $filters) {
          csvTag

          rows {
            id
            title
            searchRank
            activitiesCount
            usersCount
          }
        }
      }
    }
    `,
    {
      name: "withArticles",
      options: ({ localFilters: filters } = {}) => ({
        fetchPolicy: "cache-and-network",
        variables: { filters }
      }),
      props: (props) => {
        const retProps = {
          csvTag: get(props, "withArticles.articles.summaries.csvTag"),
          isLoading: get(props, "withArticles.loading", true),
          tableProps: {
            columns: [
              {
                Header: "Title",
                accessor: "id",
                Cell: ArticleCellLink
              },
              {
                Header: "Views",
                accessor: "activitiesCount"
              },
              {
                Header: "Unique Users",
                accessor: "usersCount"
              }
            ],
            data: get(props, "withArticles.articles.summaries.rows", [])
          }
        };

        if (!!get(props, "ownProps.localFilters.articleNeedle")) {
          retProps.tableProps.columns.push({
            Header: "Relevance",
            accessor: "searchRank",
            Cell: PercentCell,
          });
        }

        return retProps;
      }
    }
  )
);

export default class PerformanceArticles extends Component {
  static displayName = "Performance.Articles";

  render() {
    return (
      <React.Fragment>
        <ArticleFilter />
        <ArticlesTable />
        <CollectionViewsGraph />
      </React.Fragment>
    );
  }
}
