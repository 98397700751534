import React, { PureComponent } from "react";

import { Context as AuthContext } from "Auth";

export default class FinishSignOut extends PureComponent {
  static displayName = "Authentication.FinishSignOut";

  static contextType = AuthContext;

  componentDidMount() {
    this.context.authState.signOut();
    this.context.authState.signIn();
  }

  render() {
    return <div />;
  }
}
