import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Search extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 32;
  }

  get defaultWidth() {
    return 32;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 32 32";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M22.8647014,14.9945143 C22.8588241,10.6731947 19.3485476,7.17481812 15.0226017,7.17899937 C11.8491121,7.17900792 8.98845196,9.08960711 7.7757193,12.0191016 C6.56298664,14.9485961 7.23726982,18.3194406 9.48387429,20.5584291 C11.7304788,22.7974175 15.1064899,23.4631369 18.0362904,22.2448893 C20.9660909,21.0266417 22.872075,18.1646029 22.8647014,14.9945143 Z M18.4898554,23.3333507 C15.1191121,24.7349478 11.2350022,23.969036 8.65027779,21.3930738 C6.06555334,18.8171115 5.28978873,14.9389459 6.68504091,11.5685546 C8.08029308,8.19816336 11.3714902,6.00001388 15.022029,6.00000431 C19.9989681,5.99519353 24.0380407,10.0204789 24.0449559,14.9920594 C24.0533246,18.6391415 21.8605111,21.9317901 18.4898554,23.3333507 Z M20.5434032,21.3274447 L21.3822641,20.4980799 L25.5776743,24.5882665 C25.8066677,24.8115166 25.8113236,25.1781323 25.5880735,25.4071257 C25.5855954,25.4096675 25.583094,25.4121866 25.5805695,25.4146824 L25.5805695,25.4146824 C25.3476871,25.6449285 24.9733033,25.6462401 24.7388134,25.4176313 L20.5434032,21.3274447 Z"
          />
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M22.8647014,14.9945143 C22.8588241,10.6731947 19.3485476,7.17481812 15.0226017,7.17899937 C11.8491121,7.17900792 8.98845196,9.08960711 7.7757193,12.0191016 C6.56298664,14.9485961 7.23726982,18.3194406 9.48387429,20.5584291 C11.7304788,22.7974175 15.1064899,23.4631369 18.0362904,22.2448893 C20.9660909,21.0266417 22.872075,18.1646029 22.8647014,14.9945143 Z M18.4898554,23.3333507 C15.1191121,24.7349478 11.2350022,23.969036 8.65027779,21.3930738 C6.06555334,18.8171115 5.28978873,14.9389459 6.68504091,11.5685546 C8.08029308,8.19816336 11.3714902,6.00001388 15.022029,6.00000431 C19.9989681,5.99519353 24.0380407,10.0204789 24.0449559,14.9920594 C24.0533246,18.6391415 21.8605111,21.9317901 18.4898554,23.3333507 Z M20.5434032,21.3274447 L21.3822641,20.4980799 L25.5776743,24.5882665 C25.8066677,24.8115166 25.8113236,25.1781323 25.5880735,25.4071257 C25.5855954,25.4096675 25.583094,25.4121866 25.5805695,25.4146824 L25.5805695,25.4146824 C25.3476871,25.6449285 24.9733033,25.6462401 24.7388134,25.4176313 L20.5434032,21.3274447 Z"
          />
        </g>
      </svg>
    );
  }
}
