import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import { compose, withHandlers } from "recompose";
import { get } from "lodash";
import gql from "graphql-tag";

import { GlobalContext } from "Components/Application";
import SelectFilter from "Components/Utility/SelectFilter";
import { setProductIdFilter } from "Queries";

export class ProductFilter extends Component {
  static displayName = "GlobalSearch.ProductFilter";

  static propTypes = {
    noPopover: PropTypes.bool.isRequired,
    productId: PropTypes.string,
    updateProductId: PropTypes.func.isRequired
  };

  static defaultProps = {
    noPopover: false
  };

  static contextType = GlobalContext;

  render() {
    const { noPopover, productId, updateProductId } = this.props;

    const { isLoading, products } = this.context;

    return (
      <SelectFilter
        onChange={updateProductId}
        options={products}
        placeholder="All Products"
        selectedValue={productId}
        categoryLabel="Product"
        noPopover={noPopover}
        isLoading={isLoading}
      />
    );
  }
}

const withProductId = graphql(
  gql`
    query withProductId {
      localFilters @client {
        productId
      }
    }
  `,
  {
    name: "withProductId",
    options: props => ({
      fetchPolicy: "cache-only"
    }),
    props: props => ({
      productId: get(props, "withProductId.localFilters.productId", null)
    })
  }
);

const withMutation = graphql(setProductIdFilter, {
  name: "mutateProductId",
  options: props => ({
    fetchPolicy: "no-cache"
  })
});

const withUpdateProductId = withHandlers({
  updateProductId: ({ mutateProductId }) => productId => {
    mutateProductId({ variables: { productId } });
  }
});

export default compose(
  withProductId,
  withMutation,
  withUpdateProductId
)(ProductFilter);
