import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { matches } from "lodash";

import styles from "./Loader.module.scss";

const isSmall = matches("small");
const isExtraSmall = matches("extraSmall");

export default class Loader extends PureComponent {
  static displayName = "Utility.Loader";

  static propTypes = {
    size: PropTypes.oneOf(["default", "small", "extraSmall"]).isRequired,
    unwrapped: PropTypes.bool.isRequired
  };

  static defaultProps = {
    size: "default",
    unwrapped: false
  };

  render() {
    const { size, unwrapped } = this.props;

    const loaderClass = classNames(styles.loader, {
      [styles.small]: isSmall(size),
      [styles.extraSmall]: isExtraSmall(size)
    });

    const wrapperClass = classNames({
      [styles.wrapper]: !unwrapped,
      [styles.nested]: unwrapped
    });

    return (
      <div className={wrapperClass}>
        <div className={loaderClass} />
      </div>
    );
  }
}
