import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Filter2 extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 22;
  }

  get defaultWidth() {
    return 22;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 22 22";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            d="M11,5 C7.687,5 5,5.839 5,6.875 L5,8 L9.5,12.5 L9.5,16.25 C9.5,16.664 10.172,17 11,17 C11.829,17 12.5,16.664 12.5,16.25 L12.5,12.5 L17,8 L17,6.875 C17,5.839 14.314,5 11,5 M6.106,6.627 C6.387,6.467 6.781,6.315 7.246,6.187 C8.277,5.905 9.61,5.75 11,5.75 C12.391,5.75 13.724,5.905 14.754,6.187 C15.219,6.315 15.614,6.467 15.894,6.627 C16.079,6.732 16.179,6.822 16.226,6.875 C16.179,6.928 16.079,7.018 15.894,7.123 C15.614,7.283 15.219,7.435 14.754,7.563 C13.724,7.845 12.391,8 11,8 C9.61,8 8.277,7.845 7.246,7.563 C6.781,7.435 6.387,7.283 6.106,7.123 C5.921,7.018 5.821,6.928 5.774,6.875 C5.821,6.822 5.921,6.732 6.106,6.627"
          />
          <path
            fill={this.fill}
            d="M11,5 C7.687,5 5,5.839 5,6.875 L5,8 L9.5,12.5 L9.5,16.25 C9.5,16.664 10.172,17 11,17 C11.829,17 12.5,16.664 12.5,16.25 L12.5,12.5 L17,8 L17,6.875 C17,5.839 14.314,5 11,5 M6.106,6.627 C6.387,6.467 6.781,6.315 7.246,6.187 C8.277,5.905 9.61,5.75 11,5.75 C12.391,5.75 13.724,5.905 14.754,6.187 C15.219,6.315 15.614,6.467 15.894,6.627 C16.079,6.732 16.179,6.822 16.226,6.875 C16.179,6.928 16.079,7.018 15.894,7.123 C15.614,7.283 15.219,7.435 14.754,7.563 C13.724,7.845 12.391,8 11,8 C9.61,8 8.277,7.845 7.246,7.563 C6.781,7.435 6.387,7.283 6.106,7.123 C5.921,7.018 5.821,6.928 5.774,6.875 C5.821,6.822 5.921,6.732 6.106,6.627"
          />
        </g>
      </svg>
    );
  }
}
