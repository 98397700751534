import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Briefcase extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 22;
  }

  get defaultWidth() {
    return 22;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 22 22";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            d="M13.5246141,8.01315213 L15.6875,8.01315213 C16.409375,8.01315213 17,8.58348068 17,9.2805489 L17,14.7326032 C17,15.4296715 16.409375,16 15.6875,16 L6.3125,16 C5.590625,16 5,15.4296715 5,14.7326032 L5,9.2805489 C5,8.58348068 5.590625,8.01315213 6.3125,8.01315213 L8.5,8.01315213 L8.5,6.84493118 C8.5,6.38021903 8.89375,6 9.375,6 L12.6496141,6 C13.1308641,6 13.5246141,6.38021903 13.5246141,6.84493118 L13.5246141,8.01315213 Z M9.5,7.00332265 L9.5,8.0147465 L12.4937445,8.0147465 L12.4926508,7.00226496 L9.5,7.00332265 Z"
          />
          <path
            fill={this.fill}
            d="M13.5246141,8.01315213 L15.6875,8.01315213 C16.409375,8.01315213 17,8.58348068 17,9.2805489 L17,14.7326032 C17,15.4296715 16.409375,16 15.6875,16 L6.3125,16 C5.590625,16 5,15.4296715 5,14.7326032 L5,9.2805489 C5,8.58348068 5.590625,8.01315213 6.3125,8.01315213 L8.5,8.01315213 L8.5,6.84493118 C8.5,6.38021903 8.89375,6 9.375,6 L12.6496141,6 C13.1308641,6 13.5246141,6.38021903 13.5246141,6.84493118 L13.5246141,8.01315213 Z M9.5,7.00332265 L9.5,8.0147465 L12.4937445,8.0147465 L12.4926508,7.00226496 L9.5,7.00332265 Z"
          />
        </g>
      </svg>
    );
  }
}
