import { flow, method } from "lodash";
import { mapValues, pick } from "lodash/fp";
import { DateUtils } from "react-day-picker";
import moment from "./moment";

import { chartScaleFromDuration } from "./ChartScale";
import { isValidMoment, tryMoment } from "./moment";
import { fiscalYear, fiscalQuarterLabel, fiscalQuarterNumber } from "./quarters";

const { isDayInRange } = DateUtils;

const PRIVATE = Symbol("PRIVATE");
const CALCULATE = Symbol("CALCULATE");
const CONFIGURE = Symbol("CONFIGURE");
const DAYPICKER = Symbol("DAYPICKER");
const DURATION = Symbol("DURATION");
const FILTERS = Symbol("FILTERS");
const IS_VALID = Symbol("IS_VALID");
const START_DATE = Symbol("START_DATE");
const START_TIME = Symbol("START_TIME");
const UNTIL_DATE = Symbol("UNTIL_DATE");
const UNTIL_TIME = Symbol("UNTIL_TIME");

const DEFAULT_START = moment.utc().startOf("isoweek");
const DEFAULT_UNTIL = moment.utc().endOf("isoweek");

/**
 * @param {moment} start
 * @param {moment} until
 * @return {boolean}
 */
function checkValidRange(start, until) {
  if (isValidMoment(start) && isValidMoment(until)) {
    return start.isBefore(until);
  }

  return false;
}

const toDaypicker = pick(["from", "to"]);
const toFilters = flow(
  pick(["startTime", "untilTime"]),
  mapValues(method("toISOString"))
);

export default class DateRange {
  static displayName = "Utilities.Temporal.DateRange";

  constructor(startTime, untilTime) {
    Object.defineProperty(this, PRIVATE, {
      value: new Map()
    });

    this[CONFIGURE](startTime, untilTime);
  }

  *[Symbol.iterator]() {
    yield ["chartScale", this.chartScale];
    yield ["startTime", this[START_TIME].toISOString()];
    yield ["untilTime", this[UNTIL_TIME].toISOString()];
  }

  get chartScale() {
    return this[DURATION] ? chartScaleFromDuration(this[DURATION]) : null;
  }

  get daypicker() {
    return this[DAYPICKER];
  }

  get duration() {
    return this[DURATION];
  }

  get filters() {
    return this[FILTERS];
  }

  get from() {
    return this[START_DATE];
  }

  get to() {
    return this[UNTIL_DATE];
  }

  get startTime() {
    return this[START_TIME];
  }

  get untilTime() {
    return this[UNTIL_TIME];
  }

  get quarterLabel() {
    return fiscalQuarterLabel(this[START_TIME]);
  }

  get quarterNumber() {
    return fiscalQuarterNumber(this[START_TIME]);
  }

  get quarterYear() {
    return fiscalYear(this[START_TIME]);
  }

  get __typename() {
    return "DateRange";
  }

  contains(day) {
    return this[IS_VALID] ? isDayInRange(day, this[DAYPICKER]) : false;
  }

  formatStart(dateFormat) {
    return this.startTime.format(dateFormat);
  }

  formatUntil(dateFormat) {
    return this.untilTime.format(dateFormat);
  }

  getDisplayText({ dateFormat = "MMMM D, YYYY" } = {}) {
    if (!this.isValid()) {
      return null;
    }

    if (this.isSameDay()) {
      return this.formatStart(dateFormat);
    } else {
      return `${this.formatStart(dateFormat)} – ${this.formatUntil(
        dateFormat
      )}`;
    }
  }

  isSameDay() {
    return DateUtils.isSameDay(this.from, this.to);
  }

  isValid() {
    return this[IS_VALID];
  }

  [CONFIGURE](startTime, untilTime) {
    this[START_TIME] = tryMoment(startTime, DEFAULT_START).startOf("day");
    this[UNTIL_TIME] = tryMoment(untilTime, DEFAULT_UNTIL).endOf("day");
    this[CALCULATE]();
  }

  [CALCULATE]() {
    this[IS_VALID] = checkValidRange(this[START_TIME], this[UNTIL_TIME]);
    this[START_DATE] = this.isValid() ? this[START_TIME].toDate() : null;
    this[UNTIL_DATE] = this.isValid() ? this[UNTIL_TIME].toDate() : null;
    this[FILTERS] = this.isValid() ? toFilters(this) : null;
    this[DAYPICKER] = this.isValid() ? toDaypicker(this) : null;
    this[DURATION] = this.isValid()
      ? moment.duration(this[UNTIL_TIME].diff(this[START_TIME]))
      : null;
  }
}
