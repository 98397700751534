import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Icons from "Components/Icon";

export default class IconComposer extends PureComponent {
  static displayName = "IconComposer";

  static propTypes = {
    icon: PropTypes.string.isRequired,
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fill: PropTypes.string,
    stroke: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    fill: "currentColor",
    svgProps: { "aria-hidden": true }
  };

  get iconComponent() {
    const key = this.props.icon;
    const component = Icons[key];

    return component;
  }

  render() {
    const { iconClass, size, fill, stroke, icon } = this.props;
    const IconComponent = this.iconComponent;

    if (!IconComponent) return false;

    return React.createElement(IconComponent, {
      svgProps: this.props.svgProps,
      iconClass,
      icon,
      size,
      fill,
      stroke
    });
  }
}
