import React from "react";
import { Link } from "react-router-dom";
import {
  constant,
  first,
  flow,
  get,
  has,
  identity,
  isEmpty,
  isFunction,
  isString,
  memoize,
  negate,
  overEvery,
  property,
  reduceRight,
  startsWith,
  toPath,
  toSafeInteger,
  transform,
  uniqueId
} from "lodash";

import { isPresent } from "Utilities";

const { origin: WINDOW_ORIGIN } = window.location;

const KEY_PATTERN = /^:(\w+)$/;

const isKey = value => KEY_PATTERN.test(value);

const firstElementIsNotKey = flow(
  first,
  negate(isKey)
);

const shouldStartNewCluster = overEvery(isPresent, firstElementIsNotKey);

const extractParts = memoize(path =>
  Object.freeze(path.split("/").filter(identity))
);

const extractKeys = memoize(path =>
  Object.freeze(
    extractParts(path)
      .filter(isKey)
      .map(k => k.slice(1))
  )
);

function calculateRank(name, path) {
  if (path === "/") {
    return 0;
  }

  const namePath = toPath(name);
  const parts = extractParts(path);
  const keyCount = extractKeys(path).length;

  const id = toSafeInteger(uniqueId());

  return id + Math.pow(namePath.length, parts.length * keyCount);
}

/**
 * @param {string[]} pathParts
 * @return {string[][]}
 */
function extractClusters(pathParts) {
  return reduceRight(
    pathParts,
    (clusters, part) => {
      const currentCluster = first(clusters);

      if (shouldStartNewCluster(currentCluster)) {
        // Start a new cluster
        clusters.unshift([part]);
      } else {
        // Add to the current cluster
        currentCluster.unshift(part);
      }

      return clusters;
    },
    [[]]
  );
}

/**
 * @param {string[][]} clusters
 * @return {function[]}
 */
function extractResolvers(clusters) {
  return clusters.map(([part, paramKey]) => {
    if (part && paramKey) {
      const key = paramKey.slice(1);

      return (previous, params) => previous[part](params[key]);
    } else {
      return previous => previous[part]();
    }
  });
}

function validateParams(name, keys, rawParams) {
  if (isEmpty(keys)) {
    return {};
  }

  return transform(
    keys,
    (params, key) => {
      if (has(rawParams, key)) {
        const value = get(rawParams, key);

        if (isPresent(value)) {
          params[key] = value;
        } else {
          throw new Error(`Empty parameter for '${name}' route: ${key}`);
        }
      } else {
        throw new Error(`Missing parameter for '${name}' route: ${key}`);
      }
    },
    {}
  );
}

const CLUSTERS = Symbol("CLUSTERS");
const GENERATOR = Symbol("GENERATOR");
const KEYS = Symbol("KEYS");
const NAME = Symbol("NAME");
const PARTS = Symbol("PARTS");
const PATH = Symbol("PATH");
const RANK = Symbol("RANK");
const RESOLVERS = Symbol("RESOLVERS");
const ROUTER = Symbol("ROUTER");

export default class Route {
  constructor(router, name, path) {
    this[ROUTER] = router;
    this[NAME] = Object.freeze(name);
    this[PATH] = Object.freeze(path);
    this[PARTS] = extractParts(path);
    this[KEYS] = extractKeys(path);

    this[RANK] = calculateRank(name, path);

    this[CLUSTERS] = this[KEYS].length ? extractClusters(this[PARTS]) : null;

    this[RESOLVERS] = this[KEYS].length
      ? extractResolvers(this[CLUSTERS])
      : null;

    this[GENERATOR] = this[KEYS].length
      ? (params = {}) => {
          return this[RESOLVERS].reduce((r, fn) => fn(r, params), router);
        }
      : constant(this[PATH]);
  }

  /**
   * @param {string} targetName
   * @return {boolean}
   */
  contains(targetName) {
    return startsWith(this[NAME], targetName);
  }

  /**
   * @return {string}
   */
  generate(rawParams = {}) {
    const params = validateParams(this.name, this[KEYS], rawParams);

    const route = this[GENERATOR](params);

    return route.toString();
  }

  /**
   * @return {string}
   */
  generateURL(rawParams = {}) {
    return new URL(this.generate(rawParams), WINDOW_ORIGIN).toString();
  }

  isRoot() {
    return this.path === "/";
  }

  isTopLevel() {
    return this.isRoot() || this[PARTS].length === 1;
  }

  get name() {
    return this[NAME];
  }

  get path() {
    return this[PATH];
  }

  get rank() {
    return this[RANK];
  }

  toString() {
    return this[PATH];
  }

  generateLink(label, params = {}) {
    return <Link to={this.generate(params)}>{label}</Link>;
  }

  linkGenerator(label, { alterParams = identity } = {}) {
    let displayLabel;

    if (isFunction(label)) {
      displayLabel = label;
    } else if (isString(label)) {
      displayLabel = property(label);
    } else {
      throw new TypeError("Must provide a label property or function");
    }

    return flow(
      alterParams,
      (params = {}) => this.generateLink(displayLabel(params), params)
    );
  }

  tableCellLinkGenerator(label, opts = {}) {
    const { alterParams = identity } = opts;

    const wrappedAlter = flow(
      property("original"),
      alterParams
    );

    return this.linkGenerator(label, { alterParams: wrappedAlter });
  }
}
