import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class ChartBar2 extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 22;
  }

  get defaultWidth() {
    return 22;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 22 22";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            d="M5,15.9772897 L5,12.7311124 L7.17749109,12.7088756 L7.17749109,15.976201 L5,15.9772897 Z M8.26623663,15.976201 L8.26623663,8.26623663 L10.44831,8.26623663 L10.44831,15.976201 L8.26623663,15.976201 Z M11.5351181,15.976201 L11.5351181,10.5560989 L13.7256204,10.5560989 L13.7256204,15.976201 L11.5351181,15.976201 Z M14.8023036,15.9772897 L14.8023036,5 L17,5 L17,15.9772897 L14.8023036,15.9772897 Z"
          />
          <path
            fill={this.fill}
            d="M5,15.9772897 L5,12.7311124 L7.17749109,12.7088756 L7.17749109,15.976201 L5,15.9772897 Z M8.26623663,15.976201 L8.26623663,8.26623663 L10.44831,8.26623663 L10.44831,15.976201 L8.26623663,15.976201 Z M11.5351181,15.976201 L11.5351181,10.5560989 L13.7256204,10.5560989 L13.7256204,15.976201 L11.5351181,15.976201 Z M14.8023036,15.9772897 L14.8023036,5 L17,5 L17,15.9772897 L14.8023036,15.9772897 Z"
          />
        </g>
      </svg>
    );
  }
}
