import React, { Component } from "react";
import PropTypes from "prop-types";
import { setDisplayName, withPropsOnChange } from "recompose";
import { flow, isEmpty, overEvery, overSome, property } from "lodash";
import { every } from "lodash/fp";

import { cardPropsShape } from "Components/Layout";
import { Chart } from "Components/Chart";
import { wrapGraphQL } from "HOC/graphql";

import DataCard from "./DataCard";
import { chartPropsShape } from "./shapes";
import { withDefaultCardProps } from "./utilities";

const hasNoLabelsOrCategories = overEvery(
  flow(
    property("options.labels"),
    isEmpty
  ),
  flow(
    property("options.xaxis.categories"),
    isEmpty
  )
);

const hasNoSeriesData = overSome(
  flow(
    property("series"),
    overSome(
      every(
        flow(
          property("data"),
          every(value => value === 0)
        )
      )
    )
  )
);

const hasNoData = overSome(hasNoLabelsOrCategories, hasNoSeriesData);

const checkData = withPropsOnChange(["chartProps"], ({ chartProps }) => ({
  hasNoData: hasNoData(chartProps)
}));

export default class LineGraph extends Component {
  static displayName = "Data.LineGraph";

  static propTypes = {
    cardProps: cardPropsShape,
    chartProps: chartPropsShape,
    hasNoData: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
  };

  static defaultProps = {
    hasNoData: false,
    isLoading: false
  };

  render() {
    return (
      <DataCard {...this.props} absoluteIfData>
        {() => <Chart {...this.props.chartProps} type="line" />}
      </DataCard>
    );
  }

  static extendAs(displayName, cardProps = {}, ...hocs) {
    return wrapGraphQL(
      setDisplayName(displayName),
      ...hocs,
      withDefaultCardProps(cardProps),
      checkData
    )(LineGraph);
  }
}
