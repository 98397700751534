import auth0 from "auth0-js";

import { BehaviorSubject } from "rxjs";

import { bindAll, get } from "lodash";

import jwtDecode from "jwt-decode";

import { isPresent } from "Utilities";

import {
  isValidAuth,
  getAuthConfig,
  getFinishSignOutURL,
  getSignOutURL,
  loadAuthMap,
  persistAuthMap
} from "./utilities";

const PRIVATE = Symbol("PRIVATE");
const SUBJECT = Symbol("SUBJECT");

const AUTH_KEY = "MENTOR_SCA_AUTH";

const NAME_PATHS = ["nickname", "name"];

const PRIVATE_KEYS = ["accessToken", "idToken", "expiresAt", "profile"];

export const finishSignOutURL = getFinishSignOutURL();

export const signOutURL = getSignOutURL();

export default class Auth {
  constructor() {
    this[PRIVATE] = loadAuthMap(AUTH_KEY);
    this[SUBJECT] = new BehaviorSubject(this.toContext());

    const authConfig = getAuthConfig();

    console.debug("authConfig:");

    console.dir(authConfig);

    const auth0Auth = new auth0.WebAuth(authConfig);

    Object.defineProperties(this, {
      authConfig: {
        value: authConfig
      },

      auth0: {
        value: auth0Auth
      }
    });

    bindAll(
      this,
      "getProfile",
      "handleAuthentication",
      "isAuthenticated",
      "restoreLocation",
      "signIn",
      "signOut",
      "storeLocation",
      "subscribe",
      "toContext"
    );
  }

  get accessToken() {
    return this[PRIVATE].get("accessToken");
  }

  get bearerToken() {
    return this.isAuthenticated() ? `Bearer ${this.idToken}` : "";
  }

  get idToken() {
    return this[PRIVATE].get("idToken");
  }

  get expiresAt() {
    return this[PRIVATE].get("expiresAt");
  }

  get finishSignOutURL() {
    return finishSignOutURL;
  }

  get profile() {
    return this[PRIVATE].get("profile");
  }

  get redirectTo() {
    const storedLocation = this[PRIVATE].get("redirectTo");

    return isPresent(storedLocation) ? storedLocation : "/";
  }

  get signOutURL() {
    return signOutURL;
  }

  get username() {
    if (!this.isAuthenticated()) {
      return null;
    }

    for (const name_path of NAME_PATHS) {
      const value = get(this.profile, name_path, null);

      if (isPresent(value)) {
        return value;
      }
    }

    return null;
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  getProfile() {
    return this.profile;
  }

  decodeToken() {
    return this.idToken ? jwtDecode(this.idToken) : null;
  }

  toggleAuthenticating(value) {
    this[PRIVATE].set("isAuthenticating", Boolean(value));

    this.refreshAuth();
  }

  /**
   * @return {void}
   */
  async handleAuthentication() {
    try {
      this.toggleAuthenticating(true);

      const auth = new Promise((resolve, reject) => {
        this.auth0.parseHash((err, authResult) => {
          if (err) {
            console.warn("Something went wrong while authenticating");
            console.error(err);

            if (!(err instanceof Error)) {
              const error = new Error(err.errorDescription || "Something went wrong while authenticating");

              error.code = err.error;

              error.originalError = err;

              return reject(error);
            }

            return reject(err);
          }

          if (isValidAuth(authResult)) {
            this.setSession(authResult);

            return resolve(this);
          } else {
            console.warn("Invalid Auth result");
            console.error(authResult);

            return reject(new Error(`Invalid Auth Result`));
          }
        });
      });

      return await auth;
    } finally {
      this.toggleAuthenticating(false);
    }
  }

  /**
   * Check if the token is present & not expired
   *
   * @return {boolean}
   */
  isAuthenticated() {
    return (new Date().getTime() < this.expiresAt) || Boolean(this.idToken);
  }

  isAuthenticating() {
    return !this.isAuthenticated() && this[PRIVATE].get("isAuthenticating");
  }

  refreshAuth() {
    this[SUBJECT].next(this.toContext());
  }

  restoreLocation() {
    const { redirectTo } = this;

    this.storeLocation("/");

    return redirectTo;
  }

  /**
   * Redirect to Auth0 to sign the user in.
   *
   * @return {void}
   */
  signIn() {
    this.auth0.authorize();
  }

  /**
   * Clear the local session.
   *
   * @return {void}
   */
  signOut() {
    this.clearSession();
  }

  storeLocation(redirectTo) {
    this[PRIVATE].set("redirectTo", redirectTo);

    persistAuthMap(AUTH_KEY, this[PRIVATE]);
  }

  /**
   * @param {function} fn
   * @return {Subscription}
   */
  subscribe(fn) {
    return this[SUBJECT].subscribe(fn);
  }

  /**
   * @return {object}
   */
  toContext() {
    const isAuthenticated = this.isAuthenticated();
    const isAuthenticating = this.isAuthenticating();

    const { profile: authenticatedUser } = this;

    return {
      authenticatedUser,
      authState: this,
      isAuthenticated,
      isAuthenticating
    };
  }

  /**
   * @private
   * @return {void}
   */
  clearSession() {
    for (const key of PRIVATE_KEYS) {
      this[PRIVATE].delete(key);
    }

    persistAuthMap(AUTH_KEY, this[PRIVATE]);

    this.refreshAuth();
  }

  /**
   * @private
   * @return {void}
   */
  setSession({ accessToken, idToken, idTokenPayload, expiresIn = 0 }) {
    this[PRIVATE].set("accessToken", accessToken);
    this[PRIVATE].set("idToken", idToken);
    this[PRIVATE].set("profile", idTokenPayload);
    this[PRIVATE].set("expiresAt", expiresIn * 1000 + new Date().getTime());

    persistAuthMap(AUTH_KEY, this[PRIVATE]);

    this.refreshAuth();
  }
}
