import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { find, get, matchesProperty } from "lodash";
import { compose, withPropsOnChange } from "recompose";

import { FilterWrapper } from "Components/Utility";

import Selector from "./Selector";
import SelectDetails from "./SelectDetails";

import { optionsShape, optionShape } from "./utilities";

export class SelectFilter extends PureComponent {
  static displayName = "Utility.SelectFilter";

  static propTypes = {
    categoryLabel: PropTypes.string,
    displayText: PropTypes.string.isRequired,
    handleCheckbox: PropTypes.func,
    includeEmployees: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    noPopover: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    options: optionsShape,
    placeholder: PropTypes.node,
    selectedValue: PropTypes.string,
    value: optionShape,
    themes: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    displayText: "Select something...",
    includeEmployees: false,
    isClearable: true,
    isLoading: true,
    isSearchable: true,
    noPopover: false
  };

  render() {
    const {
      categoryLabel,
      displayText,
      handleCheckbox,
      includeEmployees,
      isClearable,
      isLoading,
      isSearchable,
      noPopover,
      onChange,
      options,
      placeholder,
      selectedValue,
      value,
      themes
    } = this.props;

    return (
      <FilterWrapper
        displayText={displayText}
        isClearable={isClearable}
        isSelectFilter
        value={value}
        selectedValue={selectedValue}
        onClear={onChange}
        noPopover={noPopover}
        themes={themes}
        categoryLabel={categoryLabel}
      >
        <Selector
          isClearable={isClearable}
          isSearchable={isSearchable}
          onChange={onChange}
          options={options}
          selectedValue={selectedValue}
          value={value}
          categoryLabel={categoryLabel}
          isLoading={isLoading}
          themes={themes}
        />
        <SelectDetails
          isClearable={isClearable}
          selectedValue={selectedValue}
          categoryLabel={categoryLabel}
          includeEmployees={includeEmployees}
          placeholder={placeholder}
          value={value}
          onClear={onChange}
          handleCheckbox={handleCheckbox}
          noPopover={noPopover}
          themes={themes}
        />
      </FilterWrapper>
    );
  }
}

const withValueAndDisplayText = withPropsOnChange(
  ["options", "placeholder", "selectedValue"],
  ({ options, placeholder = "Select something...", selectedValue }) => {
    const value = find(options, matchesProperty("value", selectedValue));

    const displayText = get(value, "label", placeholder);

    return { displayText, value };
  }
);

export default compose(withValueAndDisplayText)(SelectFilter);
