import { get, has, memoize } from "lodash";
import globalConfig from "config";

export { globalConfig };

export const fetchValue = memoize(function fetchValue(path) {
  if (!has(globalConfig, path)) {
    throw new Error(`Missing config value: ${path}`);
  }

  return get(globalConfig, path);
})

export const getMapsAPIKey = memoize(function getMapsAPIKey() {
  return fetchValue("Google.mapsApiKey");
});
