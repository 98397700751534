import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import { compose, withHandlers } from "recompose";
import { get } from "lodash";
import gql from "graphql-tag";

import { GlobalContext } from "Components/Application";
import SelectFilter from "Components/Utility/SelectFilter";
import { setCompanyFilter, setIncludeEmployeesFilter } from "Queries";

export class CompanyFilter extends Component {
  static displayName = "GlobalSearch.CompanyFilter";

  static propTypes = {
    company: PropTypes.string,
    includeEmployees: PropTypes.bool.isRequired,
    noPopover: PropTypes.bool.isRequired,
    updateCompany: PropTypes.func.isRequired,
    updateIncludeEmployees: PropTypes.func.isRequired
  };

  static defaultProps = {
    noPopover: false
  };

  static contextType = GlobalContext;

  render() {
    const {
      company,
      includeEmployees,
      updateCompany,
      updateIncludeEmployees,
      noPopover
    } = this.props;

    const { companies, isLoading } = this.context;

    return (
      <SelectFilter
        onChange={updateCompany}
        options={companies}
        placeholder="All Companies"
        selectedValue={company}
        categoryLabel="Company"
        includeEmployees={includeEmployees}
        handleCheckbox={updateIncludeEmployees}
        noPopover={noPopover}
        isLoading={isLoading}
      />
    );
  }
}

const withLocalFilters = graphql(
  gql`
    query withLocalFilters {
      localFilters @client {
        company
        includeEmployees
      }
    }
  `,
  {
    name: "withLocalFilters",
    options: props => ({
      fetchPolicy: "cache-only"
    }),
    props: props => ({
      company: get(props, "withLocalFilters.localFilters.company", null),
      includeEmployees: get(
        props,
        "withLocalFilters.localFilters.includeEmployees",
        false
      )
    })
  }
);

const withCompanyMutation = graphql(setCompanyFilter, {
  name: "mutateCompany",
  options: props => ({
    fetchPolicy: "no-cache"
  })
});

const withIncludeEmployeesMutation = graphql(setIncludeEmployeesFilter, {
  name: "mutateIncludeEmployees",
  options: props => ({
    fetchPolicy: "no-cache"
  })
});

const withUpdateMethods = withHandlers({
  updateCompany: ({ mutateCompany }) => company => {
    mutateCompany({ variables: { company } });
  },
  updateIncludeEmployees: ({ mutateIncludeEmployees }) => includeEmployees => {
    mutateIncludeEmployees({ variables: { includeEmployees } });
  }
});

export default compose(
  withLocalFilters,
  withCompanyMutation,
  withIncludeEmployeesMutation,
  withUpdateMethods
)(CompanyFilter);
