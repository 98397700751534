import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class User extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 24;
  }

  get defaultWidth() {
    return 24;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 24 24";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M8.5,9.5 C8.5,7.56704106 10.0674741,6 12,6 C13.9333424,6 15.5,7.56665763 15.5,9.5 C15.5,11.4333424 13.9333424,13 12,13 C10.0674741,13 8.5,11.4329589 8.5,9.5 Z M9.5,9.5 C9.5,10.8806123 10.619697,12 12,12 C13.3810576,12 14.5,10.8810576 14.5,9.5 C14.5,8.11894237 13.3810576,7 12,7 C10.619697,7 9.5,8.11938767 9.5,9.5 Z M12,23 C5.92499399,23 1,18.075006 1,12 C1,5.92499399 5.92499399,1 12,1 C18.075006,1 23,5.92499399 23,12 C23,18.075006 18.075006,23 12,23 Z M12,22 C17.5227213,22 22,17.5227213 22,12 C22,6.47727874 17.5227213,2 12,2 C6.47727874,2 2,6.47727874 2,12 C2,17.5227213 6.47727874,22 12,22 Z M17.5000041,18.0009054 C17.4995041,18.2770473 17.2752415,18.5004992 16.9990995,18.4999992 C16.7229576,18.4994991 16.4995057,18.2752365 16.5000058,17.9990946 C16.5024969,16.623379 15.3630465,15.5025121 13.95,15.5 L10.056,15.5 C8.64215933,15.5 7.5,16.6191819 7.5,17.994 C7.5,18.2701424 7.27614237,18.494 7,18.494 C6.72385763,18.494 6.5,18.2701424 6.5,17.994 C6.5,16.0622973 8.09437216,14.5 10.056,14.5 L13.945,14.5 L13.9508886,14.5000008 C15.9126867,14.5034874 17.5035036,16.0683595 17.5000041,18.0009054 Z"
          />
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M8.5,9.5 C8.5,7.56704106 10.0674741,6 12,6 C13.9333424,6 15.5,7.56665763 15.5,9.5 C15.5,11.4333424 13.9333424,13 12,13 C10.0674741,13 8.5,11.4329589 8.5,9.5 Z M9.5,9.5 C9.5,10.8806123 10.619697,12 12,12 C13.3810576,12 14.5,10.8810576 14.5,9.5 C14.5,8.11894237 13.3810576,7 12,7 C10.619697,7 9.5,8.11938767 9.5,9.5 Z M12,23 C5.92499399,23 1,18.075006 1,12 C1,5.92499399 5.92499399,1 12,1 C18.075006,1 23,5.92499399 23,12 C23,18.075006 18.075006,23 12,23 Z M12,22 C17.5227213,22 22,17.5227213 22,12 C22,6.47727874 17.5227213,2 12,2 C6.47727874,2 2,6.47727874 2,12 C2,17.5227213 6.47727874,22 12,22 Z M17.5000041,18.0009054 C17.4995041,18.2770473 17.2752415,18.5004992 16.9990995,18.4999992 C16.7229576,18.4994991 16.4995057,18.2752365 16.5000058,17.9990946 C16.5024969,16.623379 15.3630465,15.5025121 13.95,15.5 L10.056,15.5 C8.64215933,15.5 7.5,16.6191819 7.5,17.994 C7.5,18.2701424 7.27614237,18.494 7,18.494 C6.72385763,18.494 6.5,18.2701424 6.5,17.994 C6.5,16.0622973 8.09437216,14.5 10.056,14.5 L13.945,14.5 L13.9508886,14.5000008 C15.9126867,14.5034874 17.5035036,16.0683595 17.5000041,18.0009054 Z"
          />
        </g>
      </svg>
    );
  }
}
