import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Dashboard extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 24;
  }

  get defaultWidth() {
    return 24;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 24 24";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M6.5,15 C6.77614237,15 7,15.2238576 7,15.5 C7,15.7761424 6.77614237,16 6.5,16 L2,16 C1.72385763,16 1.5,15.7761424 1.5,15.5 C1.5,9.70119525 6.20151998,5 12,5 C17.7993177,5 22.501,9.70103289 22.501,15.5 C22.501,15.7761424 22.2771424,16 22.001,16 L17.5,16 C17.2238576,16 17,15.7761424 17,15.5 C17,15.2238576 17.2238576,15 17.5,15 L21.4880658,15 C21.2280181,9.98576539 17.0793335,6 12,6 C6.92149682,6 2.77297906,9.98589605 2.51293446,15 L6.5,15 Z M9.77084507,15.363469 L11.5378451,9.12846896 C11.6756515,8.64220809 12.3655221,8.64407999 12.5006877,9.13108154 L14.2336877,15.3750815 C14.2560732,15.4557367 14.2574341,15.5361721 14.241616,15.6113291 C14.248184,15.6723152 14.2508989,15.7244405 14.2508976,15.7873528 C14.2470406,17.0292971 13.2367991,18.0343702 11.9948648,18.0317989 C10.7528142,18.0292274 9.7470424,17.0178835 9.7509,15.7778 C9.7509,15.7137787 9.75386781,15.6599116 9.76091862,15.5961412 C9.74628233,15.5219296 9.74837354,15.4427615 9.77084507,15.363469 Z M12.0138761,11.1162988 L10.7784647,15.4755462 C10.7799986,15.5083731 10.7783034,15.5419145 10.7730858,15.5758286 C10.7561011,15.6862296 10.7509,15.7320486 10.7508976,15.7793544 C10.7487555,16.468382 11.3076511,17.030374 11.9969352,17.0318011 C12.6869796,17.0332297 13.2487593,16.4743242 13.2509,15.7858 C13.2509,15.7403728 13.2458882,15.69646 13.2287142,15.5848286 C13.2230294,15.5478775 13.221526,15.5113689 13.2238249,15.4757448 L12.0138761,11.1162988 Z"
          />
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M6.5,15 C6.77614237,15 7,15.2238576 7,15.5 C7,15.7761424 6.77614237,16 6.5,16 L2,16 C1.72385763,16 1.5,15.7761424 1.5,15.5 C1.5,9.70119525 6.20151998,5 12,5 C17.7993177,5 22.501,9.70103289 22.501,15.5 C22.501,15.7761424 22.2771424,16 22.001,16 L17.5,16 C17.2238576,16 17,15.7761424 17,15.5 C17,15.2238576 17.2238576,15 17.5,15 L21.4880658,15 C21.2280181,9.98576539 17.0793335,6 12,6 C6.92149682,6 2.77297906,9.98589605 2.51293446,15 L6.5,15 Z M9.77084507,15.363469 L11.5378451,9.12846896 C11.6756515,8.64220809 12.3655221,8.64407999 12.5006877,9.13108154 L14.2336877,15.3750815 C14.2560732,15.4557367 14.2574341,15.5361721 14.241616,15.6113291 C14.248184,15.6723152 14.2508989,15.7244405 14.2508976,15.7873528 C14.2470406,17.0292971 13.2367991,18.0343702 11.9948648,18.0317989 C10.7528142,18.0292274 9.7470424,17.0178835 9.7509,15.7778 C9.7509,15.7137787 9.75386781,15.6599116 9.76091862,15.5961412 C9.74628233,15.5219296 9.74837354,15.4427615 9.77084507,15.363469 Z M12.0138761,11.1162988 L10.7784647,15.4755462 C10.7799986,15.5083731 10.7783034,15.5419145 10.7730858,15.5758286 C10.7561011,15.6862296 10.7509,15.7320486 10.7508976,15.7793544 C10.7487555,16.468382 11.3076511,17.030374 11.9969352,17.0318011 C12.6869796,17.0332297 13.2487593,16.4743242 13.2509,15.7858 C13.2509,15.7403728 13.2458882,15.69646 13.2287142,15.5848286 C13.2230294,15.5478775 13.221526,15.5113689 13.2238249,15.4757448 L12.0138761,11.1162988 Z"
          />
        </g>
      </svg>
    );
  }
}
