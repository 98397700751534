import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { IconComposer } from "Components/Utility";

import styles from "./IconButton.module.scss";

export default class IconButton extends PureComponent {
  static displayName = "Utility.IconButton";

  static propTypes = {
    handleClick: PropTypes.func.isRequired,
    icon: PropTypes.string,
    text: PropTypes.string,
    srText: PropTypes.string,
    className: PropTypes.string,
    iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    theme: PropTypes.oneOf(["pill"])
  };

  get iconSize() {
    if (this.props.iconSize) return this.props.iconSize;
    return "default";
  }

  get iconClasses() {
    return classNames(styles.icon, {
      [styles.iconHighlighted]: this.props.theme === "pill"
    });
  }

  get className() {
    return classNames(styles.button, this.props.className, {
      [styles.pillTheme]: this.props.theme === "pill"
    });
  }

  render() {
    const { srText, icon, text, handleClick } = this.props;

    if ((!icon && !text) || !handleClick) return false;

    return (
      <button onClick={handleClick} className={this.className}>
        {srText && <span className="a-hidden">{srText}</span>}
        {text && <span className={styles.text}>{text}</span>}
        {icon && (
          <IconComposer
            icon={icon}
            size={this.iconSize}
            iconClass={this.iconClasses}
          />
        )}
      </button>
    );
  }
}
