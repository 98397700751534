import { compose, lifecycle, withProps } from "recompose";
import { constant, flow, set } from "lodash";

import named from "HOC/named";
import requireAuthenticatedUser from "HOC/requireAuthenticatedUser";

import { NamedRouteContext } from "Routes";

const routeLifecycle = flow(
  lifecycle({
    componentDidMount() {
      this.context.setCurrentRoute(this.props.routeName);
    },

    componentWillUnmount() {
      this.context.clearCurrentRoute();
    }
  }),
  WrappedComponent => set(WrappedComponent, "contextType", NamedRouteContext)
);

export default function wrapRouteComponent(routeName, options = {}) {
  const { authenticated = false } = options;

  const hocs = [
    named(`wrapRouteComponent["${routeName}"]`),
    withProps(constant({ routeName }))
  ];

  if (authenticated) {
    hocs.push(requireAuthenticatedUser);
  }

  hocs.push(routeLifecycle);

  return compose(...hocs);
}
