import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Path extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 22;
  }

  get defaultWidth() {
    return 22;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 22 22";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            d="M16.4375,13.4333333 L16.25,13.4333333 L16.25,10.9416667 C16.25,10.2018333 15.66125,9.6 14.9375,9.6 L11.75,9.6 L11.75,8.06666667 L11.9375,8.06666667 C12.246875,8.06666667 12.5,7.80791667 12.5,7.49166667 L12.5,5.575 C12.5,5.25875 12.246875,5 11.9375,5 L10.0625,5 C9.753125,5 9.5,5.25875 9.5,5.575 L9.5,7.49166667 C9.5,7.80791667 9.753125,8.06666667 10.0625,8.06666667 L10.25,8.06666667 L10.25,9.6 L7.0625,9.6 C6.33875,9.6 5.75,10.2018333 5.75,10.9416667 L5.75,13.4333333 L5.5625,13.4333333 C5.253125,13.4333333 5,13.6920833 5,14.0083333 L5,15.925 C5,16.24125 5.253125,16.5 5.5625,16.5 L7.4375,16.5 C7.746875,16.5 8,16.24125 8,15.925 L8,14.0083333 C8,13.6920833 7.746875,13.4333333 7.4375,13.4333333 L7.25,13.4333333 L7.25,11.1333333 L10.25,11.1333333 L10.25,13.4333333 L10.0625,13.4333333 C9.753125,13.4333333 9.5,13.6920833 9.5,14.0083333 L9.5,15.925 C9.5,16.24125 9.753125,16.5 10.0625,16.5 L11.9375,16.5 C12.246875,16.5 12.5,16.24125 12.5,15.925 L12.5,14.0083333 C12.5,13.6920833 12.246875,13.4333333 11.9375,13.4333333 L11.75,13.4333333 L11.75,11.1333333 L14.75,11.1333333 L14.75,13.4333333 L14.5625,13.4333333 C14.253125,13.4333333 14,13.6920833 14,14.0083333 L14,15.925 C14,16.24125 14.253125,16.5 14.5625,16.5 L16.4375,16.5 C16.746875,16.5 17,16.24125 17,15.925 L17,14.0083333 C17,13.6920833 16.746875,13.4333333 16.4375,13.4333333"
          />
          <path
            fill={this.fill}
            d="M16.4375,13.4333333 L16.25,13.4333333 L16.25,10.9416667 C16.25,10.2018333 15.66125,9.6 14.9375,9.6 L11.75,9.6 L11.75,8.06666667 L11.9375,8.06666667 C12.246875,8.06666667 12.5,7.80791667 12.5,7.49166667 L12.5,5.575 C12.5,5.25875 12.246875,5 11.9375,5 L10.0625,5 C9.753125,5 9.5,5.25875 9.5,5.575 L9.5,7.49166667 C9.5,7.80791667 9.753125,8.06666667 10.0625,8.06666667 L10.25,8.06666667 L10.25,9.6 L7.0625,9.6 C6.33875,9.6 5.75,10.2018333 5.75,10.9416667 L5.75,13.4333333 L5.5625,13.4333333 C5.253125,13.4333333 5,13.6920833 5,14.0083333 L5,15.925 C5,16.24125 5.253125,16.5 5.5625,16.5 L7.4375,16.5 C7.746875,16.5 8,16.24125 8,15.925 L8,14.0083333 C8,13.6920833 7.746875,13.4333333 7.4375,13.4333333 L7.25,13.4333333 L7.25,11.1333333 L10.25,11.1333333 L10.25,13.4333333 L10.0625,13.4333333 C9.753125,13.4333333 9.5,13.6920833 9.5,14.0083333 L9.5,15.925 C9.5,16.24125 9.753125,16.5 10.0625,16.5 L11.9375,16.5 C12.246875,16.5 12.5,16.24125 12.5,15.925 L12.5,14.0083333 C12.5,13.6920833 12.246875,13.4333333 11.9375,13.4333333 L11.75,13.4333333 L11.75,11.1333333 L14.75,11.1333333 L14.75,13.4333333 L14.5625,13.4333333 C14.253125,13.4333333 14,13.6920833 14,14.0083333 L14,15.925 C14,16.24125 14.253125,16.5 14.5625,16.5 L16.4375,16.5 C16.746875,16.5 17,16.24125 17,15.925 L17,14.0083333 C17,13.6920833 16.746875,13.4333333 16.4375,13.4333333"
          />
        </g>
      </svg>
    );
  }
}
