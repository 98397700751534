import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Fire extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 22;
  }

  get defaultWidth() {
    return 22;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 22 22";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            d="M9.09676335,17.5 C8.25250604,15.6972656 8.70185284,14.664375 9.35112927,13.6903906 C10.0617702,12.625 10.2448741,11.5697656 10.2448741,11.5697656 C10.2448741,11.5697656 10.8040832,12.3152344 10.5803996,13.4801563 C11.567181,12.3528125 11.7532541,10.5571875 11.6038018,9.86859375 C13.8346998,11.4692187 14.7878296,14.9325 13.504123,17.5 C20.3373616,13.5319531 15.2035249,7.595625 14.3097801,6.92632813 C14.607695,7.595625 14.6641108,8.72703125 14.0623424,9.27648438 C13.0428992,5.3125 10.5239838,4.5 10.5239838,4.5 C10.8218987,6.54445313 9.44416583,8.77984375 8.1149307,10.4505469 C8.06841242,9.635 8.01892488,9.07335937 7.60125011,8.29234375 C7.50821354,9.77414063 6.40464156,10.9807031 6.10573686,12.4645313 C5.70092884,14.4744531 6.40860056,15.9450781 9.09676335,17.5"
          />
          <path
            fill={this.fill}
            d="M9.09676335,17.5 C8.25250604,15.6972656 8.70185284,14.664375 9.35112927,13.6903906 C10.0617702,12.625 10.2448741,11.5697656 10.2448741,11.5697656 C10.2448741,11.5697656 10.8040832,12.3152344 10.5803996,13.4801563 C11.567181,12.3528125 11.7532541,10.5571875 11.6038018,9.86859375 C13.8346998,11.4692187 14.7878296,14.9325 13.504123,17.5 C20.3373616,13.5319531 15.2035249,7.595625 14.3097801,6.92632813 C14.607695,7.595625 14.6641108,8.72703125 14.0623424,9.27648438 C13.0428992,5.3125 10.5239838,4.5 10.5239838,4.5 C10.8218987,6.54445313 9.44416583,8.77984375 8.1149307,10.4505469 C8.06841242,9.635 8.01892488,9.07335937 7.60125011,8.29234375 C7.50821354,9.77414063 6.40464156,10.9807031 6.10573686,12.4645313 C5.70092884,14.4744531 6.40860056,15.9450781 9.09676335,17.5"
          />
        </g>
      </svg>
    );
  }
}
