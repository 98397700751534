export class CustomPropTypeError extends Error {
  constructor(message) {
    super();

    this.message = message;

    this.stack = '';
  }
}

function createChainableTypeChecker(validate) {
  function checkType(isRequired, props, propName, componentName, location, propFullName, secret) {
    componentName = componentName || "ANONYMOUS";
    propFullName = propFullName || propName;

    if (props[propName] == null) {
      if (isRequired) {
        const baseError = `The ${location} \`${propFullName}\` is marked as required in \`${componentName}\`, but its value is`;

        const errValue = props[propName] === null ? "`null`" : "`undefined`";

        const message = `${baseError} ${errValue}.`;

        return new CustomPropTypeError(message);
      }

      return null;
    } else {
      return validate(props, propName, componentName, location, propFullName, secret);
    }
  }

  const chainedCheckType = checkType.bind(null, false);

  chainedCheckType.isRequired = checkType.bind(null, true);

  return chainedCheckType;
}

export function tuple(...types) {
  return createChainableTypeChecker(function(props, propName, componentName, location, propFullName, secret) {
    const value = props[propName];

    if (!location) {
      location = 'prop';
    }

    if (!propFullName) {
      propFullName = propName;
    }

    if (!Array.isArray(value)) {
      return new CustomPropTypeError(`Invalid ${location} \`${propFullName}\` supplied to \`${componentName}\`, expected ${types.length}-element array`);
    }

    if (value.length !== types.length) {
      return new CustomPropTypeError(`Invalid ${location} \`${propFullName}\` supplied to \`${componentName}\`, expected ${types.length}-element array, got array of length ${value.length}`);
    }

    for (let i = 0; i < value.length; ++i) {
      const error = types[i](value, i, componentName, 'element', `${propFullName}[${i}]`, secret);

      if (error) {
        return error;
      }
    }

    return null;
  });
}
