import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { get, identity } from "lodash";
import { compose, defaultProps, setDisplayName, withHandlers, withPropsOnChange } from "recompose";
import { inspect } from "util";

import NamedLink from "./NamedLink";

export class BaseImplementation extends PureComponent {
  static displayName = "Routing.TableCellLink.Implementation";
  static propTypes = {
    children: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    alterParams: PropTypes.func.isRequired,
    original: PropTypes.object.isRequired,
    params: PropTypes.object,
  };

  render() {
    const { children, name, params } = this.props;

    return <NamedLink name={name} params={params}>{children()}</NamedLink>;
  }
}

export const Implementation = compose(
  defaultProps({
    alterParams: identity,
  }),
  withPropsOnChange(
    ["alterParams", "original"],
    ({ alterParams, original }) => ({ params: alterParams(original) })
  ),
  withHandlers({
    children: (props) => () => {
      const { label, original } = props;

      if (typeof label === "function") {
        return label(original, props);
      } else if (typeof label === "string") {
        return get(original, label, label);
      } else {
        return `Unknown Label: ${inspect(label)}`;
      }
    }
  })
)(BaseImplementation);

export default class TableCellLink extends PureComponent {
  static displayName = "Routing.TableCellLink";

  static propTypes = {
    name: PropTypes.string.isRequired,
    alterParams: PropTypes.func.isRequired,
    label: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string
    ]).isRequired,
    original: PropTypes.object.isRequired,
  };

  static defaultProps = {
    alterParams: identity
  };

  render() {
    return <Implementation {...this.props} />;
  }

  static generateFor(name, { alterParams = identity, label = inspect }) {
    return compose(
      setDisplayName(`Routing.TableCellLink[${name}]`),
      defaultProps({ alterParams, label, name })
    )(TableCellLink);
  }
}
