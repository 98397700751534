import React, { Component } from "react";
import ProductFilter from "./ProductFilter";
import CompanyFilter from "./CompanyFilter";
import DateFilter from "./DateFilter";
import styles from "./GlobalSearch.module.scss";

export default class Container extends Component {
  static displayName = "GlobalSearch.Container";

  render() {
    return (
      <div className={styles.container}>
        <CompanyFilter />
        <ProductFilter />
        <DateFilter />
      </div>
    );
  }
}
