import User32 from "./32/User.js";
import Behavior32 from "./32/Behavior.js";
import Close32 from "./32/Close.js";
import Performance32 from "./32/Performance.js";
import Menu32 from "./32/Menu.js";
import Dashboard32 from "./32/Dashboard.js";
import Search32 from "./32/Search.js";
import Audience32 from "./32/Audience.js";
import ArrowLong16 from "./16/ArrowLong.js";
import User24 from "./24/User.js";
import DownloadTray24 from "./24/DownloadTray.js";
import Dashboard24 from "./24/Dashboard.js";
import Filter24 from "./24/Filter.js";
import Fire22 from "./22/Fire.js";
import Filter122 from "./22/Filter1.js";
import Medal122 from "./22/Medal1.js";
import Binoculars22 from "./22/Binoculars.js";
import ChartBar222 from "./22/ChartBar2.js";
import Flag22 from "./22/Flag.js";
import Arrow3Down22 from "./22/arrow-3/Down.js";
import Arrow3Up22 from "./22/arrow-3/Up.js";
import Right22 from "./22/arrow-3/Right.js";
import Left22 from "./22/arrow-3/Left.js";
import Filled22 from "./22/user/Filled.js";
import Arrow2Down22 from "./22/arrow-2/Down.js";
import Arrow2Up22 from "./22/arrow-2/Up.js";
import Home22 from "./22/Home.js";
import Close22 from "./22/Close.js";
import ChartArrowUp22 from "./22/ChartArrowUp.js";
import ChartArrowDown22 from "./22/ChartArrowDown.js";
import Clipboard22 from "./22/Clipboard.js";
import Laboratory22 from "./22/Laboratory.js";
import ChartBar122 from "./22/ChartBar1.js";
import Cursor22 from "./22/Cursor.js";
import Briefcase22 from "./22/Briefcase.js";
import Path22 from "./22/Path.js";
import ChartPie22 from "./22/ChartPie.js";
import Search22 from "./22/Search.js";
import Lightning22 from "./22/Lightning.js";
import Document22 from "./22/Document.js";
import Filter222 from "./22/Filter2.js";
import Arrow1Down22 from "./22/arrow-1/Down.js";
import Arrow1Up22 from "./22/arrow-1/Up.js";
import Medal222 from "./22/Medal2.js";
import LogoSCUnique from "./unique/LogoSC";
import LogoSiemensUnique from "./unique/LogoSiemens";

export default {
  User32,
  Behavior32,
  Close32,
  Performance32,
  Menu32,
  Dashboard32,
  Search32,
  Audience32,
  ArrowLong16,
  User24,
  DownloadTray24,
  Dashboard24,
  Filter24,
  Fire22,
  Filter122,
  Medal122,
  Binoculars22,
  ChartBar222,
  Flag22,
  Arrow3Down22,
  Arrow3Up22,
  Right22,
  Left22,
  Filled22,
  Arrow2Down22,
  Arrow2Up22,
  Home22,
  Close22,
  ChartArrowUp22,
  ChartArrowDown22,
  Clipboard22,
  Laboratory22,
  ChartBar122,
  Cursor22,
  Briefcase22,
  Path22,
  ChartPie22,
  Search22,
  Lightning22,
  Document22,
  Filter222,
  Arrow1Down22,
  Arrow1Up22,
  Medal222,
  LogoSCUnique,
  LogoSiemensUnique
};
