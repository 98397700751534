import React, { PureComponent } from "react";
import { Route, Switch } from "react-router-dom";

import { Routes } from "Routes";

import { State as AppState } from "Components/Application";

import {
  Container,
  PageBody,
  SiteHeader,
  SiemensFooter
} from "Components/Layout";

import { Sidebar } from "Components/Navigation";

import { Routes as AudienceRoutes } from "Containers/Audience";
import { Routes as BehaviorRoutes } from "Containers/Behavior";
import { Routes as PerformanceRoutes } from "Containers/Performance";
import { Routes as TopLevelRoutes } from "Containers/TopLevel";

export default class App extends PureComponent {
  static displayName = "App";

  render() {
    return (
      <AppState>
        <Container>
          <SiteHeader />
          <PageBody>
            <Switch>
              <Route
                path={Routes.pathFor("audience")}
                component={AudienceRoutes}
              />
              <Route
                path={Routes.pathFor("behavior")}
                component={BehaviorRoutes}
              />
              <Route
                path={Routes.pathFor("performance")}
                component={PerformanceRoutes}
              />
              <Route path="/" component={TopLevelRoutes} />
            </Switch>
          </PageBody>
          <Sidebar />
          <SiemensFooter visibility="mobile" />
        </Container>
      </AppState>
    );
  }
}
