import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { compose, withPropsOnChange } from "recompose";
import { FilterWrapper } from "Components/Utility";
import PresetList from "./PresetList";
import RangeInputs from "./RangeInputs";
import { DateRangeProp } from "Utilities/Temporal";

export class DateRangeFilter extends PureComponent {
  static displayName = "Utility.DateRangeFilter";

  static propTypes = {
    dateRange: DateRangeProp.isRequired,
    updateDateRange: PropTypes.func.isRequired
  };

  static defaultProps = {
    updateDateRange: noop
  };

  render() {
    const { dateRange, updateDateRange, displayText } = this.props;

    return (
      <FilterWrapper
        displayText={displayText}
        themes={["with-sidebar"]}
        scrollOverflow
      >
        <PresetList
          updateDateRange={updateDateRange}
          activeDateRange={dateRange}
        />
        <RangeInputs dateRange={dateRange} updateDateRange={updateDateRange} />
      </FilterWrapper>
    );
  }
}

const withDisplayText = withPropsOnChange(["dateRange"], ({ dateRange }) => ({
  displayText:
    dateRange && dateRange.isValid()
      ? dateRange.getDisplayText()
      : "Filter By Date"
}));

export default compose(withDisplayText)(DateRangeFilter);
