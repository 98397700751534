import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import classNames from "classnames";
import { IconComposer } from "Components/Utility";

import { compose, defaultProps, withHandlers } from "recompose";

import { get, matchesProperty } from "lodash";

import hideIfEmpty from "HOC/hideIfEmpty";

import { optionsShape } from "./utilities";

export class Selector extends PureComponent {
  static displayName = "Utility.SelectFilter.Selector";

  static propTypes = {
    isClearable: PropTypes.bool.isRequired,
    isSearchable: PropTypes.bool.isRequired,
    options: optionsShape,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    selectedValue: PropTypes.string,
    themes: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    isClearable: true,
    isSearchable: true
  };

  get baseClass() {
    return "m-filter-select";
  }

  get themes() {
    return this.props.themes;
  }

  get isCardTheme() {
    return this.themes && this.themes.includes("card");
  }

  get isWithSearchTheme() {
    return this.themes && this.themes.includes("with-search");
  }

  get containerClasses() {
    return classNames({
      [`${this.baseClass}-container`]: true,
      [`${this.baseClass}-container--default`]: !this.isCardTheme && !this.isWithSearchTheme,
      [`${this.baseClass}-container--card`]: this.isCardTheme,
      [`${this.baseClass}-container--with-search`]: this.isWithSearchTheme
    })
  }

  get dropdownIndicatorClasses() {
    return classNames({
      [`${this.baseClass}__icon`]: true,
      [`${this.baseClass}__icon--with-search`]: this.isWithSearchTheme
    })
  }

  get placeholder() {
    const { placeholder, categoryLabel } = this.props;
    if (placeholder) return placeholder;
    return `${categoryLabel} name...`;
  }

  render() {
    const { isClearable, isSearchable, onChange, options, value, isLoading } = this.props;

    const ClearIndicator = props => {
      return isClearable ? (
        <components.ClearIndicator {...props}>
          <IconComposer
            icon="Close22"
            iconClass={`${this.baseClass}__icon`}
            size="default"
          />
        </components.ClearIndicator>
      ) : null;
    };

    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <IconComposer
            icon="Arrow1Down22"
            iconClass={this.dropdownIndicatorClasses}
            size="default"
          />
        </components.DropdownIndicator>
      );
    };

    return (
      <Select
        isClearable={isClearable}
        isSearchable={isSearchable}
        onChange={onChange}
        options={options}
        placeholder={this.placeholder}
        value={value}
        className={this.containerClasses}
        classNamePrefix={this.baseClass}
        components={{ ClearIndicator, DropdownIndicator }}
        isDisabled={isLoading}
      />
    );
  }
}

const withDefaultChangeHandler = defaultProps({
  onChange: option => {
    console.info("Utility.SelectFilter.Selector#handleOptionSelect");
    console.dir(option);
  }
});

const isClearAction = matchesProperty("action", "clear");

const withWrappedOnChange = withHandlers({
  onChange: ({ onChange }) => (option, context) => {
    if (isClearAction(context)) {
      onChange(null, context);
    } else {
      onChange(get(option, "value", null), context);
    }
  }
});

export default compose(
  hideIfEmpty("options"),
  withDefaultChangeHandler,
  withWrappedOnChange
)(Selector);
