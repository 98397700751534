import Auth from "./Auth";

export { default as Auth } from "./Auth";
export { default as Context, CONTEXT_STATE_KEYS } from "./Context";

const authService = new Auth();

/*if (process.env.NODE_ENV === "development") {*/
  window.authService = authService;
//}

export default authService;
