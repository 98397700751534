import React from "react";

import { noop } from "lodash";

const NamedRouteContext = React.createContext({
  namedRoutes: null,
  clearCurrentRoute: noop,
  currentRoute: null,
  isWithinRoute: noop,
  routeName: null,
  setCurrentRoute: noop
});

NamedRouteContext.displayName = "NamedRouteContext";

export default NamedRouteContext;
