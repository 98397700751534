import React, { Component, PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { flow, spread, take, toSafeInteger, zip } from "lodash";
import { at, entries, map, reverse, sortBy } from "lodash/fp";
import { compose, withPropsOnChange } from "recompose";

import { maybeFormatNumber } from "Utilities";

import defaultOptions from "./defaultOptions";

import styles from "./Legend.module.scss";

const { default: { colors: COLORS } } = defaultOptions;

const COLOR_COUNT = COLORS.length;

const getColorForIndex = flow(
  toSafeInteger,
  (value) => COLORS[value % COLOR_COUNT]
);

const chartLegendItemProps = {
  color: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
}

const chartLegendDatumShape = PropTypes.shape(chartLegendItemProps);

const chartLegendDataShape = PropTypes.arrayOf(chartLegendDatumShape);

export class Item extends PureComponent {
  static displayName = "Chart.Legend.Item";

  static propTypes = chartLegendItemProps;

  render() {
    const { color, label, value } = this.props;

    return (
      <tr className={styles.item}>
        <td
          className={styles.value}
          style={{ color }}
        >
          {maybeFormatNumber(value)}
        </td>
        <td className={styles.label}>{label}</td>
      </tr>
    );
  }
}

const renderItems = map((datum) => <Item key={datum.index} {...datum} />);

export class ChartLegend extends Component {
  static displayName = "Chart.Legend";

  static propTypes = {
    data: chartLegendDataShape.isRequired,
    limit: PropTypes.number.isRequired,
    size: PropTypes.string
  };

  static defaultProps = {
    limit: 6
  };

  get maxDataRows() {
    return 5;
  }

  get halfSizeCard() {
    return this.props.size && this.props.size === "half";
  }

  get useColumns() {
    return this.props.data.length > this.maxDataRows;
  }

  get containerClasses() {
    return classNames(styles.container, {
      [styles.columnsHalf]: this.useColumns && this.halfSizeCard,
      [styles.columnsFull]: this.useColumns && !this.halfSizeCard
    });
  }

  render() {
    const { data, limit } = this.props;

    return (
      <div className={this.containerClasses}>
        <table className={styles.table}>
          <tbody>
            {renderItems(take(data, limit))}
          </tbody>
        </table>
      </div>
    );
  }
}

const rebuildDataForLegend = flow(
  at(["options.labels", "series"]),
  spread(zip),
  entries,
  map(function([originalIndex, [label, value]]) {
    return { label, value, originalIndex };
  }),
  sortBy("value"),
  reverse,
  entries,
  map(function([index, datum]) {
    datum.index = index;

    datum.color = getColorForIndex(datum.originalIndex);

    return datum;
  })
);

export default compose(
  withPropsOnChange(
    ["options", "series"],
    (props) => ({ data: rebuildDataForLegend(props) })
  ),
)(ChartLegend);
