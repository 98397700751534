import {
  cond,
  constant,
  every,
  flow,
  get,
  has,
  identity,
  isArray,
  isArrayLikeObject,
  isBoolean,
  isDate,
  isEmpty,
  isFunction,
  isNil,
  isNumber,
  isPlainObject,
  isSafeInteger,
  isString,
  method,
  negate,
  overEvery,
  overSome,
  partial,
  property,
  size,
  some,
  stubTrue,
  toNumber,
  toString
} from "lodash";

import { inspect } from "util";

const PH = partial.placeholder;

export { default as moment } from "./Temporal/moment";

export const toLocaleString = method("toLocaleString", "en-US");
export const toPercentage = method("toLocaleString", "en-US", { style: "percent", minimumFractionDigits: 2 });

export const isFloat = (n) => isNumber(n) && n !== (n|0);

export const formatNumber = flow(
  toNumber,
  cond([
    [isFloat, toPercentage],
    [isSafeInteger, toLocaleString],
    [stubTrue, constant("NaN")],
  ]),
);

export const looksNumeric = cond([
  [isNumber, stubTrue],
  [isString, flow(method("match", /^-?\d+(?:.\d+)?$/), Boolean)],
  [stubTrue, isSafeInteger]
]);

export const maybeFormatNumber = cond([
  [looksNumeric, formatNumber],
  [stubTrue, toString]
]);

export const hasFunction = (object, functionPath) =>
  has(object, functionPath) && isFunction(get(object, functionPath));

export const hasMethod = partial(hasFunction, PH);

export const isFalsey = value => !Boolean(value);

/**
 * @return {boolean}
 */
export const isNotEmpty = negate(isEmpty);

/**
 * @return {boolean}
 */
export const isNotNil = negate(isNil);

/**
 * @return {boolean}
 */
export const canBeEmpty = overSome(isArray, isArrayLikeObject, isPlainObject);

/**
 * @return {boolean}
 */
export const isPresentArray = overEvery(isArray, isNotEmpty);

/**
 * @return {boolean}
 */
export const isPresentString = overEvery(isString, s => /\S+/.test(s));

/**
 * @return {boolean}
 */
export const isPresent = cond([
  [
    hasMethod("isPresent"),
    flow(
      method("isPresent"),
      Boolean
    )
  ],
  [isString, isPresentString],
  [canBeEmpty, isNotEmpty],
  [isDate, stubTrue],
  [isBoolean, identity],
  [isNumber, isSafeInteger],
  [stubTrue, isNotNil]
]);

export const isTruthy = value => Boolean(value);

/**
 * @return {boolean}
 */
export const isTupleOfLength = length =>
  overEvery(isArray, tuple => size(tuple) === length);

/**
 * @return {boolean}
 */
export const areTuplesOfLength = length =>
  overEvery(isArray, partial(every, PH, isTupleOfLength(length)));

/**
 * @return {boolean}
 */
export const none = negate(some);

/**
 * @return {function[]}
 */
export function otherwiseFail({ funcName = "cond" } = {}) {
  const failWith = (...args) => {
    throw new Error(`Cannot process ${funcName} ${inspect(args)}`);
  };

  return [stubTrue, failWith];
}

/**
 * @return {boolean}
 */
export const propertyIsEmpty = propertyName =>
  flow(
    property(propertyName),
    isEmpty
  );

/**
 * @return {boolean}
 */
export const propertyIsPresent = propertyName =>
  flow(
    property(propertyName),
    isPresent
  );
