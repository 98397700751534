import { LineGraph } from "Components/Data";

import { graphqlActivitySummaries, gql } from "HOC/graphql";

export default LineGraph.extendAs(
  "Performance.Cases.DeflectionRateGraph",
  {
    size: "full",
    title: "Deflection Rate",
  },
  graphqlActivitySummaries(
    "withDeflectionRate",
    gql`
    query withDeflectionRate($filters: Filters) {
      supportCases {
        deflections(filters: $filters) {
          csvTag

          rows {
            id
            label
            d1Rate
            d2Rate
            d3Rate
          }
        }
      }
    }
    `,
    {
      wrangle: {
        path: "supportCases.deflections",
        seriesDefinitions: [
          { field: "d1Rate", label: "Clicked / Searched", percentage: true, },
          { field: "d2Rate", label: "Did Not Submit / Clicked", percentage: true, yaxis: { opposite: true } },
          /*{ field: "d3Rate", label: "D3 Rate", percentage: true, yaxis: { opposite: true } },*/
        ],
        xAxisField: "label",
      }
    }
  )
);
