import React, { Component } from "react";

import { NamedRoute } from "Components/Routing";

import AudienceRoot from "./Root";

export default class AudienceRoutes extends Component {
  static displayName = "Audience.Routes";

  render() {
    return (
      <React.Fragment>
        <NamedRoute authenticated name="audience" component={AudienceRoot} />
      </React.Fragment>
    );
  }
}
