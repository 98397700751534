import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { branch, compose, lifecycle, renderComponent } from "recompose";
import { withRouter } from "react-router";
import { matchesProperty } from "lodash";
import { has } from "lodash/fp";

import { Card } from "Components/Layout";
import Loader from "Components/Utility/Loader";
import named from "HOC/named";
import withAuth from "HOC/withAuth";

import styles from "./Callback.module.scss";

const isAuthenticating = matchesProperty("isAuthenticating", true);
const hasCode = has("code");

export class Callback extends PureComponent {
  static displayName = "Authentication.Callback";

  static propTypes = {
    hasError: PropTypes.bool.isRequired,
    error: PropTypes.instanceOf(Error),
    errorMessage: PropTypes.string,
  };

  static defaultProps = {
    hasError: false
  };

  renderTitle() {
    const { hasError } = this.props;

    return hasError ? "Problem Authenticating" : "Authenticating"
  }

  renderBody() {
    const { hasError, error, errorMessage } = this.props;

    if (hasError) {
      console.warn("AUTH CALLBACK ERROR");
      console.dir(error);

      return <React.Fragment>
        <h1>There was an issue with the authentication callback</h1>
        <br />
        <p>Error message: <code>{errorMessage}</code></p>
        {hasCode(error) && <p>Error code: <code>{error.code}</code></p>}
      </React.Fragment>
    }

    return <p>Loading user profile...</p>;
  }

  render() {
    return (
      <Card size="full" title={this.renderTitle()}>
        <div className={styles.wrapper}>{this.renderBody()}</div>
      </Card>
    );
  }
}

export default compose(
  named("Authentication.Callback.HOC"),
  withAuth,
  withRouter,
  lifecycle({
    async componentDidMount() {
      const { authState, history } = this.props;

      try {
        await authState.handleAuthentication();

        history.push(authState.restoreLocation());
      } catch(error) {
        this.setState({
          hasError: true,
          error,
          errorMessage: error.message,
        });
      }
    }
  }),
  branch(isAuthenticating, renderComponent(Loader))
)(Callback);
