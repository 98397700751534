import {
  at,
  conforms,
  defaultTo,
  dropWhile,
  flow,
  get,
  max,
  merge,
  overEvery,
  set,
  unzip,
  zip
} from "lodash";

import { clamp, every, map, some } from "lodash/fp";

import { isNotEmpty, isPresentArray, isPresentString, toPercentage } from "Utilities";

export { buildAutoQuery } from "./ast";

export const isSeriesDefinition = conforms({
  field: isPresentString,
  label: isPresentString
});

export const isListOfSeriesDefinitions = overEvery(
  isPresentArray,
  every(isSeriesDefinition)
);

export const skipEmptyRows = rows =>
  dropWhile(
    rows,
    ([_, activitiesCount, usersCount]) =>
      activitiesCount === 0 || usersCount === 0
  );

const getFieldsAndLabels = flow(
  map(o => at(o, "field", "label")),
  unzip
);

const yaxisDefined = conforms({
  yaxis: isNotEmpty
});

const yaxisDefinedOnAny = some(yaxisDefined);

function adjustMaxPercentage(n) {
  const padded = (n + 0.03) * 100.0 + Number.EPSILON;

  const mult5 = Math.ceil(padded / 5 ) * 5;

  return mult5 / 100.0;
}

const calculatePercentageMax = flow(
  max,
  adjustMaxPercentage,
  clamp(0, 1)
);

export function wrangleIntoSeries(
  prefix,
  { seriesDefinitions = [], path, xAxisField, ...otherOptions } = {}
) {
  if (!path) {
    throw new Error("Must provide a path");
  }

  if (!xAxisField) {
    throw new Error("Must provide an xAxisField");
  }

  if (!isListOfSeriesDefinitions(seriesDefinitions)) {
    if (Array.isArray(seriesDefinitions) && seriesDefinitions.length) {
      console.warn("Invalid series definitions:");

      for (const seriesDefinition of seriesDefinitions) {
        if (!isSeriesDefinition(seriesDefinition)) {
          console.dir(seriesDefinition);
        }
      }

      throw new Error("Invalid series definitions");
    } else {
      throw new Error("Must provide an array of series definitions");
    }
  }

  const [fields, labels] = getFieldsAndLabels(seriesDefinitions);

  const extractRow = row => at(row, xAxisField, ...fields);

  const extractRows = flow(
    map(extractRow),
    unzip
  );

  const buildSeries = flow(
    fieldValues => zip(labels, fieldValues),
    map(([name, data]) => ({ name, data }))
  );

  const baseChartProps = {
    options: {
      chart: { id: prefix }
    }
  };

  let hasPercentage = [];

  if (yaxisDefinedOnAny(seriesDefinitions)) {
    const yaxis = seriesDefinitions.map(({ label: seriesName, percentage = false, yaxis = {}, yAxisTitle }, index) => {
      const base = {
        seriesName,
        axisTicks: { show: true },
        title: { text: defaultTo(yAxisTitle, seriesName) },
      };

      if (percentage) {
        // We need to know about this later.
        hasPercentage.push(index);
      }

      const percentageProps = percentage ? {
        forceNiceScale: false,
        labels: {
          formatter: toPercentage,
        }
      } : {};

      return merge(base, percentageProps, yaxis);
    });

    set(baseChartProps, "options.yaxis", yaxis);
  }

  return function wrangleIntoChartProps(props = {}) {
    const data = get(props, prefix, null);

    if (!data) {
      return undefined;
    }

    const isLoading = get(data, "loading", false);

    const connection = get(data, path);

    const csvTag = get(connection, "csvTag", null);

    const summaries = get(connection, "rows", []);

    const [categories, ...fieldValues] = extractRows(summaries);

    const series = buildSeries(fieldValues);

    const chartProps = merge({}, baseChartProps, {
      options: {
        labels: categories,
        xaxis: {
          categories
        }
      },
      series
    });

    for (const index of hasPercentage) {
      const data = get(series, [index, "data"], []);

      const percentageMax = calculatePercentageMax(data);

      set(chartProps, ["options", "yaxis", index, "max"], percentageMax);
    }

    return { csvTag, isLoading, chartProps };
  };
}
