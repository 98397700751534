import gql from "graphql-tag";

export default gql`
  query getLocalFilters @client {
    dateRangeChartScale

    localFilters {
      __typename
      articleKey
      articleNeedle
      chartScale
      company
      includeEmployees
      language
      productId
      startTime
      untilTime
    }
  }
`;
