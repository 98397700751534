import React, { Component } from "react";
import PropTypes from "prop-types";
import { Chart } from "react-google-charts";
import { compose, defaultProps, withPropsOnChange } from "recompose";
import { get, times } from "lodash";

import { getMapsAPIKey } from "Utilities/config";
import { tuple } from "Utilities/custom-prop-types";

import styles from "./GeoChart.module.scss";

export const countryDatum1ValueShape = tuple(
  PropTypes.string.isRequired,
  PropTypes.number.isRequired,
);

export const countryDatum2ValueShape = tuple(
  PropTypes.string.isRequired,
  PropTypes.number.isRequired,
  PropTypes.number.isRequired,
);

export const countryDatumShape = PropTypes.oneOfType([
  countryDatum2ValueShape,
  countryDatum1ValueShape
]);

export const countryDataShape = PropTypes.arrayOf(countryDatumShape);

export const labelsShape = PropTypes.arrayOf(PropTypes.string);

export class GeoChart extends Component {
  static displayName = "Chart.GeoChart";

  static propTypes = {
    countryLabel: PropTypes.string.isRequired,
    data: countryDataShape.isRequired,
    labels: labelsShape.isRequired,
    valueLabels: labelsShape.isRequired,
    wrappedData: PropTypes.arrayOf(
      PropTypes.oneOfType([
        labelsShape,
        countryDatumShape
      ])
    ).isRequired
  };

  get colors() {
    return ["#E8fBF0", "#07E065"];
  }

  render() {
    const { wrappedData } = this.props;

    return (
      <div className={styles.wrapper}>
        <Chart
          width="100%"
          height="auto"
          chartType="GeoChart"
          data={wrappedData}
          mapsApiKey={getMapsAPIKey()}
          options={{
            colorAxis: {
              colors: this.colors
            },
            datalessRegionColor: "#FFF",
            defaultColor: "#D7D7D7",
            legend: "none",
            tooltip: {
              textStyle: {
                color: "#777",
                fontName: "Avenir Next"
              }
            }
          }}
        />
      </div>
    );
  }
}

const withDefaultLabels = defaultProps({
  countryLabel: "Countries",
  valueLabels: [
    "Activities Count",
    "Unique Users Count"
  ]
});

const withWrappedData = withPropsOnChange(
  ["countryLabel", "data", "valueLabels"],
  ({ countryLabel, data, valueLabels }) => {
    const tupleLength = get(data, "[0].length", 2);

    const labels = times(tupleLength, function getLabel(n) {
      if (n === 0) {
        return countryLabel;
      }

      return get(valueLabels, [n-1], `Series ${n}`);
    });

    const wrappedData = [labels, ...data];

    return { labels, wrappedData };
  }
)

export default compose(
  withDefaultLabels,
  withWrappedData
)(GeoChart);
