const PropTypes = require("prop-types");

export const barChartOptionsShape = PropTypes.shape({
  chart: PropTypes.shape({
    id: PropTypes.string
  }),
  xaxis: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.any)
  })
});

export const chartOptionsShape = PropTypes.shape({
  chart: PropTypes.shape({
    id: PropTypes.string
  }),
  xaxis: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.any)
  })
});

export const chartSeriesShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.number)
});

export const chartPropsShape = PropTypes.shape({
  options: chartOptionsShape,
  series: PropTypes.arrayOf(chartSeriesShape)
});

export const barChartPropsShape = PropTypes.shape({
  options: barChartOptionsShape,
  series: PropTypes.arrayOf(chartSeriesShape),
});

export const pieChartPropsShape = PropTypes.shape({
  series: PropTypes.arrayOf(PropTypes.number)
});

export const tablePropsShape = PropTypes.shape({
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object)
});
