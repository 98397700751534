import { both, collection, member, routes } from "routes-generator";

import RouteMap from "./RouteMap";

export const Router = routes({
  audience: collection(),
  behavior: collection({
    content: collection(),
    "site-usage": collection(),
    searches: collection()
  }),
  callback: collection(),
  finish_signing_out: collection(),
  performance: collection({
    cases: collection(),
    articles: both(collection(), member()),
    satisfaction: collection()
  })
});

const Routes = new RouteMap(Router);

Routes.define("home", "/");
Routes.define("audience", "/audience");
Routes.define("auth.finishSigningOut", "/finish_signing_out");
Routes.define("auth.callback", "/callback");
Routes.define("behavior", "/behavior");
Routes.define("behavior.content", "/behavior/content");
Routes.define("behavior.siteUsage", "/behavior/site-usage");
Routes.define("behavior.searches", "/behavior/searches");
Routes.define("performance", "/performance");
Routes.define("performance.cases", "/performance/cases");
Routes.define("performance.articles", "/performance/articles");
Routes.define(
  "performance.articles.byArticleId",
  "/performance/articles/:articleId"
);
Routes.define("performance.satisfaction", "/performance/satisfaction");

if (process.env.NODE_ENV === "development") {
  window.ROUTER = Router;
  window.ROUTE_MAP = Routes;
}

export default Routes;
