const { flatten, isMatch } = require("lodash");
const { pick } = require("lodash/fp");

export default function makePropsChanged(...propNames) {
  const extractProps = pick(flatten(propNames));

  return function didPropsChange(currentProps, previousProps) {
    const curr = extractProps(currentProps);
    const prev = extractProps(previousProps);

    return !isMatch(curr, prev);
  };
}

export function makePropsAreSame(...propNames) {
  const extractProps = pick(flatten(propNames));

  return function didPropsChange(currentProps, previousProps) {
    const curr = extractProps(currentProps);
    const prev = extractProps(previousProps);

    return isMatch(curr, prev);
  };
}
