import { createContext } from "react";
import { noop } from "lodash";

export const CONTEXT_STATE_KEYS = [
  "authenticatedUser",
  "isAuthenticated",
  "isAuthenticating",
  "refreshAuth"
];

const AuthContext = createContext({
  authenticatedUser: null,
  isAuthenticated: false,
  isAuthenticating: false,
  refreshAuth: noop
});

AuthContext.displayName = "AuthContext";

export default AuthContext;
