import React, { Component } from "react";
import PropTypes from "prop-types";

import { constant, pick } from "lodash";
import {
  compose,
  withHandlers,
  withPropsOnChange,
  withStateHandlers
} from "recompose";

import named from "HOC/named";
import { NamedRouteContext, Route, RouteMap, Routes } from "Routes";

export class RoutingProvider extends Component {
  static displayName = "Routing.NamedRouteProvider";

  static propTypes = {
    children: PropTypes.node,
    namedRouteState: PropTypes.shape({
      clearCurrentRoute: PropTypes.func.isRequired,
      currentRoute: PropTypes.instanceOf(Route),
      isWithinRoute: PropTypes.func.isRequired,
      namedRoutes: PropTypes.instanceOf(RouteMap).isRequired,
      routeName: PropTypes.string,
      setCurrentRoute: PropTypes.func.isRequired
    }).isRequired
  };

  render() {
    const { children, namedRouteState } = this.props;

    return (
      <NamedRouteContext.Provider value={namedRouteState}>
        {children}
      </NamedRouteContext.Provider>
    );
  }
}

const getInitialNamedRouteState = () => ({
  namedRoutes: Routes,
  currentRoute: null
});

const namedRouteStateDefinition = withStateHandlers(getInitialNamedRouteState, {
  clearCurrentRoute: constant(
    constant({ currentRoute: null, routeName: null })
  ),
  setCurrentRoute: ({ namedRoutes }) => routeName => ({
    currentRoute: namedRoutes.get(routeName),
    routeName
  })
});

const namedRouteStateHandlers = withHandlers({
  isWithinRoute: ({ currentRoute }) => targetName =>
    currentRoute instanceof Route ? currentRoute.contains(targetName) : false
});

const HANDLED_STATE_PROPS = [
  "currentRoute",
  "clearCurrentRoute",
  "isWithinRoute",
  "namedRoutes",
  "routeName",
  "setCurrentRoute"
];

const namedRouteStateMapping = withPropsOnChange(
  HANDLED_STATE_PROPS,
  (props = {}) => ({ namedRouteState: pick(props, ...HANDLED_STATE_PROPS) })
);

export default compose(
  named("Routing.Provider.HOC"),
  namedRouteStateDefinition,
  namedRouteStateHandlers,
  namedRouteStateMapping
)(RoutingProvider);
