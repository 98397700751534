import { branch, compose, createSink, renderComponent } from "recompose";
import { withRouter } from "react-router-dom";

import { method, negate } from "lodash";

import named from "./named";
import withAuth from "./withAuth";

const bounceToSignIn = createSink(
  ({
    authState: { signIn, storeLocation },
    location: { pathname },
    ...otherProps
  }) => {
    storeLocation(pathname);

    signIn();
  }
);

const checkIfUnauthenticated = negate(method("authState.isAuthenticated"));

const authenticationBranch = compose(
  withRouter,
  renderComponent(bounceToSignIn)
);

const enhance = compose(
  named("requireAuthenticatedUser"),
  withAuth,
  branch(checkIfUnauthenticated, authenticationBranch)
);

export default function requireAuthenticatedUser(Component) {
  return enhance(Component);
}
