import { nest, setDisplayName } from "recompose";

import { BrowserRouter as Router } from "react-router-dom";
import AuthenticationProvider from "Components/Authentication/Provider";
import { NamedRouteProvider } from "Components/Routing";
import { Provider as LocalFiltersProvider } from "LocalFilters";
import { ApolloProvider } from "Apollo";

import GlobalBootstrap from "./GlobalBootstrap";

export default setDisplayName("Application.State")(
  nest(
    AuthenticationProvider,
    ApolloProvider,
    LocalFiltersProvider,
    GlobalBootstrap,
    NamedRouteProvider,
    Router
  )
);
