import { graphql } from "react-apollo";
import gql from "graphql-tag";

import { getInitialDateRange, toDateRange } from "Utilities/Temporal";

const getDateRange = gql`
  query getDateRange @client {
    localFilters @client {
      startTime
      untilTime
    }
  }
`;

function getCurrentDateRangeFrom({ data: { localFilters } }) {
  if (localFilters) {
    const dateRange = toDateRange(localFilters);

    if (dateRange && dateRange.isValid()) {
      return dateRange;
    }
  }

  return getInitialDateRange();
}

export default graphql(getDateRange, {
  options: props => ({
    fetchPolicy: "cache-only"
  }),
  props: props => ({
    currentDateRange: getCurrentDateRangeFrom(props)
  })
});
