import React, { Component } from "react";

import { NamedRoute } from "Components/Routing";

import Content from "./Content";
import Root from "./Root";
import Searches from "./Searches";
import SiteUsage from "./SiteUsage";

export default class BehaviorRoutes extends Component {
  static displayName = "Behavior.Routes";

  render() {
    return (
      <React.Fragment>
        <NamedRoute authenticated name="behavior.content" component={Content} />
        <NamedRoute
          authenticated
          name="behavior.siteUsage"
          component={SiteUsage}
        />
        <NamedRoute
          authenticated
          name="behavior.searches"
          component={Searches}
        />
        <NamedRoute authenticated name="behavior" component={Root} />
      </React.Fragment>
    );
  }
}
