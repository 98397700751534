import React, { PureComponent } from "react";

export default class PercentCell extends PureComponent {
  static displayName = "Table.PercentCell";

  render() {
    const { value } = this.props;

    return <span>{parseFloat(value).toLocaleString("en-us", { style: "percent", minimumFractionDigits: 2 })}</span>;
  }
}
