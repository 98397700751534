import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Flag extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 22;
  }

  get defaultWidth() {
    return 22;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 22 22";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            d="M11.4,9 C12.2,9 12.2,9.801 13,9.801 L13,11.401 C12.2,11.401 12.2,10.601 11.4,10.601 L11.4,9.001 L9.8,9.001 L9.8,7.401 L11.4,7.401 L11.4,9 Z M5,17.001 L5,5.001 L6,5.001 L6,17.001 L5,17.001 Z M13,9.801 L13,8.201 L14.6,8.201 L14.6,9.801 L13,9.801 Z M13,5.801 L17,5.801 L17,13.801 L13,13.801 C12.6,13.801 12.4,13.601 12.2,13.401 C12,13.201 11.8,13 11.4,13 L7.4,13 L7.4,5 L11.4,5 C11.8,5 12,5.201 12.2,5.401 C12.4,5.601 12.6,5.801 13,5.801 Z M16.2,9.801 L16.2,8.201 L14.6,8.201 L14.6,6.601 L13,6.601 L13,8.201 C12.6,8.201 12.4,8 12.2,7.801 C12,7.601 11.8,7.401 11.4,7.401 L11.4,5.801 L9.8,5.801 L9.8,7.401 L8.2,7.401 L8.2,9 L9.8,9 L9.8,10.601 L8.2,10.601 L8.2,12.201 L9.8,12.201 L9.8,10.601 L11.4,10.601 L11.4,12.201 C11.8,12.201 12,12.401 12.2,12.601 C12.4,12.801 12.6,13 13,13 L13,11.401 L14.6,11.401 L14.6,13 L16.2,13 L16.2,11.401 L14.6,11.401 L14.6,9.801 L16.2,9.801 Z"
          />
          <path
            fill={this.fill}
            d="M11.4,9 C12.2,9 12.2,9.801 13,9.801 L13,11.401 C12.2,11.401 12.2,10.601 11.4,10.601 L11.4,9.001 L9.8,9.001 L9.8,7.401 L11.4,7.401 L11.4,9 Z M5,17.001 L5,5.001 L6,5.001 L6,17.001 L5,17.001 Z M13,9.801 L13,8.201 L14.6,8.201 L14.6,9.801 L13,9.801 Z M13,5.801 L17,5.801 L17,13.801 L13,13.801 C12.6,13.801 12.4,13.601 12.2,13.401 C12,13.201 11.8,13 11.4,13 L7.4,13 L7.4,5 L11.4,5 C11.8,5 12,5.201 12.2,5.401 C12.4,5.601 12.6,5.801 13,5.801 Z M16.2,9.801 L16.2,8.201 L14.6,8.201 L14.6,6.601 L13,6.601 L13,8.201 C12.6,8.201 12.4,8 12.2,7.801 C12,7.601 11.8,7.401 11.4,7.401 L11.4,5.801 L9.8,5.801 L9.8,7.401 L8.2,7.401 L8.2,9 L9.8,9 L9.8,10.601 L8.2,10.601 L8.2,12.201 L9.8,12.201 L9.8,10.601 L11.4,10.601 L11.4,12.201 C11.8,12.201 12,12.401 12.2,12.601 C12.4,12.801 12.6,13 13,13 L13,11.401 L14.6,11.401 L14.6,13 L16.2,13 L16.2,11.401 L14.6,11.401 L14.6,9.801 L16.2,9.801 Z"
          />
        </g>
      </svg>
    );
  }
}
