import { cond, constant, stubTrue } from "lodash";
import { inRange } from "lodash/fp";

import { tryMoment } from "./moment";

const quarterNumberFromMonth = cond([
  [inRange(0, 3),   constant(2)],
  [inRange(3, 6),   constant(3)],
  [inRange(6, 9),   constant(4)],
  [inRange(9, 11),  constant(1)],
  [stubTrue, constant(null)]
]);

/**
 * @param {Date, moment} date
 * @return {?number}
 */
export function fiscalYear(date) {
  const m = tryMoment(date);

  if (!m) {
    return null;
  }

  const quarterNumber = quarterNumberFromMonth(m.month());

  const year = m.year();

  return quarterNumber === 1 ? year + 1 : year;
}

/**
 * @param {Date, moment} date
 * @return {?string}
 */
export function fiscalQuarterLabel(date) {
  const fyear = fiscalYear(date);
  const fqnum = fiscalQuarterNumber(date);

  if (fyear && fqnum) {
    return `${fyear}Q${fqnum}`;
  }

  return null;
}

/**
 * @param {Date, moment} date
 * @return {?number}
 */
export function fiscalQuarterNumber(date) {
  const m = tryMoment(date);

  if (!m) {
    return null;
  }

  return quarterNumberFromMonth(m.month());
}

if (process.env.NODE_ENV === "development") {
  window.quarterNumberFromMonth = quarterNumberFromMonth;
  window.fiscalYear = fiscalYear;
  window.fiscalQuarterLabel = fiscalQuarterLabel;
  window.fiscalQuarterNumber = fiscalQuarterNumber;
}
