import React, { Component } from "react";
import classNames from "classnames";

import { IconComposer } from "Components/Utility";

import styles from "./Pagination.module.scss";

export default class TablePagination extends Component {
  static displayName = "Table.Pagination";

  constructor(props) {
    super(props);

    this.getSafePage = this.getSafePage.bind(this);
    this.changePage = this.changePage.bind(this);
    this.applyPage = this.applyPage.bind(this);

    this.state = {
      page: props.page
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.page !== nextProps.page) {
      this.setState({ page: nextProps.page });
    }
  }

  getSafePage(page) {
    if (Number.isNaN(page)) {
      page = this.props.page;
    }

    return Math.min(Math.max(page, 0), this.props.pages - 1);
  }

  changePage(page) {
    page = this.getSafePage(page);
    this.setState({ page });
    if (this.props.page !== page) {
      this.props.onPageChange(page);
    }
  }

  applyPage(e) {
    if (e) {
      e.preventDefault();
    }
    const page = this.state.page;
    this.changePage(page === "" ? this.props.page : page);
  }

  renderButton(props) {
    return (
      <button type="button" {...props} className={styles.buttonDefault}>
        {props.children}
      </button>
    );
  }

  renderPageJump() {
    const { pageJumpText } = this.props;
    const { page } = this.state;

    return (
      <div className={styles.inlineEl}>
        <input
          style={{ width: page > 8 ? 70 : 60 }}
          className={classNames(styles.input, styles.inputText)}
          aria-label={pageJumpText}
          type={page === "" ? "text" : "number"}
          onChange={e => {
            const val = e.target.value;
            const page = val - 1;
            if (val === "") {
              return this.setState({ page: val });
            }
            this.setState({ page: this.getSafePage(page) });
          }}
          value={page === "" ? "" : page + 1}
          onBlur={this.applyPage}
          onKeyPress={e => {
            if (e.which === 13 || e.keyCode === 13) {
              this.applyPage();
            }
          }}
        />
      </div>
    );
  }

  renderPageSizeSelect() {
    const {
      pageSize,
      pageSizeOptions,
      onPageSizeChange,
      rowsSelectorText,
      rowsText
    } = this.props;

    return (
      <div className={classNames(styles.buttonPill)}>
        <select
          className={classNames(styles.input, styles.inputSelect)}
          aria-label={rowsSelectorText}
          onChange={e => onPageSizeChange(Number(e.target.value))}
          value={pageSize}
        >
          {pageSizeOptions.map((option, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <option key={i} value={option}>
              {`${option} ${rowsText}`}
            </option>
          ))}
        </select>
        <IconComposer
          icon="Arrow1Down22"
          iconClass={styles.selectIcon}
          size="default"
        />
      </div>
    );
  }

  render() {
    const {
      // Computed
      pages,
      // Props
      page,
      showPageSizeOptions,
      showPageJump,
      canPrevious,
      canNext,
      pageText,
      ofText,
      PreviousComponent = this.renderButton,
      NextComponent = this.renderButton
    } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.centerBlock}>
          <ul className={styles.paginationList}>
            <li className={styles.paginationItem}>
              <PreviousComponent
                onClick={() => {
                  if (!canPrevious) return;
                  this.changePage(page - 1);
                }}
                disabled={!canPrevious}
              >
                {this.props.previousText}
              </PreviousComponent>
            </li>
            <li className={styles.paginationItem}>
              <span className={styles.inlineEl}>{pageText}</span>
              {showPageJump ? this.renderPageJump() : <span>{page + 1}</span>}
              <span className={styles.inlineEl}>{ofText}</span>
              <span className={styles.inlineEl}>{pages || 1}</span>
            </li>
            <li className={styles.paginationItem}>
              <NextComponent
                onClick={() => {
                  if (!canNext) return;
                  this.changePage(page + 1);
                }}
                disabled={!canNext}
              >
                {this.props.nextText}
              </NextComponent>
            </li>
          </ul>
        </div>
        {showPageSizeOptions && (
          <div className={styles.rightBlock}>{this.renderPageSizeSelect()}</div>
        )}
      </div>
    );
  }
}
