import { onError } from "apollo-link-error";

import { matches } from "lodash";

import { inspect } from "util";

import authService from "../Auth";

const matchesStatusCode = (statusCode) => matches({ statusCode });

const isUnauthorized = matchesStatusCode(401);

const isForbidden = matchesStatusCode(403);

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (isUnauthorized(networkError)) {
    console.warn("[Unauthorized]: %s", networkError.message);
    console.error(networkError);

    authService.signOut();
  } else if (isForbidden(networkError)) {
    console.warn("[HTTP 403]: %s", networkError.message);
    console.error(networkError);

    authService.signOut();
  } else if (networkError) {
    console.warn(`[Network Error]: %s`, networkError.message);
    console.error(networkError);
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(err => {
      const { message, locations, path } = err;

      console.warn(
        `[GraphQL Error]: Message %s, Location: %s, Path: %s`,
        message,
        inspect(locations),
        inspect(path)
      );

      console.error(err);
    });
  }
});

export default onErrorLink;
