import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./Container.module.scss";

export default class Container extends Component {
  static displayName = "Layout.Container";

  static propTypes = {
    children: PropTypes.node
  };

  render() {
    const { children } = this.props;

    return <div className={styles.container} children={children} />;
  }
}
