import React, { Component, PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Popover from "react-popover";
import saveAs from "file-saver";
import { debounce, negate, property } from "lodash";
import { compose, branch, renderNothing, withPropsOnChange, withHandlers, withStateHandlers } from "recompose";

import { IconComposer } from "Components/Utility";
import { gql, graphql } from "HOC/graphql";

import styles from "./Footer.module.scss";

const MoreInfoBody = ({ children }) => (
  <div className={styles.info}>
    <p className={styles.infoText}>{children}</p>
  </div>
);

class MoreInfoImplementation extends PureComponent {
  static displayName = "Layout.Card.Footer.MoreInfo";

  static propTypes = {
    close: PropTypes.func.isRequired,
    hasMoreInfo: PropTypes.bool.isRequired,
    info: PropTypes.node,
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired
  };

  render() {
    const { close, info, isOpen, toggle } = this.props;

    return (
      <Popover
        className={styles.popover}
        body={<MoreInfoBody children={info} />}
        isOpen={isOpen}
        onOuterAction={close}
        preferPlace="below"
        enterExitTransitionDurationMs={null}
        tipSize={11}
        offset={29}
      >
        <div className={styles.left}>
          <button className={styles.button} onClick={toggle}>
            <span>More info</span>
            <IconComposer
              icon="Arrow1Down22"
              size="default"
              iconClass={classNames(styles.buttonIconLeft, styles.buttonIconArrow, {
                [styles.isRotated]: isOpen
              })}
            />
          </button>
        </div>
      </Popover>
    );
  }
}

export const MoreInfo = compose(
  branch(negate(property("hasMoreInfo")), renderNothing),
  withStateHandlers(
    () => ({ isOpen: false }),
    {
      open: () => () => ({ isOpen: true }),
      close: () => () => ({ isOpen: false }),
      toggle: ({ isOpen }) => () => ({ isOpen: !isOpen })
    }
  )
)(MoreInfoImplementation);

export class CardFooter extends Component {
  static displayName = "Layout.Card.Footer";

  static propTypes = {
    csvTag: PropTypes.string,
    hasDownload: PropTypes.bool.isRequired,
    hasMoreInfo: PropTypes.bool.isRequired,
    hasNoData: PropTypes.bool,
    info: PropTypes.string,
    isLoading: PropTypes.bool,
    onDownloadClick: PropTypes.func
  };

  static defaultProps = {
    hasDownload: false,
    hasMoreInfo: false,
  };

  get hasMoreInfo() {
    const { info } = this.props;

    return Boolean(info);
  }

  renderDownloadButton() {
    const { hasDownload, onDownloadClick } = this.props;

    if (!hasDownload) {
      return null;
    }

    return (
      <button className={styles.button} onClick={onDownloadClick}>
        <span>Download CSV</span>
        <IconComposer
          icon="DownloadTray24"
          size="default"
          iconClass={styles.buttonIconRight}
        />
      </button>
    );
  }

  render() {
    return (
      <footer className={classNames(styles.container, styles.light)}>
        <div className={styles.inner}>
          <MoreInfo {...this.props} />
          <div className={styles.right}>{this.renderDownloadButton()}</div>
        </div>
      </footer>
    );
  }
}

const withVisibility = withPropsOnChange(
  ["csvTag", "info"],
  ({ csvTag, hasNoData, info, isLoading }) => ({
    hasDownload: (!hasNoData && !isLoading && Boolean(csvTag)),
    hasMoreInfo: Boolean(info)
  })
);

const saveCSVFile = debounce(function(_proxy, { data: { getCSV: { csvString, filename } } }) {
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });

  saveAs(blob, filename, { autoBOM: true });
}, 0)

const withPossibleDownload = branch(
  property("hasDownload"),
  compose(
    graphql(gql`
    mutation getCSVExport($csvTag: CSVTag!) {
      getCSV(tag: $csvTag) {
        csvString
        csvTag
        filename
      }
    }
    `, {
      name: "downloadCSV",
      options: ({ csvTag, hasDownload }) => ({
        skip: !hasDownload,
        update: saveCSVFile,
        variables: { csvTag }
      })
    }),
    withHandlers({
      onDownloadClick: ({ downloadCSV }) => () => downloadCSV()
    })
  )
);

export default compose(
  withVisibility,
  withPossibleDownload,
)(CardFooter);
