import { add, flow, property, toString } from "lodash";
import { reduce } from "lodash/fp";
import barGradient from "Stylesheets/images/bar-chart-gradient.svg";

import { maybeFormatNumber } from "Utilities";

export const formatPieTotal = flow(
  property("globals.seriesTotals"),
  reduce(add, 0),
  maybeFormatNumber
);

export default {
  default: {
    colors: ["#07E065", "#00DCFF", "#E05EFF", "#FFA229", "#6E99FF", "#FF6161"],
    chart: {
      foreColor: "#777777",
      fontFamily: "Avenir Next', Helvetica, Arial, sans-serif",
      toolbar: {
        show: false
      }
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      position: "right",
      floating: true,
      offsetX: -10,
      offsetY: -20,
      markers: {
        width: 10,
        height: 10,
        radius: 2
      }
    },
    grid: {
      borderColor: "#C4C4C4",
      strokeDashArray: 4
    },
    xaxis: {
      type: "category",
      tickPlacement: "between",
      tickAmount: 5,
      labels: {
        formatter: toString,
        hideOverlappingLabels: true,
        trim: true,
        maxHeight: 200,
        style: {
          cssClass: "m-chart__label"
        },
        rotateAlways: true,
      }
    },
    yaxis: {
      floating: false,
      min: 0,
      forceNiceScale: true,
      tickAmount: 5,
      labels: {
        style: {
          cssClass: "m-chart__label"
        },
        offsetY: 1,
        formatter: maybeFormatNumber
      }
    }
  },
  bar: {
    fill: {
      opacity: 1,
      type: "image",
      image: {
        src: [barGradient],
        width: 400,
        height: 220
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      padding: {
        top: 50,
        right: 10,
        left: 5
      }
    },
    xaxis: {
      type: "category",
      tickPlacement: "between",
      tickAmount: 5,
      labels: {
        rotateAlways: true,
        formatter: toString,
        hideOverlappingLabels: true,
        trim: true,
        maxHeight: 200,
        style: {
          cssClass: "m-chart__label"
        }
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "28.57%",
        endingShape: "rounded"
      }
    }
  },
  line: {
    stroke: {
      curve: "smooth",
      width: 3,
      lineCap: "round"
    },
    grid: {
      padding: {
        top: 50,
        right: 20,
        bottom: 20,
        left: 5
      }
    },
    responsive: [
      {
        breakpoint: 700,
        options: {
          legend: {
            position: "top",
            horizontalAlign: "left",
            floating: false,
            offsetX: -20,
            offsetY: 0
          },
          grid: {
            padding: {
              top: 2
            }
          }
        }
      }
    ],
    tooltip: {
      y: {
        formatter: maybeFormatNumber
      }
    },
    xaxis: {
      type: "category",
      tickPlacement: "between",
      tickAmount: 5,
      labels: {
        rotateAlways: true,
        formatter: toString,
        hideOverlappingLabels: true,
        trim: true,
        maxHeight: 200,
        style: {
          cssClass: "m-chart__label"
        }
      }
    },
  },
  pie: {
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        size: 100,
        donut: {
          size: "82%",
          labels: {
            show: true,
            name: {
              fontSize: "14px",
              color: "#555555",
              offsetY: -25
            },
            value: {
              fontSize: "34px",
              color: "#3769AC",
              offsetY: -5,
              formatter: maybeFormatNumber
            },
            total: {
              show: true,
              label: "Total Views",
              formatter: formatPieTotal
            }
          }
        }
      }
    }
  }
};
