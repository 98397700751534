import React, { Component } from "react";
import PropTypes from "prop-types";
import { defaultProps, setDisplayName } from "recompose";

import { cardPropsShape } from "Components/Layout";
import { Table as TableWrapper } from "Components/Table";
import { wrapGraphQL } from "HOC/graphql";

import DataCard from "./DataCard";
import { tablePropsShape } from "./shapes";

export default class Table extends Component {
  static displayName = "Data.Table";

  static propTypes = {
    cardProps: cardPropsShape,
    tableProps: tablePropsShape,
    hasNoData: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
  };

  static defaultProps = {
    hasNoData: false,
    isLoading: false
  };

  render() {
    return <DataCard {...this.props}>{() => (<TableWrapper {...this.props.tableProps} />)}</DataCard>;
  }

  static extendAs(displayName, cardProps = {}, ...hocs) {
    return wrapGraphQL(
      setDisplayName(displayName),
      ...hocs,
      defaultProps({
        cardProps
      })
    )(Table);
  }
}
