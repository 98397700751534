import React, { Component } from "react";
import { get } from "lodash";

import { LineGraph, Table } from "Components/Data";
import { graphql, graphqlActivitySummaries, graphqlAutoActivitySummaries, gql } from "HOC/graphql";

import DeflectionRateGraph from "./DeflectionRateGraph";
import OpenCasesByPeriodGraph from "./OpenCasesByPeriodGraph";

export const D1CountGraph = LineGraph.extendAs(
  "Performance.Cases.D1CountGraph",
  {
    size: "half",
    title: "Deflection 1 Counts",
    description: "Clicked / Searched" 
  },
  graphqlActivitySummaries(
    "withDeflectionCount",
    gql`
    query withDeflectionCount($filters: Filters) {
      supportCases {
        deflections(filters: $filters) {
          csvTag

          rows {
            id
            label
            clickedCount
            searchedCount
          }
        }
      }
    }
    `,
    {
      wrangle: {
        path: "supportCases.deflections",
        seriesDefinitions: [
          { field: "clickedCount", label: "Clicked" },
          { field: "searchedCount", label: "Searched", yaxis: { opposite: true } },
        ],
        xAxisField: "label",
      }
    }
  )
);

export const D2CountGraph = LineGraph.extendAs(
  "Performance.Cases.D2CountGraph",
  {
    size: "half",
    title: "Deflection 2 Counts",
    description: "Clicked Without Submitting / Clicked" 
  },
  graphqlActivitySummaries(
    "withDeflectionCount",
    gql`
    query withDeflectionCount($filters: Filters) {
      supportCases {
        deflections(filters: $filters) {
          csvTag

          rows {
            id
            label
            clickedAndDidNotSubmitCount
            clickedCount
          }
        }
      }
    }
    `,
    {
      wrangle: {
        path: "supportCases.deflections",
        seriesDefinitions: [
          { field: "clickedAndDidNotSubmitCount", label: "Did Not Submit" },
          { field: "clickedCount", label: "Clicked", yaxis: { opposite: true } },
        ],
        xAxisField: "label",
      }
    }
  )
);

export const D3CountGraph = LineGraph.extendAs(
  "Performance.Cases.D3CountGraph",
  {
    size: "half",
    title: "Deflection 3 Counts",
    description: "Deflection via Support Center" 
  },
  graphqlActivitySummaries(
    "withDeflectionCount",
    gql`
    query withDeflectionCount($filters: Filters) {
      supportCases {
        deflections(filters: $filters) {
          csvTag

          rows {
            id
            label
            visitsWithoutSubmittingCount
            totalVisitsCount
          }
        }
      }
    }
    `,
    {
      wrangle: {
        path: "supportCases.deflections",
        seriesDefinitions: [
          { field: "visitsWithoutSubmittingCount", label: "Visits Without Submitting" },
          { field: "totalVisitsCount", label: "Total Visits", yaxis: { opposite: true } },
        ],
        xAxisField: "label",
      }
    }
  )
);

export const SolutionSearchGraph = LineGraph.extendAs(
  "Performance.Cases.SolutionSearchGraph",
  {
    size: "half",
    title: "Solution Searches",
    description: "Y axis is # of solution searches, X axis is time"
  },
  graphqlAutoActivitySummaries("withSolutionSearches", {
    path: "supportCases.searches",
    activitiesLabel: "Solution Searches"
  })
);

export const SolutionClickGraph = LineGraph.extendAs(
  "Performance.Cases.SolutionClickGraph",
  {
    size: "half",
    title: "Solution Clicks"
  },
  graphqlAutoActivitySummaries("withSolutionClicks", {
    path: "supportCases.clicks",
    activitiesLabel: "Solution Clicks"
  })
);

export const SolutionOpenGraph = LineGraph.extendAs(
  "Performance.Cases.SolutionOpenGraph",
  {
    size: "half",
    title: "Cases Opened"
  },
  graphqlAutoActivitySummaries("withSubmittedSupportCases", {
    path: "supportCases.submissions",
    activitiesLabel: "Cases Opened"
  })
);

export const SupportCasesByProductTable = Table.extendAs(
  "Performance.Cases.SupportCasesByProductTable",
  {
    size: "full",
    title: "Support Cases by Product",
  },
  graphql(
    gql`
    query withSupportCasesByProduct($filters: Filters) {
      supportCases {
        byProduct(filters: $filters) {
          csvTag

          rows {
            id
            productId
            productTitle
            submitCount
            clickCount
          }
        }
      }
    }
    `,
    {
      name: "withSupportCasesByProduct",
      options: ({ localFilters: filters } = {}) => ({
        fetchPolicy: "cache-and-network",
        variables: { filters }
      }),
      props: (props) => ({
        csvTag: get(props, "withSupportCasesByProduct.supportCases.byProduct.csvTag"),
        isLoading: get(props, "withSupportCasesByProduct.loading", true),
        tableProps: {
          columns: [
            {
              Header: "Product",
              accessor: "productTitle",
            },
            {
              Header: "Cases opened",
              accessor: "submitCount"
            },
            {
              Header: "Solution Clicks",
              accessor: "clickCount"
            }
          ],
          data: get(props, "withSupportCasesByProduct.supportCases.byProduct.rows", [])
        }
      })
    }
  )
);

export default class PerformanceCases extends Component {
  static displayName = "Performance.Cases";

  render() {
    return (
      <React.Fragment>
        <OpenCasesByPeriodGraph />
        <DeflectionRateGraph />
        <D1CountGraph />
        <D2CountGraph />
        <SolutionSearchGraph />
        <SolutionClickGraph />
        <SolutionOpenGraph />
        <SupportCasesByProductTable />
      </React.Fragment>
    );
  }
}
