import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Filled extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 22;
  }

  get defaultWidth() {
    return 22;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 22 22";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            d="M8,8 C8,6.3425 9.3434375,5 11,5 C12.6575,5 14,6.3425 14,8 C14,9.6575 12.6575,11 11,11 C9.3434375,11 8,9.6575 8,8 M5,15.5 L5,16.5 L17,16.5 L17.0018755,15.5056585 C17.0050006,13.8488072 15.6643906,12.5031304 14.0075393,12.5000053 C14.0056531,12.5000018 14.003767,12.5 14.0018808,12.5 L8,12.5 C6.34314575,12.5 5,13.8431458 5,15.5 Z"
          />
          <path
            fill={this.fill}
            d="M8,8 C8,6.3425 9.3434375,5 11,5 C12.6575,5 14,6.3425 14,8 C14,9.6575 12.6575,11 11,11 C9.3434375,11 8,9.6575 8,8 M5,15.5 L5,16.5 L17,16.5 L17.0018755,15.5056585 C17.0050006,13.8488072 15.6643906,12.5031304 14.0075393,12.5000053 C14.0056531,12.5000018 14.003767,12.5 14.0018808,12.5 L8,12.5 C6.34314575,12.5 5,13.8431458 5,15.5 Z"
          />
        </g>
      </svg>
    );
  }
}
