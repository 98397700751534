import React, { PureComponent } from "react";

import { NamedLink } from "Components/Routing";
import { IconButton, IconComposer } from "Components/Utility";
import {
  Container as GlobalSearchContainer,
  MobileContainer as GlobalMobileSearchContainer
} from "Components/GlobalSearch";
import { NavList, Userbox } from "Components/Navigation";
import Drawer from "./Drawer";

import styles from "./SiteHeader.module.scss";

export default class SiteHeader extends PureComponent {
  static displayName = "Layout.SiteHeader";

  constructor(props) {
    super(props);

    this.state = {
      leftDrawerOpen: false,
      rightDrawerOpen: false
    };

    this.resizeId = null;
    this.breakpoint = 1022;
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    if (this.resizeId) {
      window.cancelAnimationFrame(this.resizeId);
    }

    this.resizeId = window.requestAnimationFrame(() => {
      const { leftDrawerOpen, rightDrawerOpen } = this.state;

      // if below breakpoint or both drawers are closed, do nothing
      if (
        window.innerWidth < this.breakpoint ||
        (!leftDrawerOpen && !rightDrawerOpen)
      )
        return null;

      // otherwise close both drawers
      this.setState({
        leftDrawerOpen: false,
        rightDrawerOpen: false
      });
    });
  };

  handleDrawerToggleClick(drawer) {
    const { leftDrawerOpen, rightDrawerOpen } = this.state;

    this.setState({
      leftDrawerOpen: drawer === "left" && !leftDrawerOpen,
      rightDrawerOpen: drawer === "right" && !rightDrawerOpen
    });
  }

  renderLeftDrawer() {
    return (
      <Drawer
        position="left"
        handleCloseClick={event => this.handleDrawerToggleClick("left")}
      >
        <NavList useCollapse={false} />
        <Userbox size="tall" />
      </Drawer>
    );
  }

  renderRightDrawer() {
    return (
      <Drawer
        position="right"
        title="Filter Results:"
        handleCloseClick={event => this.handleDrawerToggleClick("right")}
      >
        <GlobalMobileSearchContainer
          handleApplyClick={event => this.handleDrawerToggleClick("right")}
        />
      </Drawer>
    );
  }

  renderButtons() {
    return (
      <React.Fragment>
        <div className={styles.navButtonContainer}>
          <IconButton
            handleClick={event => this.handleDrawerToggleClick("left")}
            icon="Menu32"
            srText="Show mobile navigation drawer."
          />
        </div>
        <div className={styles.filterButtonContainer}>
          <IconButton
            handleClick={event => this.handleDrawerToggleClick("right")}
            icon="Filter24"
            iconSize={28}
            srText="Show mobile filters drawer."
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { leftDrawerOpen, rightDrawerOpen } = this.state;
    const svgProps = { "aria-hidden": false };

    return (
      <header className={styles.container}>
        <div className={styles.inner}>
          <div className={styles.logoContainer}>
            <NamedLink name="home">
              <IconComposer icon="LogoSCUnique" svgProps={svgProps} />
            </NamedLink>
          </div>
          <div className={styles.searchContainer}>
            <GlobalSearchContainer />
          </div>
          {this.renderButtons()}
        </div>
        {leftDrawerOpen && this.renderLeftDrawer()}
        {rightDrawerOpen && this.renderRightDrawer()}
      </header>
    );
  }
}
