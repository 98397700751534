import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./PageBody.module.scss";

export default class PageBody extends Component {
  static displayName = "Layout.PageBody";

  static propTypes = {
    children: PropTypes.node
  };

  render() {
    const { children } = this.props;

    return (
      <main className={styles.container}>
        <div className={styles.inner}>{children}</div>
      </main>
    );
  }
}
