import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Filter extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 24;
  }

  get defaultWidth() {
    return 24;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 24 24";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M14.500977,13.1497214 L14.500977,19.5 C14.500977,19.7761424 14.2771193,20 14.000977,20 C13.7248346,20 13.500977,19.7761424 13.500977,19.5 L13.500977,12.96 C13.500977,12.8377707 13.5457496,12.7197751 13.6268334,12.6283125 L20.3895218,5 L3.61243215,5 L10.3751205,12.6283125 C10.4562044,12.7197751 10.500977,12.8377707 10.500977,12.96 L10.500977,19.5 C10.500977,19.7761424 10.2771193,20 10.000977,20 C9.7248346,20 9.50097697,19.7761424 9.50097697,19.5 L9.50097697,13.1497214 L2.12683344,4.83168753 C1.8408446,4.50909212 2.06986514,4 2.50097697,4 L21.500977,4 C21.9320888,4 22.1611093,4.50909212 21.8751205,4.83168753 L14.500977,13.1497214 Z"
          />
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M14.500977,13.1497214 L14.500977,19.5 C14.500977,19.7761424 14.2771193,20 14.000977,20 C13.7248346,20 13.500977,19.7761424 13.500977,19.5 L13.500977,12.96 C13.500977,12.8377707 13.5457496,12.7197751 13.6268334,12.6283125 L20.3895218,5 L3.61243215,5 L10.3751205,12.6283125 C10.4562044,12.7197751 10.500977,12.8377707 10.500977,12.96 L10.500977,19.5 C10.500977,19.7761424 10.2771193,20 10.000977,20 C9.7248346,20 9.50097697,19.7761424 9.50097697,19.5 L9.50097697,13.1497214 L2.12683344,4.83168753 C1.8408446,4.50909212 2.06986514,4 2.50097697,4 L21.500977,4 C21.9320888,4 22.1611093,4.50909212 21.8751205,4.83168753 L14.500977,13.1497214 Z"
          />
        </g>
      </svg>
    );
  }
}
