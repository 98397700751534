import gql from "graphql-tag";

export default gql`
  mutation setTimeFilters($startTime: String, $untilTime: String) {
    setTimeFilters(startTime: $startTime, untilTime: $untilTime) @client {
      startTime
      untilTime
    }
  }
`;
