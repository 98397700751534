import { bindAll, clamp, pick, range } from "lodash";
import { DateUtils } from "react-day-picker";
import { BehaviorSubject } from "rxjs";

import DateRange from "./DateRange";
import moment, { tryMoment } from "./moment";

const { isDayInRange } = DateUtils;

const DATE = Symbol("DATE");
const MIN = Symbol("MIN");
const MAX = Symbol("MAX");
const MOMENT = Symbol("MOMENT");
const PRESETS = Symbol("PRESETS");
const SUBJECT = Symbol("SUBJECT");
const UPDATE = Symbol("UPDATE");
const VALUE = Symbol("VALUE");

const MAX_QUARTERS = 5;

export class DateBoundary {
  constructor(value, { isMax = false } = {}) {
    Object.defineProperties(this, {
      isMax: {
        value: Boolean(isMax)
      }
    });

    this[UPDATE](value);
  }

  get date() {
    return this[DATE];
  }

  get moment() {
    return this[MOMENT];
  }

  get value() {
    return this[VALUE];
  }

  toJSON() {
    return this[DATE].toString();
  }

  toString() {
    return this[VALUE];
  }

  valueOf() {
    return this[VALUE];
  }

  [UPDATE](value) {
    const m = tryMoment(value);

    if (!m) {
      throw new Error(`Invalid date ${value}`);
    }

    if (this.isMax) {
      m.endOf("day");
    } else {
      m.startOf("day");
    }

    const date = m.toDate();

    Object.defineProperties(this, {
      [DATE]: {
        configurable: true,
        value: date
      },

      [MOMENT]: {
        configurable: true,
        value: m
      },

      [VALUE]: {
        configurable: true,
        value: value
      }
    });
  }
}

const HELPER_METHODS = [
  "clampDate", "getInitialDateRange",
  "isValidCalendarDay", "isInvalidCalendarDay",
  "toDateRange"
];

const CONTEXT_PROPS = [
  "minimumDate", "minimumDay",
  "maximumDate", "maximumDay",
  "presets"
];

export default class DateBoundaries {
  constructor(minimum, maximum) {
    const min = new DateBoundary(minimum);
    const max = new DateBoundary(maximum, { isMax: true });
    const subject = new BehaviorSubject();


    Object.defineProperties(this, {
      [MIN]: {
        value: min
      },
      [MAX]: {
        value: max
      },
      [SUBJECT]: {
        value: subject
      }
    });

    this[PRESETS] = this.createPresets();

    bindAll(this, HELPER_METHODS);

    subject.next(this.toContext());
  }

  get minimumDate() {
    return this[MIN].date;
  }

  get maximumDate() {
    return this[MAX].date;
  }

  get minimumDay() {
    return this[MIN].value;
  }

  get maximumDay() {
    return this[MAX].value;
  }

  get presets() {
    return this[PRESETS];
  }

  /**
   * @param {?Date / ?string} day
   * @param {Date} min
   * @param {Date} max
   * @return {Date}
   */
  clampDate(day, min = this.minimumDate, max = this.maximumDate) {
    if (!day) {
      return null;
    }

    const mday = moment.utc(day);
    const mmin = moment.utc(min);
    const mmax = moment.utc(max);

    return moment.max(moment.min(mday, mmax), mmin).toDate();
  }

  /**
   * @return {DateRange}
   */
  getInitialDateRange() {
    return this.toDateRange({
      startTime: this.minimumDate,
      untilTime: this.maximumDate
    });
  }

  /**
   * @return {boolean}
   */
  isValidCalendarDay(day) {
    const { minimumDate: from, maximumDate: to } = this;

    return isDayInRange(day, { from, to });
  }

  /**
   * @return {boolean}
   */
  isInvalidCalendarDay(day) {
    return !this.isValidCalendarDay(day);
  }

  /**
   * @return {DateRange}
   */
  toDateRange({ startTime, untilTime }) {
    return new DateRange(startTime, untilTime);
  }

  /**
   * @param {function} fn
   * @return {Subscription}
   */
  subscribe(fn) {
    return this[SUBJECT].subscribe(fn);
  }

  /**
   * @return {object}
   */
  toContext() {
    return pick(this, [...HELPER_METHODS, ...CONTEXT_PROPS]);
  }

  /**
   * @param {string} minimum
   * @param {string} maximum
   * @chainable
   */
  update(minimum, maximum) {
    this[MIN][UPDATE](minimum);
    this[MAX][UPDATE](maximum);
    this[PRESETS] = this.createPresets();

    this[SUBJECT].next(this.toContext());

    return this;
  }

  createPresetDateOrigin(unit, { offset = 0 } = {}) {
    const d = moment.utc();

    if (offset < 0) {
      d.subtract(Math.abs(offset), unit);
    } else if (offset > 0) {
      d.add(offset, unit);
    }

    return d;
  }

  createPresetDateRange(unit, options = {}) {
    const startTime = this.clampDate(
      this.createPresetDateOrigin(unit, options).startOf(unit)
    );

    const untilTime = this.clampDate(
      this.createPresetDateOrigin(unit, options).endOf(unit)
    );

    return new DateRange(startTime, untilTime);
  }

  createPresetRange(label, unit, options = {}) {
    const dateRange = this.createPresetDateRange(unit, options);

    return {
      label,
      dateRange
    };
  }

  createPresetQuarterRange(offset) {
    const dateRange = this.createPresetDateRange("quarter", { offset });

    const label = dateRange.quarterLabel;

    return { label, dateRange };
  }

  createPresetQuarterRanges() {
    const currentQuarter = moment().utc().startOf("quarter");
    const oldestQuarter  = this[MIN].moment.clone().startOf("quarter");

    const numberOfQuarters = currentQuarter.diff(oldestQuarter, "quarters") + 1;

    const maxNumberOfQuarters = clamp(numberOfQuarters, 1, MAX_QUARTERS);

    return range(0, maxNumberOfQuarters).map(offset =>
      this.createPresetQuarterRange(-offset)
    );
  }

  createPresets() {
    return [
      [
        this.createPresetRange("This Week", "week"),
        this.createPresetRange("This Month", "month"),
        this.createPresetRange("This Year", "year")
      ],
      [
        this.createPresetRange("Last Week", "week", { offset: -1 }),
        this.createPresetRange("Last Month", "month", { offset: -1 }),
        this.createPresetRange("Last Year", "year", { offset: -1 })
      ],
      this.createPresetQuarterRanges(),
      [
        {
          label: "All Time",
          dateRange: this.getInitialDateRange()
        }
      ]
    ];
  }
}
