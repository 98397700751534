import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import { compose, withHandlers } from "recompose";

import DateRangeFilter from "Components/Utility/DateRangeFilter";
import withCurrentDateRange from "HOC/withCurrentDateRange";
import { setTimeFilters } from "Queries";
import { DateRangeProp } from "Utilities/Temporal";

export class DateFilter extends Component {
  static displayName = "GlobalSearch.DateFilter";

  static propTypes = {
    currentDateRange: DateRangeProp.isRequired,
    updateDateRange: PropTypes.func.isRequired
  };

  render() {
    const { currentDateRange: dateRange, updateDateRange } = this.props;

    return (
      <DateRangeFilter
        dateRange={dateRange}
        updateDateRange={updateDateRange}
      />
    );
  }
}

const withMutation = graphql(setTimeFilters, {
  options: props => ({
    fetchPolicy: "no-cache"
  })
});

const withUpdateDateRange = withHandlers({
  updateDateRange: ({ mutate }) => ({ startTime, untilTime }) => {
    if (startTime && untilTime) {
      mutate({ variables: { startTime, untilTime } });
    }
  }
});

export default compose(
  withCurrentDateRange,
  withMutation,
  withUpdateDateRange
)(DateFilter);
