import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Medal1 extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 22;
  }

  get defaultWidth() {
    return 22;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 22 22";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            d="M8.68394444,5 L10.1950556,5 L10.1950556,9.69483333 L8.68394444,8.4 L8.68394444,5 Z M12.4617222,5 L13.9728333,5 L13.9728333,8.4 L12.4617222,9.69483333 L12.4617222,5 Z M10.9506111,8.02222222 L10.9506111,5 L11.7061667,5 L11.7061667,8.02222222 L10.9506111,8.02222222 Z M11.3283889,8.65594444 L12.3502778,11.8009444 L15.6567778,11.8009444 L12.9811667,13.7446111 L14.0030556,16.8886667 L11.3283889,14.9459444 L8.65277778,16.8886667 L9.67466667,13.7446111 L7,11.8009444 L10.3065,11.8009444 L11.3283889,8.65594444 Z"
          />
          <path
            fill={this.fill}
            d="M8.68394444,5 L10.1950556,5 L10.1950556,9.69483333 L8.68394444,8.4 L8.68394444,5 Z M12.4617222,5 L13.9728333,5 L13.9728333,8.4 L12.4617222,9.69483333 L12.4617222,5 Z M10.9506111,8.02222222 L10.9506111,5 L11.7061667,5 L11.7061667,8.02222222 L10.9506111,8.02222222 Z M11.3283889,8.65594444 L12.3502778,11.8009444 L15.6567778,11.8009444 L12.9811667,13.7446111 L14.0030556,16.8886667 L11.3283889,14.9459444 L8.65277778,16.8886667 L9.67466667,13.7446111 L7,11.8009444 L10.3065,11.8009444 L11.3283889,8.65594444 Z"
          />
        </g>
      </svg>
    );
  }
}
