import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

import { compose, withPropsOnChange } from "recompose";

import wrapRouteComponent from "HOC/wrapRouteComponent";

import { NamedRouteContext } from "Routes";

export class NamedRoute extends Component {
  static displayName = "Routing.NamedRoute";

  static contextType = NamedRouteContext;

  static propTypes = {
    // This should be a react component. `func` matches class components just fine.
    component: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired
  };

  render() {
    const { name, component } = this.props;

    const route = this.context.namedRoutes.get(name);

    const path = route.path;

    return <Route path={path} exact component={component} />;
  }
}

const withWrappedComponent = withPropsOnChange(
  ["component", "name", "authenticated"],
  ({ component, name, authenticated }) => ({
    component: wrapRouteComponent(name, { authenticated })(component),
    originalComponent: component
  })
);

export default compose(withWrappedComponent)(NamedRoute);
