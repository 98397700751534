import React, { Component } from "react";

import instance from "./instance";

import Context from "./Context";

export default class Provider extends Component {
  static displayName = "LocalFilters.Provider";

  async componentDidMount() {
    this.subscription$ = instance.subscribe(
      localFilters => {
        this.setState(localFilters);
      },
      err => {
        console.warn("Problem in local filters subscription");
        console.error(err);
      }
    );

    await instance.restoreFromCache();
  }

  componentWillUnmount() {
    if (
      this.subscription$ &&
      typeof this.subscription$.unsubscribe === "function"
    ) {
      this.subscription$.unsubscribe();
    }
  }

  render() {
    const { children } = this.props;

    return <Context.Provider children={children} value={this.state} />;
  }
}
