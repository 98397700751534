import React, { Component } from "react";
import { get } from "lodash";

import { Count, Table } from "Components/Data";
import { CountCell } from "Components/Table";
import { graphql, gql } from "HOC/graphql";

import { humanizeDuration } from "Utilities/Temporal";

import { PopularTermsTable } from "./Searches";

export const AverageViewsPerSessionValue = Count.extendAs(
  "Behavior.Root.AverageViewsPerSessionValue",
  {
    size: "half",
    title: "Average Views per Session"
  },
  graphql(
    gql`
    query withAverageViewsPerSessionValue($filters: Filters) {
      behavior {
        viewsPerSession(filters: $filters) {
          id
          average
        }
      }
    }
    `,
    {
      name: "withAverageViewsPerSessionValue",
      options: ({ localFilters: filters } = {}) => ({
        fetchPolicy: "cache-and-network",
        variables: { filters }
      }),
      props: (props) => ({
        isLoading: get(props, "withAverageViewsPerSessionValue.loading", true),
        value: get(props, "withAverageViewsPerSessionValue.behavior.viewsPerSession.average", null),
      })
    },
  )
);

export const AverageSessionLengthValue = Count.extendAs(
  "Behavior.Root.AverageSessionLengthValue",
  {
    size: "half",
    title: "Average Session Length"
  },
  graphql(
    gql`
    query withAverageSessionLengthValue($filters: Filters) {
      behavior {
        sessionLength(filters: $filters) {
          id
          average
        }
      }
    }
    `,
    {
      name: "withAverageSessionLengthValue",
      options: ({ localFilters: filters } = {}) => ({
        fetchPolicy: "cache-and-network",
        variables: { filters }
      }),
      props: (props) => {
        const isLoading = get(props, "withAverageSessionLengthValue.loading", true);
        const rawValue = get(props, "withAverageSessionLengthValue.behavior.sessionLength.average", null);
        const value = rawValue ? humanizeDuration(rawValue) : null;

        return { isLoading, rawValue, value };
      }
    },
  )
);

export const MostPopularContentTable = Table.extendAs(
  "Behavior.Root.MostPopularContentTable",
  {
    size: "full",
    title: "Popular Content",
  },
  graphql(
    gql`
    query withMostPopularContent($filters: Filters) {
      content {
        mostPopular(filters: $filters) {
          csvTag

          rows {
            id
            resourceId
            resourceType
            resourceTitle
            activitiesCount
            usersCount
          }
        }
      }
    }
    `,
    {
      name: "withMostPopularContent",
      options: ({ localFilters: filters } = {}) => ({
        fetchPolicy: "cache-and-network",
        variables: { filters }
      }),
      props: (props) => ({
        csvTag: get(props, "withMostPopularContent.content.mostPopular.csvTag"),
        isLoading: get(props, "withMostPopularContent.loading", true),
        tableProps: {
          columns: [
            {
              Header: "Title",
              accessor: "resourceTitle"
            },
            {
              Cell: CountCell,
              Header: "Views",
              accessor: "activitiesCount"
            },
            {
              Cell: CountCell,
              Header: "Unique User Impressions",
              accessor: "usersCount"
            }
          ],
          data: get(props, "withMostPopularContent.content.mostPopular.rows", [])
        }
      })
    }
  )
);

export default class BehaviorRoot extends Component {
  static displayName = "Behavior.Root";

  render() {
    return (
      <React.Fragment>
        <AverageViewsPerSessionValue />
        <AverageSessionLengthValue />
        <MostPopularContentTable />
        <PopularTermsTable />
      </React.Fragment>
    );
  }
}
