const { find, matchesProperty, property } = require("lodash");
const { map } = require("lodash/fp");

const KEYS = [];

const toOptions = map(property("option"));

class ArticleKey {
  constructor({ value, position, label }) {
    Object.defineProperties(this, {
      label: {
        value: label
      },

      option: {
        value: {
          label,
          value,
          position
        }
      },

      position: {
        value: position
      },

      value: {
        value
      }
    });
  }

  toString() {
    return this.value;
  }

  valueOf() {
    return this.position;
  }

  /**
   * @param {string} value
   * @param {string} label
   * @return {void}
   */
  static define(value, label) {
    const position = KEYS.length + 1;

    const instance = new ArticleKey({ value, label, position });

    KEYS.push(instance);

    return;
  }
}

ArticleKey.define("keyword",  "Keyword");
ArticleKey.define("author",   "Author");
ArticleKey.define("manager",  "Manager");
//ArticleKey.define("director", "Director");
//ArticleKey.define("category", "Category");

Object.freeze(KEYS);

const Interface = {
  *[Symbol.iterator]() {
    for (const instance of KEYS) {
      yield instance
    }
  },

  get length() {
    return KEYS.length;
  },

  get options() {
    return toOptions(KEYS);
  },

  first() {
    return KEYS[0];
  },

  get(value) {
    return find(KEYS, matchesProperty("value", value));
  },

  getOrFirst(value) {
    const found = Interface.get(value);

    if (found) {
      return found;
    }

    return KEYS[0];
  },

  getFilter(value) {
    return Interface.getOrFirst(value).toString();
  },

  toOptions() {
    return toOptions(KEYS);
  },
};

if (process.env.NODE_ENV === "development") {
  window.ARTICLE_KEYS = Interface;
}

export default Interface;
