import React, { Component } from "react";
import PropTypes from "prop-types";

import AuthState, { Context as AuthContext } from "Auth";

export default class Provider extends Component {
  static displayName = "Authentication.Provider";

  static propTypes = {
    children: PropTypes.node
  };

  state = {
    authenticatedUser: null,
    authState: AuthState,
    isAuthenticating: false,
    isAuthenticated: false
  };

  componentDidMount() {
    this.subscription$ = AuthState.subscribe(newState =>
      this.setState(newState)
    );
  }

  componentWillUnmount() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }

  render() {
    const { children } = this.props;

    return <AuthContext.Provider children={children} value={this.state} />;
  }
}
