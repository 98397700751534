import { InMemoryCache } from "apollo-cache-inmemory";
import { persistCache } from "apollo-cache-persist";

import localForage from "localforage";

/*import { dataIdFromObject } from "./utilities";*/

export const storage = localForage.createInstance({
  driver: localForage.INDEXEDDB,
  name: "SCA-apollo-cache",
  storeName: "SCA-apollo-cache-store",
  description: "Local cache for apollo client"
});

const cache = new InMemoryCache({
  /*  dataIdFromObject */
});

persistCache({
  cache,
  storage,
  // IndexedDB supports storing JSON directly.
  serialize: false,
  debug: process.env.NODE_ENV === "development"
});

if (process.env.NODE_ENV === "development") {
  window.apolloCache = cache;
  window.apolloCacheStore = storage;
}

export default cache;
