import React, { Component } from "react";
import PropTypes from "prop-types";

import makePropsChanged from "Utilities/make-props-changed";
import { BOUNDARIES } from "Utilities/Temporal";

import CalendarContext from "./CalendarContext";
import GlobalContext from "./GlobalContext";

const datesChanged = makePropsChanged("minimumDay", "maximumDay");

export default class CalendarProvider extends Component {
  static displayName = "Application.CalendarProvider";

  static contextType = GlobalContext;

  static propTypes = {
    children: PropTypes.node,
    minimumDay: PropTypes.string,
    maximumDay: PropTypes.string,
  };

  state = {
    minimumDate: null,
    minimumDay: null,
    maximumDate: null,
    maximumDay: null,
    presets: [],
  };

  componentDidMount() {
    this.subscription$ = BOUNDARIES.subscribe(newState =>
      this.setState(newState)
    );
  }

  componentDidUpdate(prevProps) {
    if (datesChanged(this.props, prevProps)) {
      BOUNDARIES.update(this.props.minimumDay, this.props.maximumDay);
    }
  }

  componentWillUnmount() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }

  render() {
    const { children } = this.props;

    return <CalendarContext.Provider children={children} value={this.state} />;
  }
}
