import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class User extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 32;
  }

  get defaultWidth() {
    return 32;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 32 32";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M12.5,13 C12.5,11.0670411 14.0674741,9.5 16,9.5 C17.9333424,9.5 19.5,11.0666576 19.5,13 C19.5,14.9333424 17.9333424,16.5 16,16.5 C14.0674741,16.5 12.5,14.9329589 12.5,13 Z M13.5,13 C13.5,14.3806123 14.619697,15.5 16,15.5 C17.3810576,15.5 18.5,14.3810576 18.5,13 C18.5,11.6189424 17.3810576,10.5 16,10.5 C14.619697,10.5 13.5,11.6193877 13.5,13 Z M16,26.5 C9.92499399,26.5 5,21.575006 5,15.5 C5,9.42499399 9.92499399,4.5 16,4.5 C22.075006,4.5 27,9.42499399 27,15.5 C27,21.575006 22.075006,26.5 16,26.5 Z M16,25.5 C21.5227213,25.5 26,21.0227213 26,15.5 C26,9.97727874 21.5227213,5.5 16,5.5 C10.4772787,5.5 6,9.97727874 6,15.5 C6,21.0227213 10.4772787,25.5 16,25.5 Z M21.5000041,21.5009054 C21.4995041,21.7770473 21.2752415,22.0004992 20.9990995,21.9999992 C20.7229576,21.9994991 20.4995057,21.7752365 20.5000058,21.4990946 C20.5024969,20.123379 19.3630465,19.0025121 17.95,19 L14.056,19 C12.6421593,19 11.5,20.1191819 11.5,21.494 C11.5,21.7701424 11.2761424,21.994 11,21.994 C10.7238576,21.994 10.5,21.7701424 10.5,21.494 C10.5,19.5622973 12.0943722,18 14.056,18 L17.945,18 L17.9508886,18.0000008 C19.9126867,18.0034874 21.5035036,19.5683595 21.5000041,21.5009054 Z"
          />
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M12.5,13 C12.5,11.0670411 14.0674741,9.5 16,9.5 C17.9333424,9.5 19.5,11.0666576 19.5,13 C19.5,14.9333424 17.9333424,16.5 16,16.5 C14.0674741,16.5 12.5,14.9329589 12.5,13 Z M13.5,13 C13.5,14.3806123 14.619697,15.5 16,15.5 C17.3810576,15.5 18.5,14.3810576 18.5,13 C18.5,11.6189424 17.3810576,10.5 16,10.5 C14.619697,10.5 13.5,11.6193877 13.5,13 Z M16,26.5 C9.92499399,26.5 5,21.575006 5,15.5 C5,9.42499399 9.92499399,4.5 16,4.5 C22.075006,4.5 27,9.42499399 27,15.5 C27,21.575006 22.075006,26.5 16,26.5 Z M16,25.5 C21.5227213,25.5 26,21.0227213 26,15.5 C26,9.97727874 21.5227213,5.5 16,5.5 C10.4772787,5.5 6,9.97727874 6,15.5 C6,21.0227213 10.4772787,25.5 16,25.5 Z M21.5000041,21.5009054 C21.4995041,21.7770473 21.2752415,22.0004992 20.9990995,21.9999992 C20.7229576,21.9994991 20.4995057,21.7752365 20.5000058,21.4990946 C20.5024969,20.123379 19.3630465,19.0025121 17.95,19 L14.056,19 C12.6421593,19 11.5,20.1191819 11.5,21.494 C11.5,21.7701424 11.2761424,21.994 11,21.994 C10.7238576,21.994 10.5,21.7701424 10.5,21.494 C10.5,19.5622973 12.0943722,18 14.056,18 L17.945,18 L17.9508886,18.0000008 C19.9126867,18.0034874 21.5035036,19.5683595 21.5000041,21.5009054 Z"
          />
        </g>
      </svg>
    );
  }
}
