import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import DayPickerInput, { defaultParse } from "react-day-picker/DayPickerInput";
import { DateUtils } from "react-day-picker";
import {
  compose,
  defaultProps,
  fromRenderProps,
  lifecycle,
  withHandlers,
  withPropsOnChange,
  withStateHandlers
} from "recompose";
import { get, isEmpty, noop } from "lodash";
import { pick } from "lodash/fp";

import CalendarContext from "Components/Application/CalendarContext";

import makePropsChanged from "Utilities/make-props-changed";
import {
  DateRangeProp,
  earliestDate,
  latestDate,
  moment,
  toDateRange,
} from "Utilities/Temporal";

import styles from "./RangeInputs.module.scss";

const { isDayInRange, isSameDay } = DateUtils;

const renderDay = day => <span>{day.getDate()}</span>;

const formatDate = (date) => moment.utc(date).format("YYYY-MM-DD");

const DateProp = PropTypes.instanceOf(Date);

export class DateInputImplementation extends PureComponent {
  static displayName = "Utility.DateRangeFilter.RangeInputs.DateInput";

  static contextType = CalendarContext;

  static propTypes = {
    dateRange: DateRangeProp.isRequired,
    dayPickerProps: PropTypes.shape({
      disabledDays: PropTypes.any,
      fromMonth: DateProp.isRequired,
      modifiers: PropTypes.object,
      month: DateProp.isRequired,
      numberOfMonths: PropTypes.number.isRequired,
      selectedDays: PropTypes.oneOfType([
        PropTypes.func.isRequired,
        PropTypes.shape({
          from: DateProp.isRequired,
          to: DateProp.isRequired,
        })
      ]),
      toMonth: DateProp.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    maximumDate: DateProp.isRequired,
    minimumDate: DateProp.isRequired,
    name: PropTypes.oneOf(["startTime", "untilTime"]).isRequired,
    onDayChange: PropTypes.func.isRequired,
    parseDate: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    selectedDay: DateProp.isRequired,
    value: PropTypes.any,
    updateDateRangeBoundary: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: "Date:",
    placeholder: "Date",
  };

  render() {
    const {
      dayPickerProps,
      id,
      label,
      onDayChange,
      parseDate,
      placeholder,
      selectedDay,
      value,
    } = this.props;

    return (
      <div className={styles.block}>
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
        <DayPickerInput
          dayPickerProps={dayPickerProps}
          formatDate={formatDate}
          hideOnDayClick
          onDayChange={onDayChange}
          parseDate={parseDate}
          placeholder={placeholder}
          selectedDay={selectedDay}
          value={value}
          inputProps={{
            className: styles.input,
            id: id
          }}
          classNames={{
            container: styles.inputWrapper,
            overlayWrapper: styles.calendar,
            overlay: ""
          }}
        />
      </div>
    );
  }
}

const IDS = {
  startTime: "DayPickerInput-From",
  untilTime: "DayPickerInput-To",
};

const LABELS = {
  startTime: "From:",
  untilTime: "To:",
};

const getStartOfMonth = (day) => moment.utc(day).startOf("month").toDate();
const getEndOfMonth = (day) => moment.utc(day).endOf("month").toDate();

const withCalendarContext = fromRenderProps(
  CalendarContext.Consumer,
  pick(["clampDate", "isInvalidCalendarDay", "minimumDate", "maximumDate" ])
);

const withDerivedInputProps = withPropsOnChange(
  ["dateRange", "minimumDate", "maximumDate", "name"],
  function({ dateRange, minimumDate, maximumDate, name }) {
    const { from, to } = dateRange;

    const id = get(IDS, name, "DayPickerInput-UNKNOWN");
    const label = get(LABELS, name, "Date:");

    const props = { from, id, label, to, selectedDays: { from, to } };

    switch(name) {
      case "startTime":
        props.minimumDate = minimumDate;
        props.maximumDate = earliestDate([to, maximumDate]);
        props.selectedDay = from;
        break;
      case "untilTime":
        props.minimumDate = latestDate([from, minimumDate]);
        props.maximumDate = maximumDate;
        props.selectedDay = to;
        break;
      default:
        // purposely left blank
    }

    props.disabledDays = [{ before: props.minimumDate, after: props.maximumDate }];

    props.placeholder = formatDate(props.selectedDay);
    props.value = formatDate(props.selectedDay);

    return props;
  }
);

const withDerivedDateHandlers = compose(
  withHandlers({
    onDayChange: ({ clampDate, minimumDate, maximumDate, name, updateDateRangeBoundary }) => (selectedDay, _modifiers, _dateRangeInput) => {
      if (selectedDay) {
        const clamped = clampDate(selectedDay, minimumDate, maximumDate);

        const formatted = formatDate(clamped);

        updateDateRangeBoundary(name, formatted);
      }
    },

    parseDate: ({ clampDate, minimumDate, maximumDate }) => (str, format, locale) => {
      const parsed = defaultParse(str, format, locale);

      return parsed ? clampDate(parsed, minimumDate, maximumDate) : parsed;
    },

    isFromDay: ({ from }) => (day) => isSameDay(day, from),

    isLeftEdge: ({ from }) => (day) => {
      if (isSameDay(day, from)) {
        return true;
      }

      const startOfMonth = getStartOfMonth(day);

      return isSameDay(day, startOfMonth);
    },

    isRightEdge: ({ to }) => (day) => {
      if (isSameDay(day, to)) {
        return true;
      }

      const endOfMonth = getEndOfMonth(day);

      return isSameDay(day, endOfMonth);
    },

    isToDay: ({ to }) => day => isSameDay(day, to)
  }),
  withHandlers({
    isOnlyEdge: ({ from, to, selectedDays }) => day =>
      (isEmpty(from) || isEmpty(to)) && isDayInRange(day, selectedDays),
  })
);

const withDayPickerProps = withPropsOnChange(
  ["disabledDays", "isFromDay", "isLeftEdge", "isOnlyEdge", "isRightEdge", "isToDay", "maximumDate", "minimumDate", "selectedDay", "selectedDays"],
  ({ disabledDays, isFromDay, isLeftEdge, isOnlyEdge, isRightEdge, isToDay, maximumDate: toMonth, minimumDate: fromMonth, selectedDay, selectedDays }) => {
    const modifiers = {
      isLeftEdge,
      isRightEdge,
      isOnlyEdge,
      isFromDay,
      isToDay
    };

    const dayPickerProps = {
      disabledDays,
      fromMonth,
      modifiers,
      month: selectedDay,
      numberOfMonths: 1,
      onDayClick: noop,
      renderDay,
      selectedDays,
      toMonth,
    };

    return { dayPickerProps };
  }
);
 
export const DateInput = compose(
  withCalendarContext,
  withDerivedInputProps,
  withDerivedDateHandlers,
  withDayPickerProps,
)(DateInputImplementation);

export class RangeInputs extends PureComponent {
  static displayName = "Utility.DateRangeFilter.RangeInputs";

  static propTypes = {
    dateRange: DateRangeProp.isRequired,
    updateDateRange: PropTypes.func.isRequired,
    updateDateRangeBoundary: PropTypes.func.isRequired,
  };

  render() {
    const { dateRange, updateDateRangeBoundary } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.heading}>Select a Date Range:</div>
        <div className={styles.inner}>
          <DateInput
            dateRange={dateRange}
            name="startTime"
            updateDateRangeBoundary={updateDateRangeBoundary}
          />
          <DateInput
            dateRange={dateRange}
            name="untilTime"
            updateDateRangeBoundary={updateDateRangeBoundary}
          />
        </div>
      </div>
    );
  }
}

const withDerivedDateRange = withStateHandlers(
  ({ dateRange: { from, to } }) => ({ from, to }),
  {
    resetDateRange: (_, { dateRange }) => () => dateRange.daypicker,

    setDateRange: () => ({ from, to } = {}) => ({ from, to }),

    updateDateRangeBoundary: () => (name, value) => {
      const newState = {};

      switch(name) {
        case "startTime":
          newState.from = value;
          break;
        case "untilTime":
          newState.to = value;
          break;
        default:
          return undefined;
      }

      return newState;
    }
  }
);

const isDifferentRange = makePropsChanged(["from", "to"]);

const updateLifecycle = lifecycle({
  componentDidUpdate(prevProps = {}) {
    const {
      dateRange: currentDateRange,
      resetDateRange,
      updateDateRange
    } = this.props;

    const {
      dateRange: priorDateRange,
    } = prevProps;

    if (isDifferentRange(prevProps, this.props)) {
      const { from: startTime, to: untilTime } = this.props;

      const dateRange = toDateRange({ startTime, untilTime });

      if (dateRange.isValid()) {
        updateDateRange({ dateRange, startTime, untilTime });
      } else {
        console.warn("invalid DR: %s", dateRange.getDisplayText());
      }
    } else if (priorDateRange !== currentDateRange) {
      resetDateRange();
    }
  }
});

export default compose(
  defaultProps({
    updateDateRange: args => {
      console.info("Utility.DateRangeFilter.RangeInputs updateDateRange callback");
      console.dir(args);
    }
  }),
  withDerivedDateRange,
  updateLifecycle,
)(RangeInputs);
