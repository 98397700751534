import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Behavior extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 32;
  }

  get defaultWidth() {
    return 32;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 32 32";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M22.560387,20.7040472 C22.739508,20.9142145 22.71434,21.229795 22.5041728,21.408916 C22.2940055,21.588037 21.978425,21.562869 21.799304,21.3527018 L17.9684872,16.8579067 C17.7488879,16.6002452 17.8434602,16.2034069 18.1556653,16.0724822 L21.2351903,14.7810685 L11.2132254,10.361742 L13.6789028,21.0046696 L15.5340839,18.2306765 C15.7184596,17.9549853 16.1152534,17.9319242 16.3303255,18.1844001 L20.1611423,22.681446 C20.3402114,22.8916575 20.3149655,23.2072317 20.1047539,23.3863008 C19.8945424,23.5653699 19.5789682,23.5401239 19.3998991,23.3299124 L15.9977974,19.3361408 L13.8680905,22.5206237 C13.6246861,22.8845785 13.0641911,22.7820628 12.9653715,22.3555145 L10.0134818,9.61388458 C9.92051778,9.2126119 10.3254348,8.87734958 10.7023195,9.04354219 L22.7023195,14.3351112 C23.1039757,14.5122271 23.098763,15.0839404 22.6939441,15.2537031 L19.1734322,16.7300468 L22.560387,20.7040472 Z"
          />
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M22.560387,20.7040472 C22.739508,20.9142145 22.71434,21.229795 22.5041728,21.408916 C22.2940055,21.588037 21.978425,21.562869 21.799304,21.3527018 L17.9684872,16.8579067 C17.7488879,16.6002452 17.8434602,16.2034069 18.1556653,16.0724822 L21.2351903,14.7810685 L11.2132254,10.361742 L13.6789028,21.0046696 L15.5340839,18.2306765 C15.7184596,17.9549853 16.1152534,17.9319242 16.3303255,18.1844001 L20.1611423,22.681446 C20.3402114,22.8916575 20.3149655,23.2072317 20.1047539,23.3863008 C19.8945424,23.5653699 19.5789682,23.5401239 19.3998991,23.3299124 L15.9977974,19.3361408 L13.8680905,22.5206237 C13.6246861,22.8845785 13.0641911,22.7820628 12.9653715,22.3555145 L10.0134818,9.61388458 C9.92051778,9.2126119 10.3254348,8.87734958 10.7023195,9.04354219 L22.7023195,14.3351112 C23.1039757,14.5122271 23.098763,15.0839404 22.6939441,15.2537031 L19.1734322,16.7300468 L22.560387,20.7040472 Z"
          />
        </g>
      </svg>
    );
  }
}
