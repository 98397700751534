import React, { Component } from "react";

import { TotalSessionsGraph, VisitorsGraph } from "Containers/Audience/Root";
import { AverageSessionLengthValue } from "Containers/Behavior/Root";

export default class TopLevelRoot extends Component {
  static displayName = "TopLevel.Root";

  render() {
    return (
      <React.Fragment>
        <VisitorsGraph />
        <TotalSessionsGraph />
        <AverageSessionLengthValue />
      </React.Fragment>
    );
  }
}
