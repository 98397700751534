import { method } from "lodash";

import Route from "./Route";

const ROUTER = Symbol("ROUTER");
const ROUTES = Symbol("ROUTES");

export default class RouteMap {
  constructor(router) {
    this[ROUTER] = router;
    this[ROUTES] = new Map();
  }

  *[Symbol.iterator]() {
    for (const route of this[ROUTES].values()) {
      yield route;
    }
  }

  containing(targetName) {
    return Array.from(this).filter(method("contains", targetName));
  }

  define(name, path) {
    const defn = new Route(this[ROUTER], name, path);

    this[ROUTES].set(name, defn);
  }

  get(name) {
    if (this.has(name)) {
      return this[ROUTES].get(name);
    } else {
      throw new Error(`Unknown route: ${name}`);
    }
  }

  has(name) {
    return this[ROUTES].has(name);
  }

  pathFor(name) {
    return this.get(name).path;
  }

  generate(name, params = {}) {
    return this.get(name).generate(params);
  }

  generateURL(name, params = {}) {
    return this.get(name).generateURL(params);
  }

  linkGenerator(name, ...args) {
    return this.get(name).linkGenerator(...args);
  }

  tableCellLinkGenerator(name, ...args) {
    return this.get(name).tableCellLinkGenerator(...args);
  }
}
