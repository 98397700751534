import React, { Component } from "react";

import { LineGraph } from "Components/Data";
import { graphqlActivitySummaries, gql } from "HOC/graphql";

export const EffortGraph = LineGraph.extendAs(
  "Performance.Satisfaction.EffortGraph",
  {
    size: "full",
    title: "Customer Effort",
  },
  graphqlActivitySummaries(
    "withCustomerEffort",
    gql`
    query withCustomerEffort($filters: Filters) {
      survey {
        effort(filters: $filters) {
          csvTag

          rows {
            id
            label
            ratedLow
            ratedMedium
            ratedHigh
          }
        }
      }
    }
    `,
    {
      wrangle: {
        path: "survey.effort",
        seriesDefinitions: [
          { field: "ratedHigh", label: "Not Much Effort" },
          //{ field: "ratedMedium", label: "Medium Effort" },
          { field: "ratedLow", label: "Lots of Effort", yaxis: { opposite: true } },
        ],
        xAxisField: "label",
      }
    }
  )
);

export const SatisfactionGraph = LineGraph.extendAs(
  "Performance.Satisfaction.SatisfactionGraph",
  {
    size: "full",
    title: "Customer Satisfaction",
  },
  graphqlActivitySummaries(
    "withCustomerSatisfaction",
    gql`
    query withCustomerSatisfaction($filters: Filters) {
      survey {
        satisfaction(filters: $filters) {
          csvTag

          rows {
            id
            label
            ratedLow
            ratedMedium
            ratedHigh
          }
        }
      }
    }
    `,
    {
      wrangle: {
        path: "survey.satisfaction",
        seriesDefinitions: [
          { field: "ratedHigh", label: "Very Satisfied" },
          //{ field: "ratedMedium", label: "Ambivalent" },
          { field: "ratedLow", label: "Very Unsatisfied", yaxis: { opposite: true } },
        ],
        xAxisField: "label",
      }
    }
  )
);

export default class PerformanceSatisfaction extends Component {
  static displayName = "Performance.Satisfaction";

  render() {
    return (
      <React.Fragment>
        <SatisfactionGraph />
        <EffortGraph />
      </React.Fragment>
    );
  }
}
