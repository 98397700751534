import React, { Component } from "react";

import { DeflectionRateGraph, OpenCasesByPeriodGraph } from "./Cases";

export default class PerformanceRoot extends Component {
  static displayName = "Performance.Root";

  render() {
    return (
      <React.Fragment>
        <OpenCasesByPeriodGraph />
        <DeflectionRateGraph />
      </React.Fragment>
    );
  }
}
