import { createContext } from "react";
import PropTypes from "prop-types";

import { MINIMUM_DATE_STRING } from "Utilities/Temporal";

import { optionsShape } from "Components/Utility/SelectFilter";

export const globalContextShape = PropTypes.shape({
  companies: optionsShape,
  isLoading: PropTypes.bool.isRequired,
  languages: optionsShape,
  minimumDay: PropTypes.string,
  maximumDay: PropTypes.string,
  products: optionsShape
});

export const defaultGlobalContext = {
  companies: [],
  isLoading: true,
  languages: [],
  minimumDay: MINIMUM_DATE_STRING,
  maximumDay: (new Date()).toISOString().slice(0, 10),
  products: []
};

const GlobalContext = createContext(defaultGlobalContext);

GlobalContext.displayName = "Application.GlobalContext";

export default GlobalContext;
