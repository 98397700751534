import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class DownloadTray extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 24;
  }

  get defaultWidth() {
    return 24;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 24 24";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M11.5,14.2928932 L11.5,4.5 C11.5,4.22385763 11.7238576,4 12,4 C12.2761424,4 12.5,4.22385763 12.5,4.5 L12.5,14.2928932 L16.6464466,10.1464466 C16.8417088,9.95118446 17.1582912,9.95118446 17.3535534,10.1464466 C17.5488155,10.3417088 17.5488155,10.6582912 17.3535534,10.8535534 L12.3535534,15.8535534 C12.1582912,16.0488155 11.8417088,16.0488155 11.6464466,15.8535534 L6.64644661,10.8535534 C6.45118446,10.6582912 6.45118446,10.3417088 6.64644661,10.1464466 C6.84170876,9.95118446 7.15829124,9.95118446 7.35355339,10.1464466 L11.5,14.2928932 Z M20,15 C20,14.7238576 20.2238576,14.5 20.5,14.5 C20.7761424,14.5 21,14.7238576 21,15 L21,17.507 C21,18.8871424 19.8801424,20.007 18.5,20.007 L5.5,20.007 C4.11916427,20.007 3,18.8874491 3,17.507 L3,15 C3,14.7238576 3.22385763,14.5 3.5,14.5 C3.77614237,14.5 4,14.7238576 4,15 L4,17.507 C4,18.3352417 4.67152634,19.007 5.5,19.007 L18.5,19.007 C19.3278576,19.007 20,18.3348576 20,17.507 L20,15 Z"
          />
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M11.5,14.2928932 L11.5,4.5 C11.5,4.22385763 11.7238576,4 12,4 C12.2761424,4 12.5,4.22385763 12.5,4.5 L12.5,14.2928932 L16.6464466,10.1464466 C16.8417088,9.95118446 17.1582912,9.95118446 17.3535534,10.1464466 C17.5488155,10.3417088 17.5488155,10.6582912 17.3535534,10.8535534 L12.3535534,15.8535534 C12.1582912,16.0488155 11.8417088,16.0488155 11.6464466,15.8535534 L6.64644661,10.8535534 C6.45118446,10.6582912 6.45118446,10.3417088 6.64644661,10.1464466 C6.84170876,9.95118446 7.15829124,9.95118446 7.35355339,10.1464466 L11.5,14.2928932 Z M20,15 C20,14.7238576 20.2238576,14.5 20.5,14.5 C20.7761424,14.5 21,14.7238576 21,15 L21,17.507 C21,18.8871424 19.8801424,20.007 18.5,20.007 L5.5,20.007 C4.11916427,20.007 3,18.8874491 3,17.507 L3,15 C3,14.7238576 3.22385763,14.5 3.5,14.5 C3.77614237,14.5 4,14.7238576 4,15 L4,17.507 C4,18.3352417 4.67152634,19.007 5.5,19.007 L18.5,19.007 C19.3278576,19.007 20,18.3348576 20,17.507 L20,15 Z"
          />
        </g>
      </svg>
    );
  }
}
