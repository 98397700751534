import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";

import cache from "./cache";

import onErrorLink from "./onErrorLink";
import localStateLink from "./localStateLink";
import authContextLink from "./authContextLink";
import httpLink from "./httpLink";

export const link = ApolloLink.from([
  onErrorLink,
  localStateLink,
  authContextLink,
  httpLink
]);

const client = new ApolloClient({
  link,
  cache
});

client.onClearStore(localStateLink.writeDefaults);
client.onResetStore(localStateLink.writeDefaults);

if (process.env.NODE_ENV === "development") {
  window.apolloClient = client;
}

export default client;
