import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Popover from "react-popover";
import { compose, withStateHandlers } from "recompose";
import classNames from "classnames";
import { IconComposer } from "Components/Utility";
import styles from "./FilterWrapper.module.scss";

export class PopoverBody extends PureComponent {
  static displayName = "Utility.Filter.PopoverBody";

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node,
    categoryLabel: PropTypes.string
  };

  get isWithSidebarTheme() {
    return this.props.themes && this.props.themes.includes("with-sidebar");
  }

  get styles() {
    return classNames(styles.popover, {
      [styles.withSidebar]: this.isWithSidebarTheme
    });
  }

  render() {
    const { isOpen, children } = this.props;

    if (isOpen) {
      return <div className={this.styles}>{children}</div>;
    }
    return <div />;
  }
}

export class FilterWrapper extends PureComponent {
  static displayName = "Utility.Filter";

  static propTypes = {
    isClearable: PropTypes.bool.isRequired,
    themes: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    isClearable: true
  };

  constructor(props) {
    super(props);

    this.state = {
      availableHeight: null
    }

    this.popover = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.scrollOverflow || !this.props.isOpen) return null;

    if (
      !this.popover ||
      !this.popover.current ||
      !this.popover.current.containerEl
    ) return null;

    // calculate space for popover height
    const box = this.popover.current.containerEl.getBoundingClientRect();
    const availableHeight = window.innerHeight - box.top;

    // set available space in state so can be passed to popover,
    // which makes it possible to scroll any overflow
    if (availableHeight < box.height) {
      this.setState({ availableHeight: availableHeight });
    }
  }

  get showClear() {
    return this.props.isClearable && this.props.value;
  }

  get themes() {
    return this.props.themes;
  }

  get isCardTheme() {
    return this.themes && this.themes.includes("card");
  }

  get isWithSearchTheme() {
    return this.themes && this.themes.includes("with-search");
  }

  renderClear() {
    const { onClear } = this.props;
    const baseClass = "m-filter-wrapper";

    return (
      <button
        className={`${baseClass}__clear-button`}
        onClick={event => {
          onClear(null);
        }}
      >
        <IconComposer
          icon="Close22"
          iconClass={`${baseClass}__icon`}
          size="default"
        />
      </button>
    );
  }

  render() {
    const {
      closePopover,
      displayText,
      isOpen,
      togglePopover,
      isSelectFilter,
      noPopover,
      children,
      categoryLabel
    } = this.props;

    const baseClass = "m-filter-wrapper";

    const iconStyles = classNames(`${baseClass}__icon`, {
      [`${baseClass}__icon--rotated`]: isOpen
    });
    const containerStyles = classNames(styles.popover, {
      select: isSelectFilter
    });
    const pillStyles = classNames(`${baseClass}__button-outer`, {
      [`${baseClass}__button-outer--mobile-light`]: isSelectFilter,
      [`${baseClass}__button-outer--mobile-dark`]: !isSelectFilter,
      [`${baseClass}__button-outer--${categoryLabel}`]: categoryLabel,
      [`${baseClass}__button-outer--card`]: this.isCardTheme,
      [`${baseClass}__button-outer--with-search`]: this.isWithSearchTheme
    });

    const popoverStyle
      = this.state.availableHeight
        ? { height: this.state.availableHeight, overflow: "auto" }
        : null;

    if (noPopover) return <div>{children}</div>;

    return (
      <div className={classNames(
        `${baseClass}-container`,
        baseClass,
        {
          [`${baseClass}--with-search`]: this.isWithSearchTheme,
          [`${baseClass}--${categoryLabel}`]: categoryLabel
        }
      )}>
        <Popover
          className={containerStyles}
          body={<PopoverBody {...this.props} />}
          isOpen={isOpen}
          onOuterAction={closePopover}
          preferPlace="below"
          enterExitTransitionDurationMs={null}
          tipSize={11}
          offset={29}
          ref={this.popover}
          style={popoverStyle}
        >
          <div className={pillStyles} onClick={togglePopover} type="button">
            {this.isCardTheme && (
              <IconComposer
                icon="Search32"
                iconClass={`${baseClass}__icon`}
                size={28}
              />
            )}
            <span className={`${baseClass}__button-inner`}>{displayText}</span>
            {this.showClear && this.renderClear()}
            <span className={`${baseClass}__dropdown-indicator`}>
              <IconComposer
                icon="Arrow1Down22"
                iconClass={iconStyles}
                size={20}
              />
            </span>
          </div>
        </Popover>
      </div>
    );
  }
}

const withPopoverState = withStateHandlers(() => ({ isOpen: false }), {
  openPopover: () => () => ({ isOpen: true }),
  closePopover: () => () => ({ isOpen: false }),
  togglePopover: ({ isOpen }) => () => ({ isOpen: !isOpen })
});

export default compose(withPopoverState)(FilterWrapper);
