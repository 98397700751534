import React, { Component } from "react";
import classNames from "classnames";

import { IconButton } from "Components/Utility";
import CompanyFilter from "./CompanyFilter";
import DateFilter from "./DateFilter";
import ProductFilter from "./ProductFilter";

import styles from "./MobileContainer.module.scss";

export default class MobileContainer extends Component {
  static displayName = "GlobalSearch.MobileContainer";

  render() {
    const Block = props => (
      <div className={styles.filterBlock}>
        <div className="m-nav-heading m-nav-heading--pad-bottom">
          {props.label}
        </div>
        <div className={
          classNames(styles.filterWrapper, {
            [styles.filterWrapperBackground]: !props.hideBackground
          })
        }>
          {props.children}
        </div>
      </div>
    );

    return (
      <div>
        <Block label="Products:">
          <ProductFilter noPopover />
        </Block>
        <Block label="Companies:">
          <CompanyFilter noPopover />
        </Block>
        <Block label="DateRange:">
          <DateFilter />
        </Block>
        <Block hideBackground>
          <IconButton
            className={styles.applyButton}
            handleClick={this.props.handleApplyClick}
            text="Apply Filters"
            icon="Filter24"
            theme="pill"
          />
        </Block>
      </div>
    );
  }
}
