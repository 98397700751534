import React, { Component } from "react";
import { withProps } from "recompose";

import { get, property } from "lodash";

import { Count, LineGraph, PieGraph } from "Components/Data";
import { graphql, gql, graphqlAutoActivitySummaries, graphqlPieGraph } from "HOC/graphql";

export const withArticleScope = withProps((props) => ({
  articleScope: {
    articleId: get(props, "match.params.articleId", null)
  }
}));

export const ViewsGraph = LineGraph.extendAs(
  "Performance.ArticleDetail.ViewsGraph",
  {
    size: "full",
    title: "Views",
    currentRouteTitle: "Article Name",
    routeLinkName: "performance.articles",
    routeLinkText: "Back to Articles"
  },
  withArticleScope,
  graphqlAutoActivitySummaries("withArticleViews", {
    addScope: property("articleScope"),
    path: "articles.views",
    scopeType: "ScopeByArticle!",
    activitiesLabel: "Views of Collections of Articles",
    usersLabel: "Unique User Views"
  }),
  graphql(
    gql`
    query withArticle($scope: ScopeByArticle!) {
      article(scope: $scope) {
        id
        title
      }
    }`,
    {
      name: "withArticle",
      options: ({ articleScope: scope }) => ({
        fetchPolicy: "cache-and-network",
        variables: { scope }
      }),
      props: (props) => ({
        cardProps: {
          currentRouteTitle: get(props, "withArticle.article.title", "Article Name"),
        },
        isLoading: (get(props, "withArticle.loading", true) || get(props, "ownProps.isLoading", false)),
      })
    }
  )
);

export const OpenedSupportCasesCount = Count.extendAs(
  "Performance.ArticleDetail.OpenedSupportCasesCount",
  {
    size: "half",
    title: "Opened Support Cases"
  },
  withArticleScope,
  graphql(
    gql`
    query withOpenedCases($filters: Filters, $scope: ScopeByArticle!) {
      articles {
        openedCases(filters: $filters, scope: $scope) {
          id
          value
        }
      }
    }
    `,
    {
      name: "withOpenedCases",
      options: ({ articleScope: scope, localFilters: filters } = {}) => ({
        fetchPolicy: "cache-and-network",
        variables: { filters, scope }
      }),
      props: (props) => ({
        isLoading: get(props, "withOpenedCases.loading", true),
        value: get(props, "withOpenedCases.articles.openedCases.value", 0),
      })
    },
  )
);

const SourceChart = PieGraph.extendAs(
  "Performance.ArticleDetail.SourceChart",
  {
    size: "half",
    title: "Sources"
  },
  withArticleScope,
  graphqlPieGraph(
    "withArticleSources",
    gql`
      query withArticleSources($filters: Filters, $scope: ScopeByArticle!) {
        articles {
          sources(filters: $filters, scope: $scope) {
            csvTag

            rows {
              id
              source
              activitiesCount
            }
          }
        }
      }
    `,
    {
      addScope: property("match.params"),
      wrangle: {
        path: "articles.sources",
        labelField: "source",
        valueField: "activitiesCount"
      }
    }
  )
);

export default class PerformanceArticleDetail extends Component {
  static displayName = "Performance.ArticleDetail";

  render() {
    return (
      <React.Fragment>
        <ViewsGraph />

        <OpenedSupportCasesCount />
        <SourceChart />
      </React.Fragment>
    );
  }
}
