import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Medal2 extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 22;
  }

  get defaultWidth() {
    return 22;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 22 22";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            d="M10.912,11.4 C12.017,11.4 12.912,12.295 12.912,13.4 C12.912,14.505 12.017,15.4 10.912,15.4 C9.808,15.4 8.912,14.505 8.912,13.4 C8.912,12.295 9.808,11.4 10.912,11.4 Z M13.569,9.893 C14.628,10.696 15.312,11.968 15.312,13.4 C15.312,15.83 13.342,17.8 10.912,17.8 C8.482,17.8 6.512,15.83 6.512,13.4 C6.512,11.968 7.196,10.696 8.255,9.893 L6,5 L8.769,5 L10.617,9.01 C10.715,9.004 10.813,9 10.912,9 C11.012,9 11.11,9.004 11.207,9.01 L13.056,5 L15.825,5 L13.569,9.893 Z M10.912,16.6 C12.68,16.6 14.112,15.167 14.112,13.4 C14.112,11.633 12.68,10.2 10.912,10.2 C9.145,10.2 7.712,11.633 7.712,13.4 C7.712,15.167 9.145,16.6 10.912,16.6 Z"
          />
          <path
            fill={this.fill}
            d="M10.912,11.4 C12.017,11.4 12.912,12.295 12.912,13.4 C12.912,14.505 12.017,15.4 10.912,15.4 C9.808,15.4 8.912,14.505 8.912,13.4 C8.912,12.295 9.808,11.4 10.912,11.4 Z M13.569,9.893 C14.628,10.696 15.312,11.968 15.312,13.4 C15.312,15.83 13.342,17.8 10.912,17.8 C8.482,17.8 6.512,15.83 6.512,13.4 C6.512,11.968 7.196,10.696 8.255,9.893 L6,5 L8.769,5 L10.617,9.01 C10.715,9.004 10.813,9 10.912,9 C11.012,9 11.11,9.004 11.207,9.01 L13.056,5 L15.825,5 L13.569,9.893 Z M10.912,16.6 C12.68,16.6 14.112,15.167 14.112,13.4 C14.112,11.633 12.68,10.2 10.912,10.2 C9.145,10.2 7.712,11.633 7.712,13.4 C7.712,15.167 9.145,16.6 10.912,16.6 Z"
          />
        </g>
      </svg>
    );
  }
}
