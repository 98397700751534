import React, { Component } from "react";
import PropTypes from "prop-types";
import { defaultProps, setDisplayName, withPropsOnChange } from "recompose";
import { flow, isEmpty, overEvery, overSome, property } from "lodash";
import { every } from "lodash/fp";

import { cardPropsShape } from "Components/Layout";
import { Chart } from "Components/Chart";
import { wrapGraphQL } from "HOC/graphql";

import DataCard from "./DataCard";
import { barChartPropsShape } from "./shapes";

const hasNoLabelsOrCategories = overEvery(
  flow(
    property("options.labels"),
    isEmpty
  ),
  flow(
    property("options.xaxis.categories"),
    isEmpty
  )
);

const hasNoSeriesData = overSome(
  flow(
    property("series"),
    overSome(
      every(
        flow(
          property("data"),
          every(value => value === 0)
        )
      )
    )
  )
);

const hasNoData = overSome(hasNoLabelsOrCategories, hasNoSeriesData);

const checkData = withPropsOnChange(["chartProps"], ({ chartProps }) => ({
  hasNoData: hasNoData(chartProps)
}));

export default class BarGraph extends Component {
  static displayName = "Data.BarGraph";

  static propTypes = {
    cardProps: cardPropsShape,
    chartProps: barChartPropsShape,
    hasNoData: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
  };

  static defaultProps = {
    hasNoData: false,
    isLoading: false
  };

  render() {
    return (
      <DataCard {...this.props} absoluteIfData>
        {() => <Chart {...this.props.chartProps} type="bar" />}
      </DataCard>
    );
  }

  static extendAs(displayName, cardProps = {}, ...hocs) {
    return wrapGraphQL(
      setDisplayName(displayName),
      ...hocs,
      defaultProps({
        cardProps
      }),
      checkData
    )(BarGraph);
  }
}
