import * as t from "graphql-ast-types-browser";

import { flow, initial, last, over, reduceRight, toPath } from "lodash";

import { print } from "graphql/language";

const splitPath = flow(
  toPath,
  over(initial, last)
);

const variable = name => t.variable(t.name(name));
const namedType = name => t.namedType(t.name(name));

export const summaryFields = t.selectionSet([
  t.field(t.name("chartScale")),
  t.field(t.name("csvTag")),
  t.field(t.name("rowCount")),
  t.field(
    t.name("rows"),
    null,
    null,
    null,
    t.selectionSet([
      t.field(t.name("id")),
      t.field(t.name("datetime")),
      t.field(t.name("date")),
      t.field(t.name("label")),
      t.field(t.name("activitiesCount")),
      t.field(t.name("usersCount"))
    ])
  )
]);

function parentizeChild(childField, fieldName) {
  return t.field(
    t.name(fieldName),
    null,
    null,
    null,
    t.selectionSet([childField])
  );
}

/**
 * @param {string} queryName
 * @param {string} path
 * @return {object}
 */
export function buildAutoQuery(queryName, path, { scopeType = null } = {}) {
  const [fieldParentNames, baseFieldName] = splitPath(path);

  const baseFieldArgs = [
    t.argument(t.name("filters"), variable("filters"))
  ];

  const variableDefinitions = [
    t.variableDefinition(variable("filters"), namedType("Filters"))
  ];

  if (scopeType) {
    baseFieldArgs.push(
      t.argument(t.name("scope"), variable("scope"))
    );

    variableDefinitions.push(
      t.variableDefinition(variable("scope"), namedType(scopeType))
    );
  }

  const baseField = t.field(
    t.name(baseFieldName),
    null,
    baseFieldArgs,
    null,
    summaryFields
  );

  const fieldTree = reduceRight(fieldParentNames, parentizeChild, baseField);

  const ast = t.document([
    t.operationDefinition(
      "query",
      t.selectionSet([fieldTree]),
      t.name(queryName),
      variableDefinitions
    )
  ]);

  if (process.env.NODE_ENV === "development" && process.env.DEBUG_GRAPHQL) {
    console.info("GENERATING GRAPHQL QUERY %s :: %s", queryName, path);
    console.info(print(ast));
  }

  return ast;
}
