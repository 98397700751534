import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";

import { NamedRouteContext } from "Routes";

export default class NamedLink extends Component {
  static displayName = "Routing.NamedLink";

  static contextType = NamedRouteContext;

  static defaultProps = {
    isNav: false
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    isNav: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    params: PropTypes.object,
    className: PropTypes.string
  };

  render() {
    const { children, isNav, name, params, className } = this.props;

    const route = this.context.namedRoutes.get(name);

    const LinkComponent = isNav ? NavLink : Link;

    const linkProps = {
      children: children,
      to: route.generate(params),
      className: className
    };

    if (isNav) {
      linkProps.exact = true;
      linkProps.strict = true;
    }

    return <LinkComponent {...linkProps} />;
  }
}
