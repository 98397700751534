import React, { Component } from "react";
import { get } from "lodash";

import { LanguageFilter } from "Components/Filters";
import { PieGraph, Table } from "Components/Data";
import {
  graphqlPieGraph,
  graphql,
  gql
} from "HOC/graphql";

const ByTypeChart = PieGraph.extendAs(
  "Behavior.Searches.ByTypeChart",
  {
    size: "half",
    title: "Searches by Type"
  },
  graphqlPieGraph(
    "withSearchesByType",
    gql`
    query withSearchesByType($filters: Filters) {
      searches {
        byType(filters: $filters) {
          csvTag

          rows {
            id
            type
            activitiesCount
          }
        }
      }
    }
    `,
    {
      wrangle: {
        path: "searches.byType",
        labelField: "type",
        valueField: "activitiesCount"
      }
    }
  )
);

const ByContentTypeChart = PieGraph.extendAs(
  "Behavior.Searches.ByContentTypeChart",
  {
    size: "half",
    title: "Clicks by Content Type"
  },
  graphqlPieGraph(
    "withSearchesByContentType",
    gql`
    query withSearchesByContentType($filters: Filters) {
      searches {
        byContentType(filters: $filters) {
          csvTag

          rows {
            id
            contentType
            activitiesCount
          }
        }
      }
    }
    `,
    {
      wrangle: {
        path: "searches.byContentType",
        labelField: "contentType",
        valueField: "activitiesCount"
      }
    }
  )
);

export const PopularTermsTable = Table.extendAs(
  "Behavior.Searches.PopularTermsTable",
  {
    size: "full",
    title: "Popular Search Terms"
  },
  graphql(
    gql`
    query withPopularSearchTerms($filters: Filters) {
      searches {
        popular(filters: $filters) {
          csvTag

          rows {
            id
            term
            maxSearchResultCount
            activitiesCount
          }
        }
      }
    }
    `,
    {
      name: "withPopularSearchTerms",
      options: ({ localFilters: filters } = {}) => ({
        fetchPolicy: "cache-and-network",
        variables: { filters }
      }),
      props: (props) => ({
        csvTag: get(props, "withPopularSearchTerms.searches.popular.csvTag", null),
        isLoading: get(props, "withPopularSearchTerms.loading", true),
        tableProps: {
          columns: [
            {
              Header: "Term",
              accessor: "term"
            },
            {
              Header: "Search Results",
              accessor: "maxSearchResultCount"
            },
            {
              Header: "# of Searches",
              accessor: "activitiesCount"
            }
          ],
          data: get(props, "withPopularSearchTerms.searches.popular.rows", [])
        }
      })
    }
  )
);

export const ResolvedTermsTable = Table.extendAs(
  "Behavior.Searches.ResolvedTermsTable",
  {
    size: "half",
    title: "Resolved Search Terms"
  },
  graphql(
    gql`
    query withResolvedSearchTerms($filters: Filters) {
      searches {
        resolved(filters: $filters) {
          csvTag

          rows {
            id
            term
            clickPercentage
            searchCount
          }
        }
      }
    }
    `,
    {
      name: "withResolvedSearchTerms",
      options: ({ localFilters: filters } = {}) => ({
        fetchPolicy: "cache-and-network",
        variables: { filters }
      }),
      props: (props) => ({
        csvTag: get(props, "withResolvedSearchTerms.searches.resolved.csvTag", null),
        isLoading: get(props, "withResolvedSearchTerms.loading", true),
        tableProps: {
          columns: [
            {
              Header: "Term",
              accessor: "term"
            },
            {
              Header: "Frequency",
              accessor: "searchCount",
            },
            {
              Header: "Click-through %",
              accessor: "clickPercentage",
              sortMethod: (a, b) => {
                a = parseFloat(a);
                b = parseFloat(b);

                if (a === b) {
                  return 0
                } else {
                  return a > b ? 1 : -1;
                }
              }
            },
          ],
          data: get(props, "withResolvedSearchTerms.searches.resolved.rows", [])
        }
      })
    }
  )
);

export const UnresolvedTermsTable = Table.extendAs(
  "Behavior.Searches.UnresolvedTermsTable",
  {
    size: "half",
    title: "Unresolved Search Terms"
  },
  graphql(
    gql`
    query withUnresolvedSearchTerms($filters: Filters) {
      searches {
        unresolved(filters: $filters) {
          csvTag

          rows {
            id
            term
            scope
            searchCount
          }
        }
      }
    }
    `,
    {
      name: "withUnresolvedSearchTerms",
      options: ({ localFilters: filters } = {}) => ({
        fetchPolicy: "cache-and-network",
        variables: { filters }
      }),
      props: (props) => ({
        csvTag: get(props, "withUnresolvedSearchTerms.searches.unresolved.csvTag", null),
        isLoading: get(props, "withUnresolvedSearchTerms.loading", true),
        tableProps: {
          columns: [
            {
              Header: "Term",
              accessor: "term"
            },
            {
              Header: "Search Scope",
              accessor: "scope"
            },
            {
              Header: "Frequency",
              accessor: "searchCount"
            }
          ],
          data: get(props, "withUnresolvedSearchTerms.searches.unresolved.rows", [])
        }
      })
    }
  )
);

export default class BehaviorSearches extends Component {
  static displayName = "Behavior.Searches";

  render() {
    return (
      <React.Fragment>
        <LanguageFilter />
        <ByTypeChart />
        <ByContentTypeChart />
        <PopularTermsTable />
        <ResolvedTermsTable />
        <UnresolvedTermsTable />
      </React.Fragment>
    );
  }
}
