import React, { Component } from "react";
import PropTypes from "prop-types";
import { cloneDeep, get, isArray, isPlainObject, merge, mergeWith } from "lodash";
import Chart from "react-apexcharts";
import { compose, defaultProps, withPropsOnChange } from "recompose";

import defaultOptions from "./defaultOptions";

import styles from "./Chart.module.scss";

export class ChartWrapper extends Component {
  static displayName = "Chart.Wrapper";

  static propTypes = {
    apexType: PropTypes.string,
    type: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  static defaultProps = {
    width: "100%"
  };

  render() {
    const { apexType, height, options, series, width } = this.props;

    return (
      <div className={styles.wrapper}>
        <Chart
          height={height}
          options={options}
          type={apexType}
          series={series}
          width={width}
        />
      </div>
    );
  }
}

function handleMergingComplexOptions(objValue, srcValue, key, object, source, stack) {
  if (key === "yaxis" && stack.size === 0 && isArray(srcValue) && isPlainObject(objValue)) {
    const sharedYAxisValues = cloneDeep(objValue);

    return srcValue.map(function(yAxisValue) {
      return merge({}, sharedYAxisValues, yAxisValue);
    });
  }

  return undefined;
}

function mergeNewOptions(type, options = {}) {
  const optionsForType = type ? get(defaultOptions, type, {}) : {};

  const preparedOptions = merge(
    {},
    cloneDeep(defaultOptions.default),
    cloneDeep(optionsForType)
  );

  return mergeWith(preparedOptions, options || {}, handleMergingComplexOptions);
}

function toApexType(type) {
  if (type === "pie") {
    return "donut";
  } else {
    return type;
  }
}

function normalizeHeight(type, height) {
  if (height) {
    return height;
  } else if (type === "bar") {
    return 340;
  } else {
    return 265;
  }
}

export default compose(
  defaultProps(() => ({ options: {} })),
  withPropsOnChange(["height", "options", "type"], ({ height, options, type }) => {
    const newOptions = mergeNewOptions(type, options);

    const apexType = toApexType(type);

    return { apexType, height: normalizeHeight(type, height), options: newOptions };
  })
)(ChartWrapper);
