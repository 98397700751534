export default {
    ApolloHTTP: {
        uri: "https://6anixx751l.execute-api.us-west-2.amazonaws.com/prd/graphql",
    },
    Auth0: {
        domain:       "ciclabs.auth0.com",
        audience:     "https://ciclabs.auth0.com/userinfo",
        clientID:     "Ou23c83GRiyF1ks58Obkw70OxLdsqHMt",
        responseType: "token id_token",
        scope:        "openid profile"
    },
Google: {
mapsApiKey:	  "AIzaSyBzjSGQ2XjCFVB6I7w_dJzPt_Xyf9HA3q4"
}
};
