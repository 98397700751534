import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class ChartBar1 extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 22;
  }

  get defaultWidth() {
    return 22;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 22 22";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            d="M5,16.399 L5,14.799 L17,14.799 L17,16.399 L5,16.399 Z M6.6,14 L6.6,11.6 L8.2,11.6 L8.2,14 L6.6,14 Z M9,14 L9,8.4 L10.6,8.4 L10.6,14 L9,14 Z M11.4,13.999 L11.4,10.799 L13,10.799 L13,13.999 L11.4,13.999 Z M13.8,14 L13.8,6 L15.4,6 L15.4,14 L13.8,14 Z"
          />
          <path
            fill={this.fill}
            d="M5,16.399 L5,14.799 L17,14.799 L17,16.399 L5,16.399 Z M6.6,14 L6.6,11.6 L8.2,11.6 L8.2,14 L6.6,14 Z M9,14 L9,8.4 L10.6,8.4 L10.6,14 L9,14 Z M11.4,13.999 L11.4,10.799 L13,10.799 L13,13.999 L11.4,13.999 Z M13.8,14 L13.8,6 L15.4,6 L15.4,14 L13.8,14 Z"
          />
        </g>
      </svg>
    );
  }
}
