import PropTypes from "prop-types";

import { range } from "lodash";

import moment from "./moment";

import DateBoundaries from "./DateBoundaries";
import DateRange from "./DateRange";

export { earliestDate, latestDate } from "./clamps";
export { default as DateRange } from "./DateRange";
export { default as moment, isValidMoment, tryMoment } from "./moment";

export const DateRangeProp = PropTypes.instanceOf(DateRange);

export const PresetItemShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  dateRange: DateRangeProp.isRequired
});

export const PresetGroupShape = PropTypes.arrayOf(PresetItemShape);

export const PresetListShape = PropTypes.arrayOf(PresetGroupShape);

export const MINIMUM_DATE_STRING = "2018-06-20";
export const MINIMUM_DATE = moment.utc(MINIMUM_DATE_STRING).toDate();
export const MAXIMUM_DATE = moment.utc().endOf("day").toDate();

export const BOUNDARIES = new DateBoundaries(MINIMUM_DATE, MAXIMUM_DATE);

if (process.env.NODE_ENV === "development") {
  window.BOUNDARIES = BOUNDARIES;
}

const { clampDate, getInitialDateRange, isInvalidCalendarDay, isValidCalendarDay, toDateRange } = BOUNDARIES;

export { clampDate, getInitialDateRange, isInvalidCalendarDay, isValidCalendarDay, toDateRange };

export function createPresetDateOrigin(unit, { offset = 0 } = {}) {
  const d = moment();

  if (offset < 0) {
    d.subtract(Math.abs(offset), unit);
  } else if (offset > 0) {
    d.add(offset, unit);
  }

  return d;
}

export function createPresetDateRange(unit, options = {}) {
  const startTime = clampDate(
    createPresetDateOrigin(unit, options).startOf(unit)
  );

  const untilTime = clampDate(
    createPresetDateOrigin(unit, options).endOf(unit)
  );

  return new DateRange(startTime, untilTime);
}

export function createPresetRange(label, unit, options = {}) {
  const dateRange = createPresetDateRange(unit, options);

  return {
    label,
    dateRange
  };
}

export function createPresetQuarterRange(offset) {
  const dateRange = createPresetDateRange("quarter", { offset });

  const label = dateRange.quarterLabel;

  return { label, dateRange };
}

export function createPresetQuarterRanges() {
  const currentQuarter = moment().startOf("quarter");
  const oldestQuarter = moment(MINIMUM_DATE).startOf("quarter");

  const numberOfQuarters = currentQuarter.diff(oldestQuarter, "quarters") + 1;

  return range(0, numberOfQuarters).map(offset =>
    createPresetQuarterRange(-offset)
  );
}

export function pad2(value) {
  return `${value}`.padStart(2, 0);
}

/**
 * @param {string / Moment.Duration} value
 * @return {?string}
 */
export function humanizeDuration(value) {
  const duration = moment.duration(value);

  if (!duration.isValid() || duration.asSeconds() === 0) {
    return null;
  }

  let humanized = "";

  const dayCount = Math.floor(duration.asDays());
  const hours   = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (dayCount > 0) {
    humanized += `${dayCount} ${dayCount === 1 ? "day" : "days"} `;
  }

  humanized += [hours, minutes, seconds].map(pad2).join(":");

  return humanized;
}

export const PRESETS = [
  [
    createPresetRange("This Week", "week"),
    createPresetRange("This Month", "month"),
    createPresetRange("This Year", "year")
  ],
  [
    createPresetRange("Last Week", "week", { offset: -1 }),
    createPresetRange("Last Month", "month", { offset: -1 }),
    createPresetRange("Last Year", "year", { offset: -1 })
  ],
  createPresetQuarterRanges(),
  [
    {
      label: "All Time",
      dateRange: getInitialDateRange()
    }
  ]
];
