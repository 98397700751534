import { createContext } from "react";
import PropTypes from "prop-types";

import { noop } from "lodash";

import { PresetListShape } from "Utilities/Temporal";

export const calendarContextShape = PropTypes.shape({
  clampDate: PropTypes.func.isRequired,
  getInitialDateRange: PropTypes.func.isRequired,
  isInvalidCalendarDay: PropTypes.func.isRequired,
  isValidCalendarDay: PropTypes.func.isRequired,
  minimumDate: PropTypes.instanceOf(Date),
  minimumDay: PropTypes.string,
  maximumDate: PropTypes.instanceOf(Date),
  maximumDay: PropTypes.string,
  presets: PresetListShape.isRequired,
  toDateRange: PropTypes.func.isRequired,
});

const CalendarContext = createContext({
  clampDate: noop,
  getInitialDateRange: noop,
  isInvalidCalendarDay: noop,
  isValidCalendarDay: noop,
  minimumDate: null,
  minimumDay: null,
  maximumDate: null,
  maximumDay: null,
  presets: [],
  toDateRange: noop,
});

CalendarContext.displayName = "Application.CalendarContext";

export default CalendarContext;
