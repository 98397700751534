import React, { Component } from "react";
import PropTypes from "prop-types";
import { NamedLink } from "Components/Routing";

import { IconComposer } from "Components/Utility";

import styles from "./Header.module.scss";

export default class CardRouteHeader extends Component {
  static displayName = "Card.RouteHeader";

  static propTypes = {
    currentRouteTitle: PropTypes.string,
    routeLinkName: PropTypes.string.isRequired,
    routeLinkText: PropTypes.string
  };

  get title() {
    return this.props.currentRouteTitle;
  }

  get linkName() {
    return this.props.routeLinkName;
  }

  get linkText() {
    return this.props.routeLinkText || "Back";
  }

  render() {
    return (
      <header className={styles.container}>
        <div className={styles.inner}>
          {this.title && <h1 className={styles.heading}>{this.title}</h1>}
          <div className={styles.linkContainer}>
            <NamedLink name={this.linkName} className={styles.link}>
              <IconComposer
                icon="ArrowLong16"
                size="default"
                iconClass={styles.linkIcon}
              />
              <span className={styles.linkText}>{this.linkText}</span>
            </NamedLink>
          </div>
        </div>
      </header>
    );
  }
}
