import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { compose, withHandlers } from "recompose";
import classnames from "classnames";

import CalendarContext from "Components/Application/CalendarContext";
import { DateRangeProp } from "Utilities/Temporal";
import makePropsChanged, { makePropsAreSame } from "Utilities/make-props-changed";

import styles from "./PresetList.module.scss";

const isDifferentRange = makePropsChanged("from", "to");
const isSameRange = makePropsAreSame("from", "to");

export class BaseItem extends PureComponent {
  static displayName = "Utility.DateRangeFilter.PresetList.Item";

  static propTypes = {
    activeDateRange: DateRangeProp.isRequired,    
    dateRange: DateRangeProp.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    updateDateRange: PropTypes.func.isRequired
  };

  get presetIsActive() {
    const { dateRange, activeDateRange } = this.props;

    return isSameRange(dateRange, activeDateRange)
  }

  get wrapperClass() {
    return classnames(styles.item, {
      [styles.active]: this.presetIsActive
    });
  }

  componentDidUpdate(previous) {
    const current = this.props;

    const sameActiveRange = isSameRange(previous.activeDateRange, current.activeDateRange);

    const differentCurrentRange = isDifferentRange(previous.dateRange, current.dateRange);

    if (sameActiveRange && differentCurrentRange) {
      this.props.onClick();
    }
  }

  render() {
    const { label, onClick } = this.props;

    return (
      <li className={this.wrapperClass} onClick={onClick}>
        {label}
      </li>
    );
  }
}

const Item = compose(
  withHandlers({
    onClick: ({ dateRange, updateDateRange }) => () =>
      updateDateRange(dateRange.filters)
  })
)(BaseItem);

export class Group extends PureComponent {
  static displayName = "Utility.DateRangeFilter.PresetList.Group";

  static propTypes = {
    activeDateRange: DateRangeProp.isRequired,
    presets: PropTypes.arrayOf(
      PropTypes.shape({
        dateRange: DateRangeProp.isRequired,
        label: PropTypes.string.isRequired
      })
    ),
    updateDateRange: PropTypes.func.isRequired
  };

  render() {
    const { presets, updateDateRange, activeDateRange } = this.props;

    return (
      <ul className={styles.group}>
        {presets.map((preset, index) => (
          <Item
            activeDateRange={activeDateRange}
            key={index}
            updateDateRange={updateDateRange}
            {...preset}
          />
        ))}
      </ul>
    );
  }
}

export default class PresetList extends PureComponent {
  static displayName = "Utility.DateRangeFilter.PresetList";

  static propTypes = {
    activeDateRange: DateRangeProp.isRequired,
    updateDateRange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    updateDateRange: noop
  };

  renderGroup(presets, index) {
    const { activeDateRange, updateDateRange } = this.props;

    return (
      <Group
        activeDateRange={activeDateRange}
        key={index}
        presets={presets}
        updateDateRange={updateDateRange}
      />
    );
  }

  renderList = ({ presets }) => {
    return presets.map(this.renderGroup, this);
  };

  render() {
    return (
      <div className={styles.list}>
        <CalendarContext.Consumer>
          {this.renderList}
        </CalendarContext.Consumer>
      </div>
    );
  }
}
