import moment from "moment";

import { extendMoment } from "moment-range";

extendMoment(moment);

export default moment;

export function isValidMoment(value) {
  return moment.isMoment(value) && value.isValid();
}

export function tryMoment(newValue, defaultValue = null) {
  if (newValue) {
    const m = moment.utc(newValue);

    if (m.isValid()) {
      return m;
    }
  }

  return defaultValue;
}

if (process.env.NODE_ENV === "development") {
  window.moment = moment;
  window.tryMoment = moment;
}
