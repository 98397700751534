import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Performance extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 32;
  }

  get defaultWidth() {
    return 32;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 32 32";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M22.5,14.9370709 C22.9478037,14.9370709 23.1698073,15.4805034 22.850094,15.7940502 L13.100094,25.3559985 C12.7199534,25.7288072 12.0979911,25.3282269 12.2801833,24.8279278 L14.2042598,19.5444201 C14.298751,19.2849475 14.5856954,19.1512037 14.845168,19.2456949 C15.1046406,19.3401862 15.2383844,19.6271305 15.1438932,19.8866031 L13.9744644,23.0978507 L21.2760728,15.9370709 L16.5418437,15.9370709 C16.1947668,15.9370709 15.9532665,15.5921168 16.0720235,15.2659893 L18.0256119,9.90109382 L10.7239272,17.0619483 L15.4751553,17.0619483 C15.7512977,17.0619483 15.9751553,17.285806 15.9751553,17.5619483 C15.9751553,17.8380907 15.7512977,18.0619483 15.4751553,18.0619483 L9.5,18.0619483 C9.05219631,18.0619483 8.83019274,17.5185159 9.14990596,17.2049691 L18.899906,7.64302078 C19.2800425,7.27021605 19.902,7.67078409 19.7198202,8.17108168 L17.2560336,14.9370709 L22.5,14.9370709 Z"
          />
          <path
            fill={this.fill}
            fillRule="nonzero"
            d="M22.5,14.9370709 C22.9478037,14.9370709 23.1698073,15.4805034 22.850094,15.7940502 L13.100094,25.3559985 C12.7199534,25.7288072 12.0979911,25.3282269 12.2801833,24.8279278 L14.2042598,19.5444201 C14.298751,19.2849475 14.5856954,19.1512037 14.845168,19.2456949 C15.1046406,19.3401862 15.2383844,19.6271305 15.1438932,19.8866031 L13.9744644,23.0978507 L21.2760728,15.9370709 L16.5418437,15.9370709 C16.1947668,15.9370709 15.9532665,15.5921168 16.0720235,15.2659893 L18.0256119,9.90109382 L10.7239272,17.0619483 L15.4751553,17.0619483 C15.7512977,17.0619483 15.9751553,17.285806 15.9751553,17.5619483 C15.9751553,17.8380907 15.7512977,18.0619483 15.4751553,18.0619483 L9.5,18.0619483 C9.05219631,18.0619483 8.83019274,17.5185159 9.14990596,17.2049691 L18.899906,7.64302078 C19.2800425,7.27021605 19.902,7.67078409 19.7198202,8.17108168 L17.2560336,14.9370709 L22.5,14.9370709 Z"
          />
        </g>
      </svg>
    );
  }
}
