import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Laboratory extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 22;
  }

  get defaultWidth() {
    return 22;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 22 22";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            d="M16.5037716,14.556 C17.4087716,16.065 16.7097716,17.3 14.9497716,17.3 L6.94977161,17.3 C5.18977161,17.3 4.49077161,16.065 5.39677161,14.556 L9.34977161,7.968 L9.34977161,5.3 L8.94977161,5.3 C8.72977161,5.3 8.54977161,5.12 8.54977161,4.9 C8.54977161,4.68 8.72977161,4.5 8.94977161,4.5 L12.9497716,4.5 C13.1697716,4.5 13.3497716,4.68 13.3497716,4.9 C13.3497716,5.12 13.1697716,5.3 12.9497716,5.3 L12.5497716,5.3 L12.5497716,7.968 L16.5037716,14.556 Z M7.56277161,12.5 L14.3367716,12.5 L11.7497716,8.188 L11.7497716,5.3 L10.1497716,5.3 L10.1497716,8.188 L7.56277161,12.5 Z"
          />
          <path
            fill={this.fill}
            d="M16.5037716,14.556 C17.4087716,16.065 16.7097716,17.3 14.9497716,17.3 L6.94977161,17.3 C5.18977161,17.3 4.49077161,16.065 5.39677161,14.556 L9.34977161,7.968 L9.34977161,5.3 L8.94977161,5.3 C8.72977161,5.3 8.54977161,5.12 8.54977161,4.9 C8.54977161,4.68 8.72977161,4.5 8.94977161,4.5 L12.9497716,4.5 C13.1697716,4.5 13.3497716,4.68 13.3497716,4.9 C13.3497716,5.12 13.1697716,5.3 12.9497716,5.3 L12.5497716,5.3 L12.5497716,7.968 L16.5037716,14.556 Z M7.56277161,12.5 L14.3367716,12.5 L11.7497716,8.188 L11.7497716,5.3 L10.1497716,5.3 L10.1497716,8.188 L7.56277161,12.5 Z"
          />
        </g>
      </svg>
    );
  }
}
