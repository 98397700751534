import { graphql } from "react-apollo";
import { withRouter } from "react-router";
import { compose } from "recompose";
import { at, flow, get, identity, isEmpty, merge, over, toPath, unzip } from "lodash";
import { map } from "lodash/fp";

import { buildAutoQuery, wrangleIntoSeries } from "ActivitySummaries";
import { isNotEmpty } from "Utilities";

import withLocalFilters from "./withLocalFilters";

export { graphql } from "react-apollo";
export { compose } from "recompose";
export { default as gql } from "graphql-tag";

export function wrapGraphQL(...hocs) {
  return compose(
    withLocalFilters,
    withRouter,
    ...hocs
  );
}

const extractFilters = ({ localFilters: filters }) => ({ filters });

function createVariableExtractor({ addScope }) {
  const steps = [extractFilters];

  if (typeof addScope === "function") {
    steps.push(flow(
      addScope,
      (scope) => (isEmpty(scope) ? {} : { scope })
    ))
  }

  const extractor = over(steps);

  return (props) => merge({}, ...extractor(props));
}

export function graphqlActivitySummaries(name, query, { addScope, wrangle = {} } = {}) {
  const extractVariables = createVariableExtractor({ addScope });

  const queryHoc = graphql(query, {
    name,
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: extractVariables(props)
    }),
    props: wrangleIntoSeries(name, wrangle)
  });

  return compose(queryHoc);
}

export function graphqlAutoActivitySummaries(
  name,
  { addScope, path, scopeType, seriesDefinitions = [], activitiesLabel, activitiesYAxis, usersLabel, usersYAxis }
) {
  const query = buildAutoQuery(name, path, { scopeType });

  if (isEmpty(seriesDefinitions)) {
    if (!Array.isArray(seriesDefinitions)) {
      seriesDefinitions = [];
    }

    if (isNotEmpty(activitiesLabel)) {
      const definition = {
        label: activitiesLabel,
        field: "activitiesCount"
      };

      if (isNotEmpty(activitiesYAxis)) {
        definition.yaxis = activitiesYAxis;
      }

      seriesDefinitions.push(definition);
    }

    if (isNotEmpty(usersLabel)) {
      const definition = { label: usersLabel, field: "usersCount" };

      if (isNotEmpty(usersYAxis)) {
        definition.yaxis = usersYAxis;
      }

      seriesDefinitions.push(definition);
    }
  }

  const options = {
    addScope,
    wrangle: {
      path,
      seriesDefinitions,
      xAxisField: "label"
    }
  };

  return graphqlActivitySummaries(name, query, options);
}

export function graphqlSimpleBarGraph(
  name,
  query,
  { 
    modifyVariables = identity,
    wrangle: { seriesTitle = "activities", path, labelField, valueField }
  } = {
    wrangle: {}
  }
) {
  if (!labelField) {
    throw new Error("Must provide wrangle.labelField");
  }

  if (!valueField) {
    throw new Error("Must provide wrangle.valueField");
  }

  if (!path) {
    throw new Error("Must provide path to rows");
  }

  const loadingPath = `${name}.loading`;

  const rowPath = [name, ...toPath(path), "rows"].join(".");
  const csvTagPath = [name, ...toPath(path), "csvTag"].join(".");

  const getRows = (props = {}) => get(props, rowPath, []);

  const transformIntoChartProps = ([categories, data]) => ({
    options: { xaxis: { categories } },
    series: [{
      name: seriesTitle,
      data
    }]
  });

  const extractProps = map(obj => at(obj, labelField, valueField));

  const toBarGraphProps = flow(
    getRows,
    extractProps,
    unzip,
    transformIntoChartProps
  );

  const queryHoc = graphql(query, {
    name,
    options: ({ localFilters: filters } = {}) => ({
      fetchPolicy: "cache-and-network",
      variables: modifyVariables({ filters })
    }),
    props: (props = {}) => ({
      chartProps: toBarGraphProps(props),
      csvTag:    get(props, csvTagPath, null),
      isLoading: get(props, loadingPath, false)
    })
  });

  return queryHoc;
}

export function graphqlPieGraph(
  name,
  query,
  { addScope, wrangle: { path, labelField, valueField } } = { wrangle: {} }
) {
  if (!labelField) {
    throw new Error("Must provide wrangle.labelField");
  }

  if (!valueField) {
    throw new Error("Must provide wrangle.valueField");
  }

  if (!path) {
    throw new Error("Must provide path to rows");
  }

  const extractVariables = createVariableExtractor({ addScope });

  const loadingPath = `${name}.loading`;

  const rowPath = [name, ...toPath(path), "rows"].join(".");
  const csvTagPath = [name, ...toPath(path), "csvTag"].join(".");

  const getRows = (props = {}) => get(props, rowPath, []);

  const transformIntoChartProps = ([labels, series]) => ({
    options: { labels },
    series
  });

  const extractProps = map(obj => at(obj, labelField, valueField));

  const toPieGraphProps = flow(
    getRows,
    extractProps,
    unzip,
    transformIntoChartProps
  );

  const queryHoc = graphql(query, {
    name,
    options: (props = {}) => ({
      fetchPolicy: "cache-and-network",
      variables: extractVariables(props)
    }),
    props: (props = {}) => ({
      chartProps: toPieGraphProps(props),
      csvTag:    get(props, csvTagPath, null),
      isLoading: get(props, loadingPath, false),
    })
  });

  return compose(queryHoc);
}
