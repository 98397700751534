import { compose, fromRenderProps } from "recompose";
import { identity } from "lodash";
import { Context as AuthContext } from "Auth";

import named from "./named";

const withAuth = compose(
  named("withAuth"),
  fromRenderProps(AuthContext.Consumer, identity)
);

export default withAuth;
