import { branch, compose, renderNothing } from "recompose";

import { negate, overSome, property } from "lodash";

import named from "./named";
import withAuth from "./withAuth";

const checkIfUnauthenticated = negate(property("isAuthenticated"));
const checkIfAuthenticating = property("isAuthenticating");

const shouldHide = overSome(checkIfUnauthenticated, checkIfAuthenticating);

const enhance = compose(
  named("hideIfUnauthenticated"),
  withAuth,
  branch(shouldHide, renderNothing)
);

export default function hideIfUnauthenticated(Component) {
  return enhance(Component);
}
