import React, { Component } from "react";
import PropTypes from "prop-types";
import { branch, compose, defaultProps, setDisplayName, withHandlers, withPropsOnChange } from "recompose";
import { conforms, isNil, toSafeInteger } from "lodash";
import { has } from "lodash/fp";
import classNames from "classnames";

import { cardPropsShape } from "Components/Layout";
import { wrapGraphQL } from "HOC/graphql";
import { formatNumber, looksNumeric } from "Utilities";

import DataCard from "./DataCard";
import defaultOptions from "../Chart/defaultOptions";
import styles from "./Count.module.scss";

const { default: { colors: COLORS } } = defaultOptions;

const withColors = compose(
  defaultProps({ defaultColor: "#3769AC" }),
  withPropsOnChange(
    ["defaultColor"],
    ({ defaultColor }) => ({ colors: [defaultColor, ...COLORS] })
  )
);

const hasCountAndTotal = conforms({
  count: looksNumeric,
  total: looksNumeric
});

const formatCountAndTotal = withPropsOnChange(["count", "total"], ({ count, total }) => {
  count = toSafeInteger(count);
  total = toSafeInteger(total);

  const percentage = (100.0 * count) / total;

  const label = `${percentage.toFixed(2)}%`;

  const hasNoData = percentage < 1.0;

  return { count, total, label, percentage, hasNoData };
});

const buildNumericRenderCount = withHandlers({
  renderCount: ({ colors, count, label, total }) => () => (
    <div className={styles.wrapper}>
      <figure className={classNames(styles.figure, styles.figureLarge)}>
        <h3
          className={classNames(styles.number, styles.numberLarge)}
          style={{ color: colors[0] }}
        >
          {label}
        </h3>
        <figcaption className={styles.desc}>{formatNumber(count)} / {formatNumber(total)}</figcaption>
      </figure>
    </div>
  )
});

const hasValue = has("value");

const formatValue = withPropsOnChange(
  ["value"],
  ({ value }) => ({
    hasNoData: isNil(value)
  })
);

const buildValueRenderCount = withHandlers({
  renderCount: ({ colors, value }) => () => (
    <div className={styles.wrapper}>
      <figure className={classNames(styles.figure, styles.figureLarge)}>
        <h3
          className={classNames(styles.number, styles.numberLarge)}
          style={{ color: colors[0] }}
        >
          {value}
        </h3>
      </figure>
    </div>
  )
});

const handleCountAndTotal = branch(
  hasCountAndTotal,
  compose(
    formatCountAndTotal,
    buildNumericRenderCount
  ),
  branch(
    hasValue,
    compose(
      formatValue,
      buildValueRenderCount
    )
  )
);

function renderNothing() {
  return null;
}

export default class Count extends Component {
  static displayName = "Data.Count";

  static propTypes = {
    cardProps: cardPropsShape,
    hasNoData: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    renderCount: PropTypes.func.isRequired,
  };

  static defaultProps = {
    hasNoData: true,
    isLoading: true,
    renderCount: renderNothing
  };

  render() {
    const { renderCount } = this.props;

    return <DataCard {...this.props}>{renderCount}</DataCard>;
  }

  static extendAs(displayName, cardProps = {}, ...hocs) {
    return wrapGraphQL(
      setDisplayName(displayName),
      ...hocs,
      defaultProps({
        cardProps
      }),
      withColors,
      handleCountAndTotal,
    )(Count);
  }
}
