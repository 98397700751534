import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { compose, withHandlers } from "recompose";
import { graphql } from "react-apollo";
import { noop } from "lodash";

import { clearGlobalFilters, resetDateRange } from "Queries";

import styles from "./NoResults.module.scss";

export class FilterOption extends PureComponent {
  static displayName = "Data.NoResults.FilterOption";

  static propTypes = {
    children: PropTypes.node,
    handler: PropTypes.func.isRequired
  };

  static defaultProps = {
    handler: noop
  };

  render() {
    const { handler, children } = this.props;

    return (
      <span role="button" className={styles.button} onClick={handler}>
        {children}
      </span>
    );
  }
}

export class NoResults extends PureComponent {
  static displayName = "Data.NoResults";

  static propTypes = {
    clearGlobalFilters: PropTypes.func.isRequired,
    resetDateRange: PropTypes.func.isRequired
  };

  static defaultProps = {
    clearGlobalFilters: noop,
    resetDateRange: noop
  };

  render() {
    const { clearGlobalFilters, resetDateRange } = this.props;

    return (
      <div className={styles.wrapper}>
        <p className={styles.bold}>There is no data in this set.</p>
        <p>
          Try adjusting your <FilterOption handler={clearGlobalFilters}>global filters</FilterOption>,
          &nbsp;
          <FilterOption handler={resetDateRange}>date range</FilterOption>,
          or search terms.
        </p>
      </div>
    );
  }
}

const withGlobalFiltersMutation = graphql(clearGlobalFilters, {
  name: "mutateGlobalFilters",
  options: props => ({
    fetchPolicy: "no-cache"
  })
});

const withDateRangeMutation = graphql(resetDateRange, {
  name: "mutateDateRange",
  options: props => ({
    fetchPolicy: "no-cache"
  })
});

const withMutationHandlers = withHandlers({
  clearGlobalFilters: ({ mutateGlobalFilters }) => () => {
    mutateGlobalFilters();
  },
  resetDateRange: ({ mutateDateRange }) => () => {
    mutateDateRange();
  }
});

export default compose(
  withGlobalFiltersMutation,
  withDateRangeMutation,
  withMutationHandlers,
)(NoResults);
