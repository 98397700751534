import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import hideIfUnauthenticated from "HOC/hideIfUnauthenticated";

import NavItem from "./NavItem";
import styles from "./NavList.module.scss";

export class NavList extends Component {
  static displayName = "Navigation.NavList";

  static propTypes = {
    useCollapse: PropTypes.bool,
    useSticky: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.container = null;

    this.setContainerRef = element => {
      this.container = element;
    };

    this.setContainerStickyPos = () => {
      if (!this.props.useSticky || !this.container) return false;

      const containerBox = this.container.getBoundingClientRect();
      const yPos = containerBox.top + window.scrollY;

      Object.assign(this.container.style, {
        position: "sticky",
        top: `${yPos}px`
      });
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setContainerStickyPos();
    }, 250);
  }

  active(name, exact = false) {
    const { pathname } = this.props.location;

    return exact ? pathname === name : pathname.indexOf(name) > -1;
  }

  render() {
    return (
      <nav
        ref={this.setContainerRef}
        role="navigation"
        aria-label="Main navigation"
      >
        <div className="m-nav-heading m-nav-heading--pad-bottom">Reports:</div>
        <ul className={styles.list}>
          <NavItem
            name="home"
            label="Dashboard"
            icon="Dashboard32"
            active={this.active("/", true)}
          />
          <NavItem
            name="audience"
            label="Audience"
            icon="Audience32"
            active={this.active("/audience", true)}
          />
          <NavItem
            name="performance"
            label="Performance"
            icon="Performance32"
            useCollapse={this.props.useCollapse}
            active={this.active("/performance", true)}
            expanded={this.active("/performance")}
          >
            <NavItem
              name="performance.cases"
              label="Cases"
              active={this.active("/performance/cases")}
            />
            <NavItem
              name="performance.articles"
              label="Articles"
              active={this.active("/performance/articles")}
            />
            <NavItem
              name="performance.satisfaction"
              label="Satisfaction"
              active={this.active("/performance/satisfaction")}
            />
          </NavItem>
          <NavItem
            name="behavior"
            label="Behavior"
            icon="Behavior32"
            useCollapse={this.props.useCollapse}
            active={this.active("/behavior", true)}
            expanded={this.active("/behavior")}
          >
            <NavItem
              name="behavior.content"
              label="Content"
              active={this.active("/behavior/content")}
            />
            <NavItem
              name="behavior.siteUsage"
              label="Site-Usage"
              active={this.active("/behavior/site-usage")}
            />
            <NavItem
              name="behavior.searches"
              label="Searches"
              active={this.active("/behavior/searches")}
            />
          </NavItem>
        </ul>
      </nav>
    );
  }
}

export default compose(
  withRouter,
  hideIfUnauthenticated
)(NavList);
