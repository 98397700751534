import React, { Component } from "react";
import PropTypes from "prop-types";
import { defaultProps, setDisplayName, withPropsOnChange } from "recompose";
import { flow, isEmpty, take, unzip } from "lodash";
import { at, map } from "lodash/fp";

import { Chart as BarChart, GeoChart, countryDataShape } from "Components/Chart";
import { cardPropsShape } from "Components/Layout";
import { wrapGraphQL } from "HOC/graphql";

import { maybeFormatNumber } from "Utilities";

import DataCard from "./DataCard";
import styles from "./CountryMap.module.scss";
import barGradient from "Stylesheets/images/bar-chart-gradient-green.svg";

export const countryShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  alpha2: PropTypes.string.isRequired,
  activitiesCount: PropTypes.number.isRequired,
  usersCount: PropTypes.number.isRequired
});

export const countriesShape = PropTypes.arrayOf(countryShape);

export const extractCountryBarDatum = at(["alpha2", "activitiesCount"]);
export const extractCountryBarData = flow(map(extractCountryBarDatum), unzip);

export const extractCountryDatum = at(["alpha2", "activitiesCount", "usersCount"]);
export const extractCountryData = map(extractCountryDatum);

function buildBarChartProps(countries) {
  const [categories, data] = extractCountryBarData(take(countries, 4));

  return ({
    options: {
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        },
        padding: {
          top: 0,
          right: 20,
          left: 10
        }
      },
      xaxis: {
        categories,
        axisBorder: {
          show: false
        },
        labels: {
          formatter: maybeFormatNumber
        }
      },
      yaxis: {
        floating: false,
        labels: {
          offsetX: 0
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "44%"
        }
      },
      fill: {
        image: {
          src: [barGradient],
          width: undefined,
          height: undefined
        }
      },
      states: {
        hover: {
          filter: {
            type: "none"
          }
        }
      },
    },
    series: [{
      name: "Visits",
      data
    }]
  });
}

const checkData = withPropsOnChange(
  ["countries"],
  function({ countries }) {
    const hasNoData = isEmpty(countries);

    const data = hasNoData ? [] : extractCountryData(countries);

    const barChartProps = hasNoData ? null : buildBarChartProps(countries);

    return { barChartProps, data, hasNoData };
  }
);

export default class CountryMap extends Component {
  static displayName = "Data.CountryMap";

  static propTypes = {
    barChartProps: PropTypes.object,
    cardProps: cardPropsShape,
    countries: countriesShape,
    data: countryDataShape,
    hasNoData: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    hasNoData: true,
    isLoading: true,
  };

  render() {
    const renderMap = () => {
      const { barChartProps, data } = this.props;

      return (
        <React.Fragment>
          <GeoChart data={data} />
          <div className={styles.barChartWrapper}>
            <BarChart {...barChartProps} type="bar" height={200} />
          </div>
        </React.Fragment>
      );
    };

    return (
      <DataCard {...this.props}>{renderMap}</DataCard>
    );
  }

  static extendAs(displayName, cardProps = {}, ...hocs) {
    return wrapGraphQL(
      setDisplayName(displayName),
      ...hocs,
      defaultProps({
        cardProps
      }),
      checkData
    )(CountryMap);
  }
}
