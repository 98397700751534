import { compose, branch, renderNothing } from "recompose";
import { cond, identity, isFunction, isString, over } from "lodash";

import { inspect } from "util";

import { propertyIsEmpty, otherwiseFail } from "Utilities";

import named from "./named";

const getDescriptor = cond([
  [isFunction, inspect],
  [isString, inspect],
  otherwiseFail("hideIfEmpty descriptor")
]);

const getDisplayName = value => `hideIfEmpty[${getDescriptor(value)}]`;

const getPredicate = cond([
  [isFunction, identity],
  [isString, propertyIsEmpty],
  otherwiseFail("hideIfEmpty predicate")
]);

const getValues = over([getDisplayName, getPredicate]);

/**
 * @param {string, function} predicateOrPropertyName
 * @return {function} composable HOC
 */
export default function hideIfEmpty(predicateOrPropertyName) {
  const [displayName, predicate] = getValues(predicateOrPropertyName);

  return compose(
    named(displayName),
    branch(predicate, renderNothing)
  );
}
