import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import { defaults, transform } from "lodash";

import {
  NamedRouteContext,
  NamedRouteSwitchShape,
  toNamedRouteSwitchProps
} from "Routes";

export default class NamedRouteSwitch extends Component {
  static displayName = "Routing.NamedRouteSwitch";

  static contextType = NamedRouteContext;

  static propTypes = {
    mapping: NamedRouteSwitchShape.isRequired
  };

  render() {
    const { mapping } = this.props;

    const transformRoute = (routes, componentOrProps, routeName) => {
      const props = defaults(toNamedRouteSwitchProps(componentOrProps), {
        exact: true
      });

      return <Route {...props} key={routeName} />;
    };

    const routes = transform(mapping, transformRoute, []);

    return <Switch>{routes}</Switch>;
  }
}
