import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import isFunction from "lodash/isFunction";

import styles from "./index.module.scss";

export default class FilterCard extends Component {
  static displayName = "Layout.FilterCard";

  static propTypes = {
    size: PropTypes.oneOf(["half", "full"]),
    children: PropTypes.node
  };

  static defaultProps = {
    size: "half"
  };

  state = {
    hasError: false
  };

  static getDerivedStateFromError(error) {
    return { hasError: Boolean(error), error };
  }

  componentDidCatch(error, info) {
    console.warn("Caught error in Layout.FilterCard");
    console.dir({ error, info });
  }

  get containerClasses() {
    const sizeClass = styles[this.props.size];

    return classNames(styles.container, sizeClass);
  }

  get hasError() {
    return this.state.hasError && this.state.error;
  }

  renderChildren() {
    if (this.hasError) {
      return (
        <React.Fragment>
          <p>An error occurred in this card</p>
          <p>{this.state.error.toString()}</p>
        </React.Fragment>
      );
    }

    const { children } = this.props;

    return isFunction(children) ? children() : children;
  }

  render() {
    return (
      <section className={this.containerClasses}>
        <div className={styles.inner}>
          {this.renderChildren()}
        </div>
      </section>
    );
  }
}
