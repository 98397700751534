import React, { Component } from "react";

import NavList from "./NavList";
import Footer from "./Footer";
import styles from "./Sidebar.module.scss";

export default class Sidebar extends Component {
  static displayName = "Navigation.Sidebar";

  render() {
    return (
      <aside className={styles.container}>
        <div className={styles.navWrapper}>
          <NavList useSticky />
        </div>
        <Footer />
      </aside>
    );
  }
}
