import React, { Component } from "react";
import PropTypes from "prop-types";

import { get } from "lodash";
import { compose, withPropsOnChange } from "recompose";

import { Card, cardPropsShape } from "Components/Layout";
import Loader from "Components/Utility/Loader";

import NoResults from "./NoResults";
import { loaderSizeFor } from "./utilities";

export class DataCard extends Component {
  static displayName = "Data.Card";

  static propTypes = {
    absoluteIfData: PropTypes.bool.isRequired,
    cardProps: cardPropsShape,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    csvTag: PropTypes.string,
    hasNoData: PropTypes.bool.isRequired,
    headerPosAbsolute: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
  };

  static defaultProps = {
    absoluteIfData: false,
    hasNoData: false,
    headerPosAbsolute: false,
    isLoading: false
  };

  renderCardChildren() {
    if (this.props.isLoading) {
      return <Loader unwrapped size={loaderSizeFor(this.props)} />;
    } else if (this.props.hasNoData) {
      return <NoResults />;
    } else {
      return this.props.children;
    }
  }

  render() {
    const { headerPosAbsolute, cardProps, csvTag } = this.props;

    return <Card {...cardProps} csvTag={csvTag} headerPosAbsolute={headerPosAbsolute}>{this.renderCardChildren()}</Card>;
  }
}

const withHeaderPos = withPropsOnChange(
  ["cardProps", "absoluteIfData", "isLoading", "hasNoData"],
  ({ cardProps, absoluteIfData, isLoading, hasNoData }) => {
    const derived = absoluteIfData && !isLoading && !hasNoData;

    return { headerPosAbsolute: get(cardProps, "headerPosAbsolute", derived) };
  }
);

export default compose(
  withHeaderPos
)(DataCard);
