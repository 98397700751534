import { graphql } from "react-apollo";

import { flow, property } from "lodash";

import { getOr, pick } from "lodash/fp";

import { getLocalFilters as getLocalFiltersQuery } from "Queries";

const FILTER_NAMES = [
  "articleKey",
  "articleNeedle",
  "chartScale",
  "company",
  "includeEmployees",
  "language",
  "productId",
  "startTime",
  "untilTime"
];

const getLocalFilters = flow(
  property("withLocalFilters.localFilters"),
  pick(FILTER_NAMES)
);

const getDateRangeChartScale = getOr(
  "weekly",
  "withLocalFilters.dateRangeChartScale"
);

export default graphql(getLocalFiltersQuery, {
  name: "withLocalFilters",
  options: {
    fetchPolicy: "cache-only"
  },
  props: props => ({
    dateRangeChartScale: getDateRangeChartScale(props),
    localFilters: getLocalFilters(props)
  })
});
