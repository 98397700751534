import { withClientState } from "apollo-link-state";

import cache from "./cache";

import LOCAL_FILTERS from "LocalFilters";

const LocalFilterMutations = LOCAL_FILTERS.createMergeResolvers({
  setArticleKeyFilter: "articleKey",
  setArticleNeedleFilter: "articleNeedle",
  setCompanyFilter: "company",
  setIncludeEmployeesFilter: "includeEmployees",
  setLanguageFilter: "language",
  setProductIdFilter: "productId",
  setTimeFilters: ["startTime", "untilTime"]
});

const otherMutations = LOCAL_FILTERS.mutationsFor(
  "clearGlobalFilters",
  "resetDateRange"
);

export default withClientState({
  cache,

  defaults: Object.assign({}, LOCAL_FILTERS.data, {
    networkStatus: {
      isConnected: true,
      __typename: "NetworkStatus"
    }
  }),

  resolvers: {
    Mutation: {
      ...LocalFilterMutations,
      ...otherMutations
    }
  },

  typeDefs: `
  scalar DateRange @client;

  type LocalFilters @client {
    chartScale: String
    company: String
    includeEmployees: Boolean
    language: String
    productId: ID
    startTime: String
    untilTime: String
  }

  type NetworkStatus @client {
    isConnected: Boolean!
  }

  type Query @client {
    dateRange: DateRange
    dateRangeChartScale: String

    localFilters: LocalFilters!
    networkStatus: NetworkStatus!
  }
  `
});
