import { cond, constant, matchesProperty, merge } from "lodash";

import { withPropsOnChange } from "recompose";

export const loaderSizeFor = cond([
  [matchesProperty("cardProps.size", "full"), constant("small")],
  [matchesProperty("cardProps.size", "half"), constant("small")]
]);

export function withDefaultCardProps(defaultCardProps = {}) {
  return withPropsOnChange(
    ["cardProps"],
    ({ cardProps = {} }) => ({
      cardProps: merge({}, defaultCardProps, cardProps)
    })
  );
}
