import React, { Component } from "react";

import Userbox from "./Userbox";
import SiemensFooter from "Components/Layout/SiemensFooter";

export default class Footer extends Component {
  static displayName = "Navigation.Footer";

  render() {
    return (
      <div>
        <Userbox />
        <SiemensFooter visibility="desktop" />
      </div>
    );
  }
}
