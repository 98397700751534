import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class ChartPie extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 22;
  }

  get defaultWidth() {
    return 22;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 22 22";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            d="M11.1692308,6.47692308 C8.31415385,6.47692308 6,8.79107692 6,11.6461538 C6,14.5012308 8.31415385,16.8153846 11.1692308,16.8153846 C14.0243077,16.8153846 16.3384615,14.5012308 16.3384615,11.6461538 L11.1692308,11.6461538 L11.1692308,6.47692308 Z M12.6461538,5 L12.6461538,10.1692308 L17.8153846,10.1692308 C17.8153846,7.31415385 15.5012308,5 12.6461538,5 L12.6461538,5 Z"
          />
          <path
            fill={this.fill}
            d="M11.1692308,6.47692308 C8.31415385,6.47692308 6,8.79107692 6,11.6461538 C6,14.5012308 8.31415385,16.8153846 11.1692308,16.8153846 C14.0243077,16.8153846 16.3384615,14.5012308 16.3384615,11.6461538 L11.1692308,11.6461538 L11.1692308,6.47692308 Z M12.6461538,5 L12.6461538,10.1692308 L17.8153846,10.1692308 C17.8153846,7.31415385 15.5012308,5 12.6461538,5 L12.6461538,5 Z"
          />
        </g>
      </svg>
    );
  }
}
