import React, { Component } from "react";

import { NamedRoute } from "Components/Routing";
import { Callback, FinishSignOut } from "Components/Authentication";

import Root from "./Root";

export default class TopLevelRoutes extends Component {
  static displayName = "TopLevel.Routes";

  render() {
    return (
      <React.Fragment>
        <NamedRoute name="auth.callback" component={Callback} />
        <NamedRoute name="auth.finishSigningOut" component={FinishSignOut} />
        <NamedRoute authenticated name="home" component={Root} />
      </React.Fragment>
    );
  }
}
