import React from "react";
import ReactDOM from "react-dom";

/**
 * Polyfills
 */
import "@babel/polyfill";
import "element-closest";
import "url-polyfill";
import "whatwg-fetch";

import "react-table/react-table.css";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
