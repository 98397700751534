import { cloneDeep, conforms, isMap, set } from "lodash";

import { Routes } from "Routes";
import { isPresent, isPresentString, areTuplesOfLength } from "Utilities";

import globalConfig from "config";

const { Auth0: Auth0Config } = globalConfig;

const isEntries = areTuplesOfLength(2);

const defaultAuthEntries = () => [["isAuthenticating", false]];

export const ENDPOINT_URL = `https://${Auth0Config.domain}`;

export const isValidAuth = conforms({
  accessToken: isPresent,
  idToken: isPresent
});

export function getAuthConfig() {
  const config = cloneDeep(Auth0Config);

  return set(config, "redirectUri", getCallbackURL());
}

export function getCallbackURL() {
  return Routes.generateURL("auth.callback");
}

export function getFinishSignOutURL() {
  return Routes.generateURL("auth.finishSigningOut");
}

export function getSignOutURL() {
  const url = new URL("/v2/logout", ENDPOINT_URL);

  url.searchParams.set("returnTo", getFinishSignOutURL());

  return url;
}

/**
 * @param {string} stringifiedEntries
 * @return {array[][]}
 */
function parseAuthEntries(stringifiedEntries) {
  try {
    if (isPresentString(stringifiedEntries)) {
      const entries = JSON.parse(stringifiedEntries);

      if (isEntries(entries)) {
        return entries;
      }
    }
  } catch (err) {
    console.warn("Error parsing entries: %s", stringifiedEntries);
    console.error(err);
  }

  return defaultAuthEntries();
}

/**
 * @param {Map} authMap
 * @return {object[][]}
 */
function extractAuthEntries(authMap) {
  return isMap(authMap) ? Array.from(authMap) : defaultAuthEntries();
}

/**
 * @param {string} key
 * @return {Map}
 */
export function loadAuthMap(key) {
  const stringifiedEntries = localStorage.getItem(key);

  const entries = parseAuthEntries(stringifiedEntries);

  return new Map(entries);
}

/**
 * @param {string} key
 * @param {Map} authMap
 * @return {void}
 */
export function persistAuthMap(key, authMap) {
  const extracted = extractAuthEntries(authMap);

  try {
    localStorage.setItem(key, JSON.stringify(extracted));
  } catch (err) {
    console.warn("Problem saving auth map!!");
    console.error(err);
  }
}
