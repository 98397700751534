import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Document extends Component {
  static propTypes = {
    iconClass: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stroke: PropTypes.string,
    fill: PropTypes.string,
    svgProps: PropTypes.object
  };

  static defaultProps = {
    iconClass: "",
    size: "inherit",
    stroke: "currentColor",
    fill: "currentColor",
    svgProps: {}
  };

  get defaultHeight() {
    return 22;
  }

  get defaultWidth() {
    return 22;
  }

  get size() {
    return this.props.size;
  }

  get width() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultWidth;
    return this.size;
  }

  get height() {
    if (this.size === null || this.size === "inherit") return null;
    if (this.size === "default") return this.defaultHeight;
    return this.size;
  }

  get viewBox() {
    return "0 0 22 22";
  }

  get classes() {
    const { iconClass } = this.props;
    return classnames("a-icon", iconClass);
  }

  get fill() {
    return this.props.fill;
  }

  get stroke() {
    return this.props.stroke;
  }

  render() {
    const baseSvgProps = {
      xmlns: "http://www.w3.org/2000/svg",
      className: this.classes,
      width: this.width,
      height: this.height,
      viewBox: this.viewBox
    };

    const svgProps = Object.assign(baseSvgProps, this.props.svgProps);

    return (
      <svg {...svgProps}>
        <g fill="none" fillRule="evenodd">
          <path
            fill={this.fill}
            d="M12.5454545,8.32307692 C12.5454545,8.52707692 12.7081818,8.69230769 12.9090909,8.69230769 L16.1818182,8.69230769 L16.1818182,15.8923077 C16.1818182,16.4009231 15.7736364,16.8153846 15.2727273,16.8153846 L6.90909091,16.8153846 C6.40818182,16.8153846 6,16.4009231 6,15.8923077 L6,5.92307692 C6,5.41446154 6.40818182,5 6.90909091,5 L12.5454545,5 L12.5454545,8.32307692 Z M13.2727273,5.276 C13.3645455,5.33323077 13.4663636,5.40246154 13.5790909,5.48646154 C13.9227273,5.74307692 14.3254545,6.10030769 14.7118182,6.49261538 C15.0981818,6.88492308 15.45,7.29384615 15.7027273,7.64276923 C15.7845455,7.75815385 15.8536364,7.86061538 15.9109091,7.95384615 L13.2727273,7.95384615 L13.2727273,5.276 Z"
          />
          <path
            fill={this.fill}
            d="M12.5454545,8.32307692 C12.5454545,8.52707692 12.7081818,8.69230769 12.9090909,8.69230769 L16.1818182,8.69230769 L16.1818182,15.8923077 C16.1818182,16.4009231 15.7736364,16.8153846 15.2727273,16.8153846 L6.90909091,16.8153846 C6.40818182,16.8153846 6,16.4009231 6,15.8923077 L6,5.92307692 C6,5.41446154 6.40818182,5 6.90909091,5 L12.5454545,5 L12.5454545,8.32307692 Z M13.2727273,5.276 C13.3645455,5.33323077 13.4663636,5.40246154 13.5790909,5.48646154 C13.9227273,5.74307692 14.3254545,6.10030769 14.7118182,6.49261538 C15.0981818,6.88492308 15.45,7.29384615 15.7027273,7.64276923 C15.7845455,7.75815385 15.8536364,7.86061538 15.9109091,7.95384615 L13.2727273,7.95384615 L13.2727273,5.276 Z"
          />
        </g>
      </svg>
    );
  }
}
