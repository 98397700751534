import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./SelectDetails.module.scss";
import { IconComposer } from "Components/Utility";

export default class SelectDetails extends PureComponent {
  static displayName = "Utility.SelectFilter.SelectDetails";

  static propTypes = {
    isClearable: PropTypes.bool.isRequired,
    themes: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    isClearable: true
  };

  get showCheckbox() {
    return this.props.categoryLabel === "Company";
  }

  get selectedLabel() {
    const { placeholder, value } = this.props;
    const labelText = value ? value.label : placeholder;
    return labelText;
  }

  get showClear() {
    return this.props.isClearable && this.props.value;
  }

  get showSelection() {
    return this.props.noPopover;
  }

  get themes() {
    return this.props.themes;
  }

  get isCardTheme() {
    return this.themes && this.themes.includes("card");
  }

  get containerStyles() {
    return classNames({
      [styles.container]: true,
      [styles.containerDefault]: !this.isCardTheme,
      [styles.containerCard]: this.isCardTheme
    });
  }

  handleCheckbox = event => {
    const target = event.target;
    const value = target.checked;
    this.props.handleCheckbox(value);
  };

  renderSelection() {
    const { categoryLabel } = this.props;

    return (
      <span>
        <div className={styles.label}>{categoryLabel}:</div>
        <div className={styles.selected}>{this.selectedLabel}</div>
        {this.showClear && this.renderClear()}
      </span>
    );
  }

  renderCheckbox() {
    return (
      <div className={styles.checkboxContainer}>
        <input
          id="employees"
          type="checkbox"
          checked={this.props.includeEmployees}
          className={styles.checkbox}
          onChange={this.handleCheckbox}
        />
        <label htmlFor="employees" className={styles.checkText}>
          Include employees in results
        </label>
      </div>
    );
  }

  renderClear() {
    const { onClear } = this.props;

    return (
      <button
        className={styles.button}
        onClick={event => {
          onClear(null);
        }}
      >
        <IconComposer icon="Close22" iconClass={styles.icon} size="default" />
      </button>
    );
  }

  render() {
    return (
      <div className={this.containerStyles}>
        {!this.showSelection && this.renderSelection()}
        {this.showCheckbox && this.renderCheckbox()}
      </div>
    );
  }
}
